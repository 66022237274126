import React, { FC, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import { DateSelectArg, EventInput } from '@fullcalendar/core';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction';
import { Avatar, Box, LinearProgress, Stack, Typography } from '@mui/material';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { createFilterString } from 'utils/helpers';
import { listUsers } from 'api/organisations/users';
import useInvalidateData from 'hooks/useInvalidateData';
import { useNavigate } from 'react-router-dom';
import { SelectedFilter } from 'components/common/FilterWithDropdown/types';
import { GridRowId } from '@mui/x-data-grid';
import { PaginationModel } from 'components/common/DataTable/types';
import { User } from 'types/dbSchema/userProfiles';
import { UserPosition } from 'types/dbSchema/userPositions';
import { useAtom } from 'jotai';
import { currentDataPointAtom } from 'atoms';
import { CalendarEvent } from 'types/fullCalendar';
import useCalendarResize from 'hooks/useCalendarResize';
import { formatDate, getDay, parseISO } from 'date-fns';
import { calculateDuration } from 'utils/helpers/fullCalendarTemplates';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';

const ServiceTeamSchedule: FC = () => {
  const [searchText, setSearchText] = useState('');
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowId[]>([]);
  const [paginationModel, setPaginationModel] = useState<PaginationModel>({ page: 0, pageSize: 20 });
  const [organisations] = useUserOrganisations();
  const navigate = useNavigate();
  useInvalidateData('user-profile'); // If the data table has been flagged for invalidation, invalidate the query
  const [, setCurrentDataPoint] = useAtom(currentDataPointAtom);
  const calendarRef = useRef<any>(null);

  // Handles the style refresh of the calendar when the sidebar is toggled
  useCalendarResize(calendarRef);

  const selectedFilters = [{
    field: "MobileUser",
    valuesSelected: [
      "True",
    ]
  }] as SelectedFilter[];

  const { isPending, isError, data, isFetching, isLoading } = useQuery({
    queryKey: ['user-profile', paginationModel, createFilterString(selectedFilters)],
    queryFn: () => listUsers(organisations[0].organisation.globalId, selectedFilters, paginationModel.page + 1, paginationModel.pageSize),
    placeholderData: keepPreviousData,
    enabled: organisations.length > 0,
    refetchOnWindowFocus: false,
    staleTime: cachingInvalidation.userProfiles,
  });

  const [events, setEvents] = useState<CalendarEvent[]>([]);

  const handleDateSelect = (selectInfo: DateSelectArg) => {
    const { startStr, endStr, allDay, resource } = selectInfo;
    const resourceId = resource?.id;

    if (!resourceId) {
      alert('Please select a valid resource to create an event.');
      return;
    }

    const title = prompt('Please enter a title for your event:');

    // Calculate the duration
    const duration = calculateDuration(startStr, endStr);

    if (title) {
      const newEvent: CalendarEvent = {
        id: String(events.length + 1),
        title,
        start: startStr,
        end: endStr,
        allDay,
        duration,
        day: formatDate(parseISO(startStr), 'EEEE'),
        backgroundColor: '#388E3C',
        rrule: {
          freq: 'weekly',
          byweekday: [getDay(parseISO(startStr)).toString()],
          dtstart: startStr,
          interval: 1,
        },
      };

      setEvents((prevEvents) => [...prevEvents, newEvent]);
    }

    selectInfo.view.calendar.unselect(); // Clear the selection
  };

  // Flatten the resources and assign positions
  const resources = data?.items.map((user: User) => ({
    id: user.id,
    title: `${user.fullName.firstName} ${user.fullName.lastName}`,
    position: user.position ? (user.position as UserPosition).name : 'Unknown Position', // Assign position here
    profileImageUrl: user.profilePicture,
    object: user,
  })) || [];

  const handleUserClick = (user: User) => {
    setCurrentDataPoint({
      object: user,
      type: 'user-profile',
    });
    navigate(`/scheduling/service-team/${user.id}/roster`);
  };

  function renderEventContent(info: any) {
    const profileImageUrl = info.resource._resource.extendedProps.profileImageUrl || '';
    const user = info.resource._resource.extendedProps.object;
    return (
      <Box display='flex' alignItems='center' sx={{ cursor: 'pointer' }} onClick={() => handleUserClick(user)}>
        <Avatar sx={{ width: 24, height: 24, mr: 1 }} src={profileImageUrl} />
        <Typography variant='body2'>{info.fieldValue}</Typography>
      </Box>
    );
  }

  if (isPending || isFetching || isLoading) return <LinearProgress />;

  return (
    <Stack sx={{ backgroundColor: 'white' }}>
      <FullCalendar
        ref={calendarRef}
        plugins={[resourceTimelinePlugin, interactionPlugin]}
        initialView="resourceTimelineWeek"
        selectable={true}
        events={events}
        nowIndicator
        select={handleDateSelect}
        schedulerLicenseKey='0151803017-fcs-1700699622'
        resourceGroupField='position'  // Group by position
        resources={resources}
        resourceLabelContent={renderEventContent}
        slotDuration='24:00'
        slotLabelInterval={{ days: 1 }}
        slotLabelFormat={{ weekday: 'short', day: 'numeric' }}
        droppable={true}
        eventResize={() => alert('Resized!')}
        eventDrop={() => alert('Dropped!')}
        editable={true}
      />
    </Stack>
  );
};

export default ServiceTeamSchedule;