import axiosInstance from "api/config";
import { ExternalContact, ExternalContactList } from "types/dbSchema/externalContacts";

// Returns a list of external contacts for the given organisation ID and participant ID
export const listParticipantExternalContacts = async (organisationId: string, participantId: string, page?: number, pageSize?: number): Promise<ExternalContactList> => {
  try {
    if (!organisationId || !participantId) throw new Error('Organisation ID, page, and page size are required');

    const response = await axiosInstance.get(`/api/organisations/${organisationId}/participants/${participantId}/externalcontacts`, {
      params: {
        page,
        pageSize
      }
    });

    return response.data;
  } catch (error) {
    console.error('There was an error listing external contacts for the organisation and participant', error);
    throw error;
  }
};

// Get a external contact by ID, for the given organisation ID and participant ID
export const getParticipantExternalContactByID = async (organisationId: string, externalContactId: string, participantId: string): Promise<ExternalContact> => {
  try {
    if (!externalContactId || !organisationId || !participantId) throw new Error('Organisation ID, Participant ID and ExternalContact ID are required');

    const response = await axiosInstance.get(`/api/organisations/${organisationId}/participants/${participantId}/externalcontacts/${externalContactId}`);
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the external contact for the participant', error);
    throw error;
  }
};

// Link an external contact to a participant
export const linkParticipantToExternalContact = async (organisationId: string, externalContactId: string, participantId: string, data: any): Promise<ExternalContact> => {
  try {
    if (!externalContactId || !organisationId || !participantId) throw new Error('Organisation ID, Participant ID and ExternalContact ID are required');

    const response = await axiosInstance.post(`/api/organisations/${organisationId}/participants/${participantId}/externalcontacts/${externalContactId}/linkcontact`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error linking the external contact to the participant', error);
    throw error;
  }
};

// Update the link between a participant and an external contact
export const updateParticipantExternalContactLink = async (organisationId: string, externalContactId: string, participantId: string, data: any): Promise<ExternalContact> => {
  try {
    if (!externalContactId || !organisationId || !participantId) throw new Error('Organisation ID, Participant ID and ExternalContact ID are required');

    const response = await axiosInstance.put(`/api/organisations/${organisationId}/participants/${participantId}/externalcontacts/${externalContactId}/updatecontactlink`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error updating the external contact link', error);
    throw error;
  }
};

// Unlink a external contact
export const unlinkParticipantFromExternalContact = async (organisationId: string, externalContactId: string, participantId: string): Promise<ExternalContact> => {
  try {
    if (!externalContactId || !organisationId || !participantId) throw new Error('Organisation ID, Participant ID and ExternalContact ID are required');

    const response = await axiosInstance.put(`/api/organisations/${organisationId}/participants/${participantId}/externalcontacts/${externalContactId}/unlinkcontact`);
    return response.data;
  } catch (error) {
    console.error('There was an error unlinking the external contact from the participant', error);
    throw error;
  }
};