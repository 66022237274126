import axiosInstance from 'api/config';
import { Organisation } from 'types/dbSchema/organisation';

// Checks if an organisation exists with the given ABN and NDIS ID
export const organisationExists = async (abn: string, ndisId: string) => {
  try {
    if (!abn || !ndisId) {
      throw new Error('ABN and NDIS ID are required');
    }
    const response = await axiosInstance.get('/api/organisations/organisation-exists', {
      params: {
        abn,
        ndisId
      }
    });
    return response.data;
  } catch (error) {
    console.error('There was an error checking if the organisation exists', error);
    throw error;
  }
};

export const getCurrentUserProfile = async () => {
  try {
    const response = await axiosInstance.get(`/api/organisations/current-user`);
    return response.data;
  } catch (error) {
    console.error('There was an error getting the current user profile', error);
    throw error;
  }
};

export const getVersion = async () => {
  try {
    const response = await axiosInstance.get('/api/version');
    return response.data;
  } catch (error) {
    console.error('There was an error getting the version', error);
    throw error;
  }
};

export const editOrganisation = async (organisation: Organisation) => {
  try {
    const response = await axiosInstance.put(`/api/organisations/${organisation.globalId}/details`, organisation);
    return response.data;
  } catch (error) {
    console.error('There was an error updating the organisation', error);
    throw error;
  }
};