import { Address } from 'types';
import { ServiceType } from './schedulingSettings';
import { FullName } from './userProfiles';
import { UserPosition } from './userPositions';

export interface ServiceProvider {
  id: string;
  companyName: string;
  abn: string;
  serviceTypes: ServiceType[];
  phoneNumber: string;
  mobileNumber?: string;
  email: string;
  website?: string;
  serviceRegion: string;
  address: Address;
  isActive: boolean;
}

export interface ServiceProviderContact {
  id: string;
  fullName: FullName;
  email: string;
  mobile: string;
  phone?: string;
  isActive: boolean;
  serviceProvider?: ServiceProvider | string; // in POST & PATCH will be the ServiceProvider ID. In GET it will be the serviceProvider object.
  position: UserPosition | string; // in POST & PATCH will be the Position ID. In GET it will be the position type.
  isPrimaryContact: boolean;
  createdBy: {
    id: string;
    fullName: FullName
  }
  createdDateTime: string; // "2024-07-02T02:52:51.560Z" This format
}

export const serviceProviderContactPlaceholder: ServiceProviderContact[] = [
  {
    id: '11112222-aaaa-bbbb-cccc-ddddeeeeffff',
    createdBy: {
      id: '11112222-aaaa-bbbb-cccc-ddddeeeeffff',
      fullName: {
        firstName: 'John',
        lastName: 'Doe'
      }
    },
    createdDateTime: '2024-07-02T02:52:51.560Z',
    fullName: {
      firstName: 'John',
      middleName: undefined,
      lastName: 'Doe'
    },
    email: 'test@test.com',
    mobile: '0412345678',
    isActive: true,
    phone: '0400000000',
    isPrimaryContact: true,
    position: {
      id: "63b630d7-701e-497f-83e0-31dca4a4fb07",
      name: "Owner",
      description: "Owner",
      isActive: true,
      usersCount: 1,
      serviceTypesDelivered: []
    },
  },
];