import React, { FC, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Stack, Typography } from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import styled from 'styled-components';
import variables from 'styles/variables';
import { EditOutlined } from '@mui/icons-material';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { useAtom } from 'jotai';
import { modalStateAtom, triggerRefreshCurrentDataPointAtom } from 'atoms';
import LineHeader from 'components/common/LineHeader';
import Line from 'components/common/Line';
import ExternalContactForm from 'components/features/Forms/ExternalContactForm';
import LinkIcon from '@mui/icons-material/Link';
import { closeModalAtom } from 'atoms/modalAtom';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Participant } from 'types/dbSchema/participants';
import { Manager } from 'types/dbSchema/userProfiles';
import { ExternalContact } from 'types/dbSchema/externalContacts';
import useErrorMessage from 'hooks/useErrorMessage';
import DeleteModal from '../DataTables/ContactsDataTable/Controls/DeleteModal';
import { unlinkParticipantFromExternalContact } from 'api/organisations/participants/externalcontacts';
import useUserOrganisations from 'hooks/useUserOrganisations';

export interface ViewContactProps {
  externalContact: ExternalContact;
}

const ViewContact: FC<ViewContactProps> = ({ externalContact }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const handleLinkParticipant = () => {
    navigate(`/participant-centre/contacts-console/view/${id}/link-participant`);
  };

  return (
    <Stack width='100%' justifyContent='flex-start' alignItems='center' height='100%' sx={{
      backgroundColor: 'white',
      minHeight: `calc(100vh - ${variables.heights.topBar})`,
      overflowY: 'auto',
    }}>
      <PageHeader externalContact={externalContact} />
      <Stack gap='24px' width='100%' height='fit-content' padding='0 24px 24px' >
        <Stack gap='5px'>
          <ContactDetailsStack>
            <LineHeader>
              <Typography variant='subtitle2'>
                Contact Details
              </Typography>
            </LineHeader>
            <Line noBottomBorder>
              <Stack width='100%'>
                <Typography variant='body2' color={variables.colors.text.secondary}>
                  Email
                </Typography>
                <Typography variant='body2'>
                  {externalContact.email}
                </Typography>
              </Stack>
              <Stack width='100%'>
                <Typography variant='body2' color={variables.colors.text.secondary}>
                  Mobile Number
                </Typography>
                <Typography variant='body2'>
                  {externalContact.mobile}
                </Typography>
              </Stack>
              <Stack width='100%'>
                <Typography variant='body2' color={variables.colors.text.secondary}>
                  Phone Number
                </Typography>
                <Typography variant='body2'>
                  {externalContact.phone}
                </Typography>
              </Stack>
            </Line>
          </ContactDetailsStack>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            {`Created by ${(externalContact.createdBy as Manager).fullName}
             on ${new Date(externalContact.createdDateTime).toLocaleDateString()}`}
          </Typography>
        </Stack>
        <Stack gap='24px'>
          <Stack flexDirection='row' gap='16px' width='100%' justifyContent='space-between' alignContent='center'>
            <Typography variant='subtitle1'>
              Linked Participants
            </Typography>
            <Button variant='outlined' color='primary' startIcon={<LinkIcon />} onClick={handleLinkParticipant}>
              Link Participant
            </Button>
          </Stack>
          <Box display='grid' gridTemplateColumns='repeat(auto-fill, minmax(368px, 1fr))' gap='24px'>
            {externalContact.linkedParticipants.map((participant: any) => (
              <LinkedParticipant key={participant.id} participant={participant} externalContact={externalContact} />
            ))}
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};

export interface LinkedParticipantProps {
  participant: Participant;
  externalContact: ExternalContact;
}

const LinkedParticipant: FC<LinkedParticipantProps> = ({ participant, externalContact }) => {
  const [, setModalState] = useAtom(modalStateAtom);

  // Function to open/close the modal.
  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: UnlinkModal,
      props: {
        dataTableName: 'participants',
        participant: participant,
        externalContact,

      }
    }));
  }, [setModalState]);

  return (
    <Stack
      flexDirection='row'
      gap='16px'
      alignItems='center'
      justifyContent='space-between'
      minWidth='368px'
      padding='16px 24px'
      sx={{
        backgroundColor: '#F9FAFB',
        border: '1px solid #E5E7EB',
        borderRadius: '4px',
      }}
    >
      <Stack flexDirection='row' gap='16px' alignItems='center'>
        <Box height='100%' width='fit-content' display='flex' alignItems='center'>
          <img src={participant.profilePicture} alt="profile" style={{ width: '42px', height: '42px', borderRadius: '32px', objectFit: 'cover' }} />
        </Box>
        <Stack>
          <Typography variant='subtitle2'>
            {participant.fullName.firstName} {participant.fullName.lastName}
          </Typography>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            {participant.email}
          </Typography>
        </Stack>
      </Stack>
      <LinkOffIcon
        sx={{ color: variables.colors.icon.standard, cursor: 'pointer' }}
        onClick={handleModalOpen}
      />
    </Stack>
  );
};

const PageHeader: FC<ViewContactProps> = ({ externalContact }) => {
  const navigate = useNavigate();
  const [, setModalState] = useAtom(modalStateAtom);
  const showError = useErrorMessage();
  const isDisabled = externalContact.linkedParticipantsCount > 0;

  // Function to open/close the modal.
  const handleDeleteModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: DeleteModal,
      props: {
        dataTableName: 'external-contacts',
        type: 'delete',
        externalContact,
      }
    }));
  }, [setModalState]);

  // Function to open/close the modal.
  const handleEditModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: ExternalContactForm,
      props: {
        dataTableName: 'external-contacts',
        type: 'edit',
        externalContact,
      }
    }));
  }, [setModalState, externalContact]);

  const handleDelete = async () => {
    try {
      handleDeleteModalOpen();
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <PageHeaderStack>
      <Button variant='text'
        sx={{
          color: variables.colors.text.secondary,
          textTransform: 'none',
          fontWeight: 400,
          padding: 0
        }}
        onClick={() => navigate(`/participant-centre/contacts-console`)}
      >
        <ArrowBackOutlinedIcon sx={{
          padding: '4px',
          boxSizing: 'border-box',
          height: '24px',
          width: '24px',
        }}
        />
        Contacts
      </Button>
      <PageHeaderDetails>
        <Typography variant='h5' sx={{ textWrap: 'wrap' }}>
          {externalContact.fullName.firstName} {externalContact.fullName.lastName}
        </Typography>
        <Stack flexDirection='row' gap='16px' width='fit-content' alignItems='center'>
          {isDisabled ? (
            <Stack justifyContent='center' alignItems='center' sx={{ cursor: 'not-allowed' }}>
              <DeleteOutlinedIcon sx={{ color: variables.colors.icon.standard, opacity: 0.2 }} />
            </Stack>
          ) : (
            <Stack justifyContent='center' alignItems='center' sx={{ cursor: 'pointer' }} onClick={handleDelete}>
              <DeleteOutlinedIcon sx={{ color: '#C62828' }} />
            </Stack>
          )}
          <Button startIcon={<EditOutlined />} variant='contained' color='primary' onClick={handleEditModalOpen}>
            Edit
          </Button>
        </Stack>
      </PageHeaderDetails>
    </PageHeaderStack >
  );
};

export interface UnlinkModalProps {
  participant: Participant;
  externalContact: ExternalContact;
}

export const UnlinkModal: FC<UnlinkModalProps> = ({ participant, externalContact }) => {
  const [, closeModal] = useAtom(closeModalAtom);
  const showError = useErrorMessage();
  const [organisations] = useUserOrganisations();
  const [, setTriggerRefresh] = useAtom(triggerRefreshCurrentDataPointAtom);

  const handleUnlink = async () => {
    if (!externalContact.id || !participant.id) {
      showError('Participant or contact not found');
      return;
    }
    try {
      await unlinkParticipantFromExternalContact(
        organisations[0].organisation.globalId,
        externalContact.id,
        participant.id
      );
      setTriggerRefresh(true);
      closeModal();
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <Stack alignItems="center" width='500px'>
      <Stack flexDirection="row" alignItems="center" gap='24px' sx={{ padding: '32px' }}>
        <Box sx={{ borderRadius: '100%', background: '#FECDD2', padding: '8px', height: '40px', width: '40px', display: 'flex', alignSelf: 'flex-start', boxSizing: 'border-box' }}>
          <LinkOffIcon color='error' />
        </Box>
        <Stack gap='16px'>
          <Typography variant='h6'>
            Unlink this contact
          </Typography>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            Are you sure you want to remove this contact from the participant’s contacts list?
          </Typography>
          <Typography component='div' variant='body2' color={variables.colors.text.secondary} sx={{ fontStyle: 'italic' }}>
            <Box component='span' sx={{ fontWeight: 700 }} color={variables.colors.text.secondary}>Note: </Box>this action does not delete the contact record from the system, it only removes the link with the participant.
          </Typography>
        </Stack>
      </Stack>
      <ButtonStack>
        <Button variant='outlined' onClick={closeModal}>Cancel</Button>
        <Button variant='contained' color='error' onClick={handleUnlink}>Unlink</Button>
      </ButtonStack>
    </Stack>
  );
};

const ButtonStack = styled(Stack)`
  flex-direction: row;
  width: 100%;
  padding: 16px 32px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 0px 0px 12px 12px;
  background: #F9FAFB;
  box-sizing: border-box;
`;

const PageHeaderStack = styled(Stack)`
  display: flex;
  width: 100%;
  padding: 32px 24px;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  gap: 8px;
`;

const PageHeaderDetails = styled(Stack)`
 flex-direction: row;
  gap: 8px;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  position: relative;
`;

const ContactDetailsStack = styled(Stack)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
`;

export default ViewContact;