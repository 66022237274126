import React, { FC, useEffect } from 'react';
import { LinearProgress } from '@mui/material';
import ServiceTeamSkillsQualifications from 'components/features/Scheduling/ServiceTeamSchedule/ServiceTeamSkillsQualifications';
import { useAtom } from 'jotai';
import { sidebarAtom } from 'atoms';
import ServiceTeamMemberDetails from 'components/features/ServiceTeamMemberDetails';
import { useFetchDataPoint } from 'hooks/useFetchRecordById';
import { getUserById } from 'api/organisations/users';

const ViewServiceTeamSkillsQualificationsPage = () => {
  const [sidebarState, setSidebarState] = useAtom(sidebarAtom);

  useEffect(() => {
    setSidebarState({
      ...sidebarState,
      content: <ServiceTeamMemberDetails />,
    });
  }, [setSidebarState, sidebarState]);

  const { isLoading, currentDataPoint, hasError } = useFetchDataPoint({ getByIDFunc: getUserById });
  if (isLoading) return <LinearProgress />;
  if (hasError || !currentDataPoint) return null;
  return <ServiceTeamSkillsQualifications />;
};

export default ViewServiceTeamSkillsQualificationsPage;