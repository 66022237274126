import React from "react";
import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { ServiceTypesProps } from "./types";

const ServiceType: FC<ServiceTypesProps> = ({ serviceType }) => {
  return (
    <Stack
      flexDirection='row'
      alignItems='center'
      justifyContent='center'
      gap='4px'
      sx={{
        backgroundColor: `${serviceType.color}14`,
        padding: '4px 8px',
        borderRadius: '4px',
      }}
    >
      <svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8' fill='none'>
        <circle cx='4' cy='4' r='4' fill={serviceType.color} />
      </svg>
      <Typography variant='caption' fontWeight='600'>
        {serviceType.name}
      </Typography>
    </Stack>
  );
};


export default ServiceType;