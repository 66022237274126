import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import styled from 'styled-components';
import variables from 'styles/variables';
import { ServiceContract } from 'types/dbSchema/serviceContracts';
import IsActiveDropdown from 'components/common/IsActiveDropdown';
import Tabs from 'components/common/Tabs';
import CategoryBudgets from './CategoryBudgets';
import BudgetNotes from './BudgetNotes';
import DeliveredServices from './DeliveredServices';

export interface ViewServiceContractsProps {
  serviceContract: ServiceContract;
}

const ViewServiceContracts: FC<ViewServiceContractsProps> = ({ serviceContract }) => {

  return (
    <Stack width='100%' justifyContent='flex-start' alignItems='center' height='100%' sx={{
      backgroundColor: 'white',
      minHeight: `calc(100vh - ${variables.heights.topBar})`,
      overflowY: 'auto',
    }}>
      <PageHeader serviceContract={serviceContract} />
      <Tabs
        tabs={[
          {
            label: 'Category Budgets',
            content: <CategoryBudgets serviceContract={serviceContract} />,
            requiredPermissions: [],
          },
          {
            label: 'Budgets Notes',
            content: <BudgetNotes serviceContract={serviceContract} />,
            requiredPermissions: [],
          },
          {
            label: 'Delivered Services',
            content: <DeliveredServices serviceContract={serviceContract} />,
            requiredPermissions: [],
          },
        ]}
      />
    </Stack>
  );
};

const PageHeader: FC<ViewServiceContractsProps> = ({ serviceContract }) => {
  const navigate = useNavigate();

  return (
    <PageHeaderStack>
      <Button variant='text'
        sx={{
          color: variables.colors.text.secondary,
          textTransform: 'none',
          fontWeight: 400,
          padding: 0
        }}
        onClick={() => navigate(-1)}
      >
        <ArrowBackOutlinedIcon sx={{
          padding: '4px',
          boxSizing: 'border-box',
          height: '24px',
          width: '24px',
        }}
        />
        Contacts
      </Button>
      <Stack gap='16px' width='100%' alignItems='center' flexDirection='row' justifyContent='flex-start'>
        <Typography variant='h6'>
          {serviceContract.sosReferenceId}
        </Typography>
        <IsActiveDropdown
          isActive={serviceContract.isActive}
          disabled
        />
      </Stack>
    </PageHeaderStack >
  );
};

const PageHeaderStack = styled(Stack)`
  display: flex;
  width: 100%;
  padding: 32px 24px;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  gap: 8px;
`;

export default ViewServiceContracts;