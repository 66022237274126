import React from 'react';
import { useCallback } from "react";
import { useAtom } from "jotai";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { modalStateAtom } from "atoms";
import { Button, Stack } from "@mui/material";
import variables from 'styles/variables';
import { ServiceProviderContact } from 'types/dbSchema/serviceProviders';

export const DeleteIcon = ({ serviceProviderContact }: { serviceProviderContact: ServiceProviderContact }) => {
  const [, setModalState] = useAtom(modalStateAtom);

  // Function to open/close the modal.
  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: DeleteModal,
      props: {
        dataTableName: 'service-provider-contacts',
        type: 'delete',
        serviceProviderContact: serviceProviderContact,
      }
    }));
  }, [setModalState]);

  return (
    <Stack justifyContent='center' alignItems='center' sx={{ cursor: 'pointer' }} onClick={handleModalOpen}>
      <DeleteOutlinedIcon sx={{ color: '#C62828' }} />
    </Stack>
  );
};

const DeleteModal = ({ serviceProviderContact }: { serviceProviderContact: ServiceProviderContact }) => {
  const handleDelete = () => {
    console.log('Delete service provider contact:', serviceProviderContact);
  };

  return (
    <div>
      <Button onClick={handleDelete}>Delete</Button>
    </div>
  );
};