import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAtom } from 'jotai';
import { currentDataPointAtom } from 'atoms';
import { User } from 'types/dbSchema/userProfiles';
import { getUserById } from 'api/organisations/users';
import useUserOrganisations from '../useUserOrganisations';
import { dummyDataServiceProviders } from 'utils/helpers/getDBData';
import { ServiceProvider } from 'types/dbSchema/serviceProviders';

// This hook is used to sync user profile data with the current data point atom
export function useServiceProviderDataSync() {
  const { id } = useParams();
  const [currentDataPoint, setCurrentDataPoint] = useAtom(currentDataPointAtom);
  const [organisations] = useUserOrganisations();

  useEffect(() => {
    if (!organisations.length) return;
    const getUserProfile = async (id: string) => {
      // Replace this URL with the actual endpoint to fetch user profile data
      // const data = await getUserById(organisations[0].organisation.globalId, id);
      const data = dummyDataServiceProviders.find((serviceProvider: ServiceProvider) => serviceProvider.id === id) || null;
      setCurrentDataPoint({
        object: data,
        type: 'service-provider',
      });
    };

    if (id) {
      if (currentDataPoint?.object?.id !== id) {
        getUserProfile(id);
      }
    }
  }, [id, currentDataPoint, setCurrentDataPoint, organisations.length]);

  // Check if the data is still loading
  const isLoading = !currentDataPoint.object || currentDataPoint.object?.id !== id;

  return { isLoading, currentDataPoint };
}