import { PaginationBase } from "types";
import { FullName, UserAndDate } from "./userProfiles";

export type GoalType = 'Short-term' | 'Medium to Long-term';
export const goalTypes: GoalType[] = ['Short-term', 'Medium to Long-term'];
export type GoalStatus = 'Not Started' | 'In Progress' | 'Completed';
export const goalStatuses: GoalStatus[] = ['Not Started', 'In Progress', 'Completed'];
export type GoalHistoryType = 'Goal Review' | 'General Comment';

export interface ParticipantGoal {
  id: string;
  goal: string;
  description: string;
  status: GoalStatus;
  reviewDate: string;
  goalType: GoalType;
  score: number; // 1 to 5. -1 if not scored and will be displayed n/a
  created: UserAndDate;
  lastUpdated: UserAndDate;
  goalHistory: GoalHistory[];
}

export type ParticipantGoalList = PaginationBase<ParticipantGoal>;

export interface GoalHistory {
  id: string;
  comment: string;
  createdBy: string;
  createdDate: string;
  goalSnapshot: ParticipantGoal | null;
  type: GoalHistoryType;
}

export const statusArray = [
  {
    label: 'Not Started',
    value: 'NotStarted',
    fontColor: '#1F2937',
    backgroundColor: '#6B728033',
  },
  {
    label: 'In Progress',
    value: 'InProgress',
    fontColor: '#01579B',
    backgroundColor: '#03A9F433',
  },
  {
    label: 'Completed',
    value: 'Completed',
    fontColor: '#1B5E20',
    backgroundColor: '#69C64F4D',
  },
];