import axiosInstance from 'api/config';
import { UserPosition, UserPositionList } from 'types/dbSchema/userPositions';

// Returns a list of positions for the given organisation ID
export const listPositions = async (organisationId: string, page?: number, pageSize?: number): Promise<UserPositionList> => {
  try {
    if (!organisationId) throw new Error('Organisation ID, page, and page size are required');

    const response = await axiosInstance.get(`/api/organisations/${organisationId}/positions`, {
      params: {
        page,
        pageSize
      }
    });

    return response.data;
  } catch (error) {
    console.error('There was an error listing positions for the organisation', error);
    throw error;
  }
};

// Creates a new position for the given organisation ID
// Partial type because we don't need to send an ID
export const createPosition = async (organisationId: string, data: Partial<UserPosition>) => {
  try {
    if (!organisationId) throw new Error('Organisation ID and User ID are required');

    const response = await axiosInstance.post(`/api/organisations/${organisationId}/positions`,
      data
    );
    return response.data;
  } catch (error) {
    console.error('There was an error creating a new position for the organisation', error);
    throw error;
  }
};

// Updates position for the given organisation ID and position ID
export const updatePosition = async (organisationId: string, data: UserPosition) => {
  try {
    if (!organisationId) throw new Error('Organisation ID and User ID are required');

    const response = await axiosInstance.put(`/api/organisations/${organisationId}/positions/${data.id}`,
      data
    );

    return response.data;
  } catch (error) {
    console.error('There was an error updating the position for the organisation', error);
    throw error;
  }
};


// Returns a position by the ID params for the given organisation ID
export const getPositionById = async (organisationId: string, positionId: string): Promise<UserPosition> => {
  try {
    if (!organisationId) throw new Error('Organisation ID and User ID are required');

    const response = await axiosInstance.get(`/api/organisations/${organisationId}/positions/${positionId}`);

    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the position for the organisation', error);
    throw error;
  }
};