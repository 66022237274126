import ParticipantContactsDataTable from 'components/features/DataTables/ParticipantContactsDataTable';
import React, { FC } from 'react';
import { Participant } from 'types/dbSchema/participants';

export interface ContactsProps {
  participant: Participant;
}

const Contacts: FC<ContactsProps> = ({ participant }) => {
  return (
    <ParticipantContactsDataTable participant={participant} />
  );
};

export default Contacts;