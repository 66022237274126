import React from 'react';
import { LinearProgress } from '@mui/material';
import ServiceProviderForm from 'components/features/Forms/ServiceProviderForm';
import { useServiceProviderDataSync } from 'hooks/dataSync/useServiceProviderDataSync';

const EditServiceProvidersPage = () => {
  const { isLoading, currentDataPoint } = useServiceProviderDataSync();

  if (isLoading || !currentDataPoint) {
    return <LinearProgress />;
  }

  return (
    <ServiceProviderForm serviceProvider={currentDataPoint.object} />
  );
};

export default EditServiceProvidersPage;