import React, { FC, useCallback, useState } from 'react';
import { Box, Button, LinearProgress, Stack, Typography } from '@mui/material';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Data, PaginationModel } from 'components/common/DataTable/types';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { dummyLeaveTypes, placeholderLeaveData } from 'utils/helpers/getDBData';
import { ServiceTeamLeave, ServiceTeamLeaveStatuses } from 'types/dbSchema/serviceTeam';
import { User, UserAndDate } from 'types/dbSchema/userProfiles';
import variables from 'styles/variables';
import { formatISODateTime, hexToRgba } from 'utils/helpers';
import { LeaveType } from 'types/dbSchema/availabilitySettings';
import StatusDropdown from 'components/common/StatusDropdown';
import { Link } from 'react-router-dom';
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined';
import ViewTimelineOutlinedIcon from '@mui/icons-material/ViewTimelineOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { modalStateAtom, userMessageAtom } from 'atoms';
import { useAtom } from 'jotai';
import { Controller, useForm } from 'react-hook-form';
import LoadingOverlay from 'components/common/LoadingOverlay';
import TextInput from 'components/common/InputFields/TextInput';
import { closeModalAtom } from 'atoms/modalAtom';
import SelectInput from 'components/common/InputFields/SelectInput';
import CheckboxInput from 'components/common/InputFields/CheckboxInput';
import TextareaInput from 'components/common/InputFields/TextareaInput';
import DragAndDropFileUpload from 'components/common/InputFields/DragAndDropFileUpload';
import { UploadedFile } from 'components/common/InputFields/DragAndDropFileUpload/types';
import { ImportStatus } from 'components/features/ImportModal/types';
import { SubmittedState } from 'components/common/InputFields/DragAndDropFileUpload/FileStates';
import DateTimePickerInput from 'components/common/InputFields/DateTimePickerInput';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';

const UpcomingLeave = () => {
  const [paginationModel, setPaginationModel] = useState<PaginationModel>({ page: 0, pageSize: 20 });
  const [organisations] = useUserOrganisations();

  const { isPending, isError, data, isFetching, isLoading } = useQuery({
    queryKey: ['service-team-leave', paginationModel],
    queryFn: () => placeholderLeaveData,
    placeholderData: keepPreviousData,
    enabled: organisations.length > 0,
    refetchOnWindowFocus: false,
    staleTime: cachingInvalidation.upcomingLeave,
  }) as any;

  if (isPending || isFetching || isLoading) return <LinearProgress />;

  return (
    <Stack sx={{ backgroundColor: 'white' }} gap='24px' padding='24px'>
      {(data && data.length > 0) && data.map((item: ServiceTeamLeave) => (
        <LeaveItem key={item.id} item={item} />
      ))}
    </Stack>
  );
};

export interface LeaveItemProps {
  item: ServiceTeamLeave;
}

export const LeaveItem: FC<LeaveItemProps> = ({ item }) => {
  const [, setModalState] = useAtom(modalStateAtom);
  const backgroundColor = hexToRgba((item.leaveType as LeaveType).color, 0.2);
  const backgroundColorString = `rgba(${backgroundColor.r}, ${backgroundColor.g}, ${backgroundColor.b}, ${backgroundColor.a})`;

  const handleDelete = () => {
    console.log('Delete');
  };

  const handleViewLeaveHistory = () => {
    console.log('View Leave History');
  };

  // Function to open/close the modal.
  const handleEdit = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: LeaveModal,
      props: {
        dataTableName: 'service-team-leave',
        type: 'edit',
        item,
      }
    }));
  }, [setModalState]);

  return (
    <Stack gap='8px' padding='24px' borderRadius='8px' sx={{ backgroundColor: '#F8F7FA' }}>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Box padding='4px 8px' sx={{ border: `1px solid ${(item.leaveType as LeaveType).color}`, borderRadius: '4px', backgroundColor: `${backgroundColorString}`, }}>
          <Typography variant='subtitle2'>
            {(item.leaveType as LeaveType).name}
          </Typography>
        </Box>
        <Stack direction='row' justifyContent='flex-end' alignItems='center' gap='16px'>
          <ViewTimelineOutlinedIcon sx={{ color: variables.colors.icon.standard, cursor: 'pointer' }} onClick={handleViewLeaveHistory} />
          <DeleteOutlinedIcon sx={{ color: variables.colors.icon.standard, cursor: 'pointer' }} onClick={handleDelete} />
          <EditOutlinedIcon sx={{ color: variables.colors.icon.standard, cursor: 'pointer' }} onClick={handleEdit} />
        </Stack>
      </Stack>
      <Stack gap='4px'>
        <Typography variant='subtitle2'>
          {item.startTime && formatISODateTime(item.startTime)}
        </Typography>
        <Typography variant='body2'>
          {item.notes}
        </Typography>
        {item.attachments && item.attachments.length > 0 && (
          <Stack direction='row' gap='8px' alignItems='center'>
            {item.attachments.map((attachment, index) => (
              <Link key={index} to={attachment.blobUrl} target='_blank' download>
                <Stack flexDirection='row' gap='4px' alignItems='center'>
                  <FilePresentOutlinedIcon sx={{ color: variables.colors.icon.standard }} />
                  <Typography variant='body2' color={variables.colors.text.primary}>
                    {attachment.fileName}
                  </Typography>
                </Stack>
              </Link>
            ))}
          </Stack>
        )}
      </Stack>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Typography
          component="div"
          fontSize="12px"
          variant="body2"
          color={variables.colors.text.secondary}
        >
          {`${item.status} by `}
          <span style={{ fontWeight: 700 }}>
            {`${(item.reviewedBy as UserAndDate).userFullName.firstName} ${(item.reviewedBy as UserAndDate).userFullName.lastName}`}
          </span>
          {`, ${formatISODateTime((item.reviewedBy as UserAndDate).date)}`}
          {item.declinedReason && (
            <>
              {` | Comment: `}
              <span style={{ fontStyle: 'italic' }}>
                {`${item.declinedReason}`}
              </span>
            </>
          )}
        </Typography>
        <StatusDropdown currentStatus={item.status} statuses={ServiceTeamLeaveStatuses} disabled />
      </Stack>
    </Stack>
  );
};

export const LeaveModal: FC<LeaveItemProps> = ({ item }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [, closeModal] = useAtom(closeModalAtom);
  const [modalState, setModalState] = useAtom(modalStateAtom);
  const [currentFiles, setCurrentFiles] = useState<UploadedFile[]>([]);
  const validFileTypes = ['application/pdf', 'image/jpeg', 'image/png', 'image/jpg'];
  const [importStatus, setImportStatus] = useState<ImportStatus>({ status: 'idle', message: '', });
  const [, setUserMessage] = useAtom(userMessageAtom);
  const maxTotalSize = 104857600;
  const maxFileSize = 104857600;
  const { control, handleSubmit, formState: { errors, isDirty } } = useForm({
    mode: 'onChange',
    defaultValues: item || {
      isActive: true // Default value for isActive is required for the IsActiveDropdown component
    }
  });

  const onSubmit = (data: any) => {
    console.log(data);
  };

  return (
    <Box sx={{ overflowY: 'auto', maxHeight: 'calc(100vh - 64px)' }}>
      <form style={{ position: 'relative', width: '600px', padding: '32px', height: 'fit-content' }} onSubmit={handleSubmit((data) => onSubmit(data))}>
        {isLoading && <LoadingOverlay sx={{ borderRadius: '12px' }} />}
        <Stack height='fit-content' gap='12px'>
          <Typography variant='h6' fontWeight='600' mb={2}>
            {item ? 'Edit Leave' : 'Add New Leave'}
          </Typography>
          <Controller
            name="leaveType"
            control={control}
            rules={{ required: 'Leave Type is required' }}
            render={({ field }) =>
              <SelectInput
                {...field}
                id='leaveType'
                label="Leave Type"
                error={errors.leaveType ? true : false}
                errorText={errors.leaveType?.message}
                placeholder='Select a Leave Type'
                options={dummyLeaveTypes.map((leaveType) => ({ value: leaveType.id, label: leaveType.name }))}
                isMandatory
              />}
          />
          <Stack>
            <Typography variant='subtitle2' fontWeight='500' color={variables.colors.text.primary} sx={{ marginBottom: '4px' }}>
              Start <span style={{ color: 'red' }}>*</span>
            </Typography>
            <Controller
              name="startTime"
              control={control}
              rules={{ required: 'Start Time is required' }}
              render={({ field }) =>
                <DateTimePickerInput
                  {...field}
                  id='startTime'
                  // label='Start Time'
                  error={errors.startTime ? true : false}
                  errorText={errors?.startTime?.message}
                  isMandatory
                />
              }
            />
          </Stack>
          <Stack>
            <Typography variant='subtitle2' fontWeight='500' color={variables.colors.text.primary} sx={{ marginBottom: '4px' }}>
              End <span style={{ color: 'red' }}>*</span>
            </Typography>
            <Controller
              name="endTime"
              control={control}
              rules={{ required: 'End Time is required' }}
              render={({ field }) =>
                <DateTimePickerInput
                  {...field}
                  id='endTime'
                  error={errors.endTime ? true : false}
                  errorText={errors?.endTime?.message}
                  isMandatory
                />
              }
            />
          </Stack>
          <Stack width='100%' alignItems={'flex-end'}>
            <Controller
              name="isAllDay"
              control={control}
              render={({ field }) =>
                <CheckboxInput
                  {...field}
                  id='isAllDay'
                  label="All Day"
                  error={errors.isAllDay ? true : false}
                  checked={field.value ? true : false}
                />}
            />
          </Stack>
          <Controller
            control={control}
            name='notes'
            render={({ field }) => (
              <TextareaInput
                {...field}
                id='notes'
                label='Notes'
                error={errors.notes ? true : false}
                errorText={errors.notes?.message}
              />
            )}
          />
          <DragAndDropFileUpload
            setCurrentFiles={setCurrentFiles}
            currentFiles={currentFiles}
            validFileTypes={validFileTypes}
            maxTotalSize={maxTotalSize}
            maxFileSize={maxFileSize}
            multiple
          />
          {item?.attachments.map((file, index) => (
            <SubmittedState
              key={index}
              uploadedFile={file}
              showDeleteIcon
              removeFile={() => setCurrentFiles((prevFiles) => prevFiles.filter((_, i) => i !== index))}
            />
          ))}
          <Stack flexDirection='row' justifyContent='flex-end' gap='16px' padding='24px 0 0 0'>
            <Button variant='outlined' color='primary' onClick={closeModal}>
              Cancel
            </Button>
            <Button type='submit' variant='contained' color='primary' disabled={!isDirty}>
              {item ? 'Update' : 'Create'}
            </Button>
          </Stack>
        </Stack>
      </form>
    </Box>
  );
};

export default UpcomingLeave;