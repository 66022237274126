import React, { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import styled from 'styled-components';
import variables from 'styles/variables';
import { EditOutlined } from '@mui/icons-material';
import { LinkBetweenParticipantAndContact } from 'types/dbSchema/participantContact';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { UnlinkModal } from 'components/features/DataTables/ParticipantContactsDataTable/Controls/Columns/UnlinkIcon';
import LineHeader from 'components/common/LineHeader';
import Line from 'components/common/Line';
import { RelationshipType } from 'types/dbSchema/participantSettings';
import { ServiceProvider } from 'types/dbSchema/serviceProviders';
import Label from 'components/common/Label';
import ExternalContactForm from 'components/features/Forms/ExternalContactForm';
import { Manager } from 'types/dbSchema/userProfiles';

export interface ViewParticipantContactProps {
  participantContact: LinkBetweenParticipantAndContact;
}

const ViewParticipantContact: FC<ViewParticipantContactProps> = ({ participantContact }) => {

  return (
    <Stack width='100%' justifyContent='flex-start' alignItems='center' height='100%' sx={{
      backgroundColor: 'white',
      minHeight: `calc(100vh - ${variables.heights.topBar})`,
      overflowY: 'auto',
    }}>
      <PageHeader participantContact={participantContact} />
      <Stack gap='24px' width='100%' height='fit-content' padding='0 24px 24px' >
        <Stack gap='5px'>
          <ContactDetailsStack>
            <LineHeader>
              <Typography variant='subtitle2'>
                Contact Details
              </Typography>
            </LineHeader>
            <Line>
              <Stack width='100%'>
                <Typography variant='body2' color={variables.colors.text.secondary}>
                  Email
                </Typography>
                <Typography variant='body2'>
                  {participantContact.email}
                </Typography>
              </Stack>
              <Stack width='100%'>
                <Typography variant='body2' color={variables.colors.text.secondary}>
                  Mobile Number
                </Typography>
                <Typography variant='body2'>
                  {participantContact.mobile}
                </Typography>
              </Stack>
              <Stack width='100%'>
                <Typography variant='body2' color={variables.colors.text.secondary}>
                  Phone Number
                </Typography>
                <Typography variant='body2'>
                  {participantContact.phone}
                </Typography>
              </Stack>
            </Line>
            <Line noBottomBorder>
              <Stack width='100%'>
                <Typography variant='body2' color={variables.colors.text.secondary}>
                  Relationship to Participant
                </Typography>
                <Typography variant='body2'>
                  {(participantContact.relationship as RelationshipType).name}
                </Typography>
              </Stack>
              <Stack width='100%'>
                <Typography variant='body2' color={variables.colors.text.secondary}>
                  Service Provider
                </Typography>
                {/* <Typography variant='body2'>
                  {(participantContact.serviceProvider as ServiceProvider)?.companyName}
                </Typography> */}
              </Stack>
              <Stack width='100%'>
                <Typography variant='body2' color={variables.colors.text.secondary}>
                  Contact Tags
                </Typography>
                <Stack direction='row' gap='8px'>
                  {participantContact.isPrimaryContact && <Label text='Primary' />}
                  {participantContact.isEmergencyContact && <Label text='Emergency' />}
                </Stack>
              </Stack>
            </Line>
          </ContactDetailsStack>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            {`Created by ${(participantContact.createdBy as Manager).fullName}
             on ${new Date(participantContact.createdDateTime).toLocaleDateString()}`}
          </Typography>
        </Stack>
        <Typography variant='subtitle1'>
          Linked Participants
        </Typography>
      </Stack>
    </Stack>
  );
};

const PageHeader: FC<ViewParticipantContactProps> = ({ participantContact }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [, setModalState] = useAtom(modalStateAtom);

  // Function to open/close the modal.
  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: UnlinkModal,
      props: {
        dataTableName: 'participant-contacts',
        participantContact: participantContact,
      }
    }));
  }, [setModalState]);

  // Function to open/close the modal.
  const handleEditModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: ExternalContactForm,
      props: {
        dataTableName: 'participant-contacts',
        type: 'edit',
        participantContact: participantContact,
      }
    }));
  }, [setModalState, participantContact]);

  return (
    <PageHeaderStack>
      <Button variant='text'
        sx={{
          color: variables.colors.text.secondary,
          textTransform: 'none',
          fontWeight: 400,
          padding: 0
        }}
        onClick={() => navigate(`/participant-centre/participants/view/${id}`)}
      >
        <ArrowBackOutlinedIcon sx={{
          padding: '4px',
          boxSizing: 'border-box',
          height: '24px',
          width: '24px',
        }}
        />
        Contacts
      </Button>
      <PageHeaderDetails>
        <Typography variant='h5' sx={{ textWrap: 'wrap' }}>
          {participantContact.fullName.firstName} {participantContact.fullName.lastName}
        </Typography>
        <Stack flexDirection='row' gap='16px' width='fit-content' alignItems='center'>
          <Button startIcon={<LinkOffIcon />} variant='text' color='error' onClick={handleModalOpen}>
            Unlink contact
          </Button>
          <Button startIcon={<EditOutlined />} variant='contained' color='primary' onClick={handleEditModalOpen}>
            Edit
          </Button>
        </Stack>
      </PageHeaderDetails>
    </PageHeaderStack >
  );
};

const PageHeaderStack = styled(Stack)`
  display: flex;
  width: 100%;
  padding: 32px 24px;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  gap: 8px;
`;

const PageHeaderDetails = styled(Stack)`
 flex-direction: row;
  gap: 8px;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  position: relative;
`;

const ContactDetailsStack = styled(Stack)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
`;

export default ViewParticipantContact;