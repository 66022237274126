import React from 'react';
import { LinearProgress } from '@mui/material';
import ServiceTeamAvailability from 'components/features/Scheduling/ServiceTeamSchedule/ServiceTeamAvailability';
import { User } from 'types/dbSchema/userProfiles';
import { useFetchDataPoint } from 'hooks/useFetchRecordById';
import { getUserById } from 'api/organisations/users';

const ViewServiceTeamAvailabilityPage = () => {
  const { isLoading, currentDataPoint, hasError } = useFetchDataPoint({ getByIDFunc: getUserById });
  if (isLoading) return <LinearProgress />;
  if (hasError || !currentDataPoint) return null;

  return <ServiceTeamAvailability serviceTeamMember={currentDataPoint as User} />;
};

export default ViewServiceTeamAvailabilityPage;