import axiosInstance from "api/config";
import { ExternalContact, ExternalContactList } from "types/dbSchema/externalContacts";

// Returns a list of external contacts for the given organisation ID
export const listExternalContacts = async (organisationId: string, page?: number, pageSize?: number): Promise<ExternalContactList> => {
  try {
    if (!organisationId) throw new Error('Organisation ID is required');

    const response = await axiosInstance.get(`/api/organisations/${organisationId}/externalcontacts?linked=false`, {
      params: {
        page,
        pageSize
      }
    });

    return response.data;
  } catch (error) {
    console.error('There was an error listing external contacts for the organisation', error);
    throw error;
  }
};

// Returns a external contact by ID
export const getExternalContactByID = async (organisationId: string, externalContactId: string): Promise<ExternalContact> => {
  try {
    if (!externalContactId || !organisationId) throw new Error('Organisation ID and ExternalContact ID are required');

    const response = await axiosInstance.get(`/api/organisations/${organisationId}/externalcontacts/${externalContactId}`);
    return response.data;
  } catch (error) {
    console.error('There was an error retrieving the external contact', error);
    throw error;
  }
};

// Create a new external contact
export const createExternalContact = async (organisationId: string, data: ExternalContact) => {
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.post(`/api/organisations/${organisationId}/externalcontacts/newcontact`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error creating the external contact', error);
    throw error;
  }
};

// Update a external contact
export const updateExternalContact = async (organisationId: string, data: ExternalContact) => {
  try {
    if (!organisationId || !data) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.put(`/api/organisations/${organisationId}/externalcontacts/${data.id}`, data);
    return response.data;
  } catch (error) {
    console.error('There was an error updating the external contact', error);
    throw error;
  }
};

// Delete a external contact
export const deleteExternalContact = async (organisationId: string, externalContactId: string) => {
  try {
    if (!organisationId) throw new Error('Organisation ID and data are required');

    const response = await axiosInstance.delete(`/api/organisations/${organisationId}/externalcontacts/${externalContactId}`);
    return response.data;
  } catch (error) {
    console.error('There was an error deleting the external contact', error);
    throw error;
  }
};