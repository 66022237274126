import { PaginationBase } from "types";

export const systemSetUserRoles: UserRole[] = [
  {
    "name": "Owner",
    "description": "Owner Role",
    "id": "239b93b6-1811-41dd-9497-fa3f88467d64",
    isEditable: false
  },
  {
    "name": "Intake",
    "description": "Intake Role",
    "id": "929dbc5c-1b45-493e-8b7f-85fb39de8fa1",
    isEditable: false
  },
  {
    "name": "Finance",
    "description": "Finance Role",
    "id": "6453459f-5287-4b14-a15f-cb7a6d0fabe4",
    isEditable: false
  },
  {
    "name": "Admin",
    "description": "Admin Role",
    "id": "867022e2-2dfa-4985-92a1-ba69946de078",
    isEditable: false
  },
  {
    "name": "Scheduler",
    "description": "Scheduler Role",
    "id": "746d6fee-0dc8-42d4-a555-e93b23076503",
    isEditable: false
  },
  {
    "name": "Standard User",
    "description": "Standard User Role",
    "id": "d1981a62-67fc-4d1c-8e53-46a529579322",
    isEditable: false
  },
  {
    "name": "Service Team",
    "description": "Service Team Role",
    "id": "9f7f237b-640b-4899-ab91-ef44d21fb5de",
    isEditable: false
  }
];

export interface UserRole {
  id: string;
  name: string; // 'Owner' | 'Administrator' | 'Standard User' | 'Scheduler' | 'Finance' | 'Intake' | 'Service Team';
  description: string;
  isEditable: boolean;
}
export type UserRoleList = PaginationBase<UserRole>;

export interface UserRoleCount {
  name: string;
  description: string;
  count: number;
}

export type UserRoleCountList = PaginationBase<UserRoleCount>;