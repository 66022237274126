import React, { FC, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import { AddOutlined, CloseOutlined } from '@mui/icons-material';
import variables from 'styles/variables';
import { useLocation, useNavigate } from 'react-router-dom';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { closeModalAtom } from 'atoms/modalAtom';
import useHashScroll from 'hooks/useHashScroll';
import { ServiceProvider } from 'types/dbSchema/serviceProviders';
import ContactDetails from './CompanyDetails';
import LocationDetails from './LocationDetails';

export interface ServiceProviderFormProps {
  serviceProvider?: ServiceProvider;
}

export interface ModalState {
  isOpen: boolean;
  type: ModalType;
}

export type ModalType = 'create' | 'update' | 'cancel';

const ServiceProviderForm: FC<ServiceProviderFormProps> = ({ serviceProvider }) => {
  useHashScroll(); // Scroll to the hash element (if any) when the component mounts
  const [pageVariant, setPageVariant] = useState<'create' | 'edit'>('create');
  const location = useLocation();
  const [modalState, setModalState] = useAtom(modalStateAtom);
  const { control, handleSubmit, reset, formState: { errors }, getValues, setValue } = useForm<ServiceProvider>({
    defaultValues: serviceProvider || {
      isActive: true,
    },
    mode: 'onChange'
  });

  // Check if the current location is an edit page
  useEffect(() => {
    console.log(pageVariant);
    if (location.pathname.includes('edit')) {
      setPageVariant('edit');
    } else {
      setPageVariant('create');
    }
  }, [location]);

  // // Update form values with service provider when/if it's available
  // useEffect(() => {
  //   if (serviceProvider) {
  //     reset(serviceProvider);
  //   }
  // }, [serviceProvider, reset]);

  // We are not submitting the form here, we trigger the validation checks.
  // If validation passes, open a modal which will handle the submission
  // This will allow us to show a confirmation modal before actually submitting the form to the DB
  const onSubmit = () => {
    if (Object.keys(errors).length !== 0) return;
    setModalState({
      ...modalState,
      status: 'open',
      position: 'center',
      component: ServiceProviderModal,
      props: {
        ...modalState.props,
        formData: getValues(),
        pageVariant: pageVariant,
      },
    });
  };

  const handleCancel = () => {
    setModalState({
      ...modalState,
      status: 'open',
      position: 'center',
      component: ServiceProviderModal,
      props: {
        type: 'cancel',
        pageVariant: pageVariant,
        formData: getValues(),
      },
    });
  };

  const handleModalStateChange = (type: ModalType) => {
    setModalState({
      ...modalState,
      props: {
        type: type,
      },
    });
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <Banner>
        <MaxWidthContainer>
          <Stack flexDirection='row' gap={1} alignItems='center' minWidth='fit-content'>
            {pageVariant === 'edit' ?
              <EditOutlinedIcon sx={{ borderRadius: '100%', border: ' 2px dashed #81D4FA', color: '#81D4FA', padding: '4px', height: '28px', width: '28px', boxSizing: 'border-box' }} />
              : <AddOutlined sx={{ borderRadius: '100%', border: ' 2px dashed #81D4FA', color: '#81D4FA', padding: '4px', height: '28px', width: '28px', boxSizing: 'border-box' }} />
            }
            <Typography variant='h5'>
              {serviceProvider ? 'Edit Service Provider' : 'Add New Service Provider'}
            </Typography>
          </Stack>
          {pageVariant === 'create' ? <CloseOutlined sx={{ cursor: 'pointer' }} onClick={handleCancel} />
            : (
              <Stack flexDirection='row' gap={2} width='100%' justifyContent='flex-end' maxWidth='960px'>
                <Button variant='outlined' sx={{ color: 'white', border: '1px solid white' }} onClick={handleCancel}>Cancel</Button>
                <Button variant='contained' type="submit" sx={{ color: 'white', backgroundColor: variables.colors.secondary.main, ':hover': { backgroundColor: variables.colors.secondary.darker } }} onClick={() => handleModalStateChange('update')}>Update</Button>
              </Stack>
            )}
        </MaxWidthContainer>
      </Banner>
      <Stack padding='32px' width='100%' height='fit-content' justifyContent='center' alignItems='center' boxSizing='border-box' gap='32px' overflow='auto'>
        <ContactDetails control={control} errors={errors} />
        <LocationDetails setValue={setValue} control={control} errors={errors} />
        {pageVariant === 'create' && (
          <Stack flexDirection='row' gap={2} width='100%' justifyContent='flex-end' maxWidth='960px' boxSizing='border-box'>
            <Button variant='text' onClick={handleCancel}>Cancel</Button>
            <Button variant='contained' type='submit' onClick={() => handleModalStateChange('create')}>Create Service Provider</Button>
          </Stack>
        )}
      </Stack>
    </StyledForm>
  );
};

export interface ServiceProviderModalProps {
  formData: ServiceProvider;
  pageVariant: 'create' | 'edit';
}

const ServiceProviderModal: FC<ServiceProviderModalProps> = ({ formData, pageVariant }) => {
  const navigate = useNavigate();
  const [modalState] = useAtom(modalStateAtom);
  const [, closeModal] = useAtom(closeModalAtom);

  const handleCreate = () => {
    // When APIs are ready, replace the console.log with the API call
    console.log(formData);
  };

  const handleUpdate = () => {
    // When APIs are ready, replace the console.log with the API call
    console.log(formData);
  };

  const handleLeave = () => {
    closeModal();
    if (pageVariant === 'edit') {
      navigate(`/resources/service-providers/view/${formData.id}`);
    } else {
      navigate('/resources/service-providers');
    }
  };

  const handleSubmit = useMemo(() => {
    switch (modalState.props.type) {
      case 'create':
        return handleCreate;
      case 'update':
        return handleUpdate;
      default:
        return closeModal;
    }
  }, [modalState.props.type]);

  return (
    <Stack width='500px' boxSizing='border-box'>
      <Stack flexDirection='row' gap='24px' width='100%' padding='32px' boxSizing='border-box'>
        <Stack>
          <Stack sx={{ backgroundColor: modalState.props.type === 'cancel' ? variables.colors.error.subtle : variables.colors.primary.subtle, borderRadius: '100%', width: '40px', height: '40px', justifyContent: 'center', alignItems: 'center' }} >
            {modalState.props.type === 'create'
              ? <PersonAddAlt1OutlinedIcon sx={{ color: variables.colors.primary.darker, width: '24px' }} />
              : modalState.props.type === 'update'
                ? <EditOutlinedIcon sx={{ color: variables.colors.primary.darker, width: '24px' }} />
                : <CancelOutlinedIcon sx={{ color: variables.colors.error.main, width: '24px' }} />
            }
          </Stack>
        </Stack>
        <Stack gap='12px'>
          <Typography variant='h6'>
            {modalState.props.type === 'create'
              ? 'Create new Service Provider?'
              : modalState.props.type === 'update'
                ? 'Update Service Provider?'
                : 'Are you sure you want to leave?'
            }
          </Typography>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            {modalState.props.type === 'create'
              ? 'Please confirm to create new Service Provider'
              : modalState.props.type === 'update'
                ? 'Please confirm to update the Service Provider'
                : 'Any information added will be lost.'
            }
          </Typography>
        </Stack>
      </Stack>
      <Stack flexDirection='row' gap={2} width='100%' justifyContent='flex-end' maxWidth='960px' padding='16px 32px' boxSizing='border-box' sx={{ backgroundColor: variables.colors.lightNeutral.subtle, borderRadius: '0 0 12px 12px' }} >
        {modalState.props.type === 'cancel' ? (
          <>
            <Button variant='text' onClick={closeModal} sx={{ border: `1px solid ${variables.colors.lightNeutral.darker}` }}>Stay</Button>
            <Button variant='contained' color='error' onClick={handleLeave}>Leave</Button>
          </>
        ) : (
          <>
            <Button variant='text' onClick={closeModal}>Cancel</Button>
            <Button variant='contained' onClick={handleSubmit}>Confirm</Button>
          </>
        )}
      </Stack>
    </Stack>
  );
};

const Banner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 16px;
  background-color: ${variables.colors.primary.darker};
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
  color: white;
  position: fixed;
  padding: 16px 32px;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 70px;
`;

const MaxWidthContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  max-width: 960px;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  height: fit-content;
  background-color: #F3F4F6;
  margin-top: 70px;
`;

export default ServiceProviderForm;