import React, { FC } from 'react';
import { LinearProgress } from '@mui/material';
import ParticipantForm from 'components/features/Forms/ParticipantForm';
import { useFetchDataPoint } from 'hooks/useFetchRecordById';
import { getParticipantById } from 'api/organisations/participants';

const EditParticipantPage: FC = () => {
  const { isLoading, currentDataPoint, hasError } = useFetchDataPoint({ getByIDFunc: getParticipantById });
  if (isLoading) return <LinearProgress />;
  if (hasError || !currentDataPoint) return null;

  return <ParticipantForm participant={currentDataPoint} />;
};

export default EditParticipantPage;