import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import ViewIcon from './ViewIcon';
import { Stack, Typography } from '@mui/material';
import IsActiveDropdown from 'components/common/IsActiveDropdown';
import variables from 'styles/variables';
import { EditIcon } from './EditIcon';
import { DeleteIcon } from './DeleteIcon';

export const Columns: GridColDef[] = [
  {
    field: 'view',
    headerName: '',
    width: 50,
    sortable: false,
    renderCell: (params) => (
      <ViewIcon externalContact={params.row} />
    ),
  },
  {
    field: 'edit',
    headerName: '',
    width: 50,
    sortable: false,
    renderCell: (params) => (
      <EditIcon externalContact={params.row} />
    ),
  },
  {
    field: 'delete',
    headerName: '',
    width: 50,
    sortable: false,
    renderCell: (params) => (
      <DeleteIcon externalContact={params.row} />
    ),
  },
  {
    field: 'name',
    headerName: 'Name',
    minWidth: 300,
    renderCell: (params) => (
      <Stack>
        <Typography variant='body2' fontWeight='600'>
          {params.row.fullName.firstName} {params.row.fullName.lastName}
        </Typography>
        <Typography color={variables.colors.text.secondary} variant='body2' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          {params.row.email}
        </Typography>
      </Stack>
    )
  },
  {
    field: 'mobile',
    headerName: 'Mobile',
    width: 200,
    renderCell: (params) => (
      <Typography variant='body2'>
        {params.row.mobile}
      </Typography>
    )
  },
  {
    field: 'phone',
    headerName: 'Phone',
    width: 200,
    renderCell: (params) => (
      <Typography variant='body2'>
        {params.row.phone}
      </Typography>
    )
  },
  {
    field: 'numberOfLinkedParticipants',
    headerName: 'Linked Participants',
    minWidth: 200,
    flex: 1,
    renderCell: (params) => (
      <Typography variant='body2' fontWeight='600'>
        {params.row.linkedParticipantCount}
      </Typography>
    )
  },
  {
    field: 'status',
    headerName: 'Status',
    sortable: false,
    width: 150,
    renderCell: (params) => (
      <IsActiveDropdown isActive={params.row.isActive} />
    ),
  },
];