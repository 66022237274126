export const permissions = {
  // Specific Permissions
  APPROVE_SCHEDULE_INTERNALLY: 'Approve Schedule Internally',
  APPROVE_TIMESHEET: 'Approve Timesheet',
  CAN_TRANSFER_OWNERSHIP: 'Can Transfer Ownership',
  RELEASE_SHIFTS: 'Release Shifts',

  // Base
  VIEW_HOME: 'View Home',
  VIEW_POWERVIEW: 'View Power View',

  // Participant Centre - This is the parent tab
  // View Actions
  VIEW_PARTICIPANT_CENTRE: 'View Participant Centre',

  // Participant Centre > Participants
  // View Actions
  VIEW_PARTICIPANTS: 'View Participants', // View participants tab/list
  VIEW_PARTICIPANT_DETAILS: 'View Participant Details',
  VIEW_PARTICIPANT_CONTACTS: 'View Participant Contacts',
  VIEW_PARTICIPANT_GOALS: 'View Participant Goals',
  VIEW_PARTICIPANT_PROGRESS_NOTES: 'View Participant Progress Notes',
  VIEW_PARTICIPANT_GENERAL_NOTES: 'View Participant General Notes',
  VIEW_PARTICIPANT_ALERTS: 'View Participant Alerts',
  // Create Actions
  CREATE_PARTICIPANTS: 'Create Participants',
  CREATE_PARTICIPANT_DETAILS: 'Create Participant Details',
  CREATE_PARTICIPANT_CONTACTS: 'Create Participant Contacts',
  CREATE_PARTICIPANT_GOALS: 'Create Participant Goals',
  CREATE_PARTICIPANT_PROGRESS_NOTES: 'Create Participant Progress Notes',
  CREATE_PARTICIPANT_GENERAL_NOTES: 'Create Participant General Notes',
  CREATE_PARTICIPANT_ALERTS: 'Create Participant Alerts',
  // Edit Actions
  MODIFY_PARTICIPANT_DETAILS: 'Modify Participant Details',
  MODIFY_PARTICIPANT_CONTACTS: 'Modify Participant Contacts',
  MODIFY_PARTICIPANT_GOALS: 'Modify Participant Goals',
  MODIFY_PARTICIPANT_PROGRESS_NOTES: 'Modify Participant Progress Notes',
  MODIFY_PARTICIPANT_GENERAL_NOTES: 'Modify Participant General Notes',
  MODIFY_PARTICIPANT_ALERTS: 'Modify Participant Alerts',
  // Delete Actions
  DELETE_PARTICIPANTS: 'Delete Participants',
  DELETE_PARTICIPANT_DETAILS: 'Delete Participant Details',
  DELETE_PARTICIPANT_CONTACTS: 'Delete Participant Contacts',
  DELETE_PARTICIPANT_GOALS: 'Delete Participant Goals',
  DELETE_PARTICIPANT_PROGRESS_NOTES: 'Delete Participant Progress Notes',
  DELETE_PARTICIPANT_GENERAL_NOTES: 'Delete Participant General Notes',
  DELETE_PARTICIPANT_ALERTS: 'Delete Participant Alerts',

  // Participant Centre > Contacts
  // View Actions
  VIEW_CONTACTS: 'View Contacts',
  // Create Actions
  CREATE_CONTACTS: 'Create Contacts',
  // Edit Actions
  MODIFY_CONTACTS: 'Modify Contacts',
  // Delete Actions
  DELETE_CONTACTS: 'Delete Contacts',

  // Scheduling - This is the parent tab
  // View Actions
  VIEW_SCHEDULING: 'View Scheduling',

  // Scheduling > Participant Schedule
  // View Actions
  VIEW_PARTICIPANT_SCHEDULE: 'View Participant Schedule',
  // Create Actions
  CREATE_PARTICIPANT_SCHEDULE: 'Create Participant Schedule',
  // Edit Actions
  MODIFY_PARTICIPANT_SCHEDULE: 'Modify Participant Schedule',
  // Delete Actions
  DELETE_PARTICIPANT_SCHEDULE: 'Delete Participant Schedule',

  // Scheduling > Staff Roster
  // View Actions
  VIEW_STAFF_ROSTER: 'View Staff Roster',
  // Create Actions
  CREATE_STAFF_ROSTER: 'Create Staff Roster',
  // Edit Actions
  MODIFY_STAFF_ROSTER: 'Modify Staff Roster',
  // Delete Actions
  DELETE_STAFF_ROSTER: 'Delete Staff Roster',

  // Scheduling > Shift Release
  // View Actions
  VIEW_SHIFT_RELEASE: 'View Shift Release',
  // Create Actions
  CREATE_SHIFT_RELEASE: 'Create Shift Release',
  // Edit Actions
  MODIFY_SHIFT_RELEASE: 'Modify Shift Release',
  // Delete Actions
  DELETE_SHIFT_RELEASE: 'Delete Shift Release',

  // Scheduling > Shift Approval
  // View Actions
  VIEW_SHIFT_APPROVAL: 'View Shift Approval',
  // Create Actions
  CREATE_SHIFT_APPROVAL: 'Create Shift Approval',
  // Edit Actions
  MODIFY_SHIFT_APPROVAL: 'Modify Shift Approval',
  // Delete Actions
  DELETE_SHIFT_APPROVAL: 'Delete Shift Approval',

  // Scheduling > Service Team
  // View Actions
  VIEW_SERVICE_TEAM: 'View Service Team',
  // Create Actions
  CREATE_SERVICE_TEAM: 'Create Service Team',
  // Edit Actions
  MODIFY_SERVICE_TEAM: 'Modify Service Team',
  // Delete Actions
  DELETE_SERVICE_TEAM: 'Delete Service Team',

  // Scheduling > Unallocated Services
  // View Actions
  VIEW_UNALLOCATED_SERVICES: 'View Unallocated Services',
  // Create Actions
  CREATE_UNALLOCATED_SERVICES: 'Create Unallocated Services',
  // Edit Actions
  MODIFY_UNALLOCATED_SERVICES: 'Modify Unallocated Services',
  // Delete Actions
  DELETE_UNALLOCATED_SERVICES: 'Delete Unallocated Services',


  // Scheduling > Scheduling Support
  // View Actions
  VIEW_SCHEDULING_SUPPORT: 'View Scheduling Support',
  // Create Actions
  CREATE_SCHEDULING_SUPPORT: 'Create Scheduling Support',
  // Edit Actions
  MODIFY_SCHEDULING_SUPPORT: 'Modify Scheduling Support',
  // Delete Actions
  DELETE_SCHEDULING_SUPPORT: 'Delete Scheduling Support',

  // Scheduling > Notification
  // View Actions
  VIEW_NOTIFICATION: 'View Notification',
  // Create Actions
  CREATE_NOTIFICATION: 'Create Notification',
  // Edit Actions
  MODIFY_NOTIFICATION: 'Modify Notification',
  // Delete Actions
  DELETE_NOTIFICATION: 'Delete Notification',

  // Scheduling > Service Dashboard
  // View Actions
  VIEW_SERVICE_DASHBOARD: 'View Service Dashboard',
  // Create Actions
  CREATE_SERVICE_DASHBOARD: 'Create Service Dashboard',
  // Edit Actions
  MODIFY_SERVICE_DASHBOARD: 'Modify Service Dashboard',
  // Delete Actions
  DELETE_SERVICE_DASHBOARD: 'Delete Service Dashboard',

  // Billing - This is the parent tab
  // View Actions
  VIEW_BILLING: 'View Billing',

  // Billing > Service NDIS Claims
  // View Actions
  VIEW_SERVICE_NDIS_CLAIMS: 'View Service NDIS Claims',
  // Create Actions
  CREATE_SERVICE_NDIS_CLAIMS: 'Create Service NDIS Claims',
  // Edit Actions
  MODIFY_SERVICE_NDIS_CLAIMS: 'Modify Service NDIS Claims',
  // Delete Actions
  DELETE_SERVICE_NDIS_CLAIMS: 'Delete Service NDIS Claims',

  // Billing > Service Invoice
  // View Actions
  VIEW_SERVICE_INVOICE: 'View Service Invoice',
  // Create Actions
  CREATE_SERVICE_INVOICE: 'Create Service Invoice',
  // Edit Actions
  MODIFY_SERVICE_INVOICE: 'Modify Service Invoice',
  // Delete Actions
  DELETE_SERVICE_INVOICE: 'Delete Service Invoice',

  // Billing > Payroll
  // View Actions
  VIEW_PAYROLL: 'View Payroll',
  // Create Actions
  CREATE_PAYROLL: 'Create Payroll',
  // Edit Actions
  MODIFY_PAYROLL: 'Modify Payroll',
  // Delete Actions
  DELETE_PAYROLL: 'Delete Payroll',

  // Resources - This is the parent tab
  // View Actions
  VIEW_RESOURCES: 'View Resources',

  // Resources > Service Providers
  // View Actions
  VIEW_SERVICE_PROVIDERS: 'View Service Providers', // View service providers tab/list
  VIEW_SERVICE_PROVIDER_DETAILS: 'View Service Provider Details',
  VIEW_SERVICE_PROVIDER_CONTACTS: 'View Service Provider Contacts',
  VIEW_SERVICE_PROVIDER_NOTES: 'View Service Provider Notes',
  // Create Actions
  CREATE_SERVICE_PROVIDERS: 'Create Service Providers',
  CREATE_SERVICE_PROVIDER_DETAILS: 'Create Service Provider Details',
  CREATE_SERVICE_PROVIDER_CONTACTS: 'Create Service Provider Contacts',
  CREATE_SERVICE_PROVIDER_NOTES: 'Create Service Provider Notes',
  // Edit Actions
  MODIFY_SERVICE_PROVIDERS: 'Modify Service Providers',
  MODIFY_SERVICE_PROVIDER_DETAILS: 'Modify Service Provider Details',
  MODIFY_SERVICE_PROVIDER_CONTACTS: 'Modify Service Provider Contacts',
  MODIFY_SERVICE_PROVIDER_NOTES: 'Modify Service Provider Notes',
  // Delete Actions
  DELETE_SERVICE_PROVIDERS: 'Delete Service Providers',
  DELETE_SERVICE_PROVIDER_DETAILS: 'Delete Service Provider Details',
  DELETE_SERVICE_PROVIDER_CONTACTS: 'Delete Service Provider Contacts',
  DELETE_SERVICE_PROVIDER_NOTES: 'Delete Service Provider Notes',

  // Resources > Service Region
  // View Actions
  VIEW_SERVICE_REGION: 'View Service Region',
  // Create Actions
  CREATE_SERVICE_REGION: 'Create Service Region',
  // Edit Actions
  MODIFY_SERVICE_REGION: 'Modify Service Region',
  // Delete Actions
  DELETE_SERVICE_REGION: 'Delete Service Region',

  // Products and Services
  // View Actions
  VIEW_PRODUCTS_SERVICES: 'View Products Services',

  // Downloads
  // View Actions
  VIEW_DOWNLOADS: 'View Downloads',
  // Create Actions
  CREATE_DOWNLOADS: 'Create Downloads', // This is for creating a new download/triggering a download (if needed)
  // Edit Actions
  MODIFY_DOWNLOADS: 'Modify Downloads',
  // Delete Actions
  DELETE_DOWNLOADS: 'Delete Downloads',

  // Notification Alerts
  // View Actions
  VIEW_NOTIFICATION_ALERTS: 'View Notification Alerts',
  // Create Actions
  CREATE_NOTIFICATION_ALERTS: 'Create Notification Alerts',
  // Edit Actions
  MODIFY_NOTIFICATION_ALERTS: 'Modify Notification Alerts',
  // Delete Actions
  DELETE_NOTIFICATION_ALERTS: 'Delete Notification Alerts',

  // Help Centre - This is the parent tab
  // View Actions
  VIEW_HELP_CENTRE: 'View Help Centre',

  // Help Centre > Help Articles
  // View Actions
  VIEW_HELP_ARTICLES: 'View Help Articles',
  // Create Actions
  CREATE_HELP_ARTICLES: 'Create Help Articles',
  // Edit Actions
  MODIFY_HELP_ARTICLES: 'Modify Help Articles',
  // Delete Actions
  DELETE_HELP_ARTICLES: 'Delete Help Articles',

  // Help Centre > Support Requests
  // View Actions
  VIEW_SUPPORT_REQUESTS: 'View Support Requests',
  // Create Actions
  CREATE_SUPPORT_REQUESTS: 'Create Support Requests',
  // Edit Actions
  MODIFY_SUPPORT_REQUESTS: 'Modify Support Requests',
  // Delete Actions
  DELETE_SUPPORT_REQUESTS: 'Delete Support Requests',

  // Settings > Organisation Settings
  // View Actions
  VIEW_ORGANISATION_DETAILS: 'View Organisation Details',
  VIEW_SUBSCRIPTION_DETAILS: 'View Subscription Details',
  VIEW_PAYMENT_HISTORY: 'View Payment History',
  // Create Actions
  // CREATE_ORGANISATION_DETAILS: 'create_organisation_details', // Not needed - please review
  // CREATE_SUBSCRIPTION_DETAILS: 'create_subscription_details', // Not needed - please review
  // CREATE_PAYMENT_HISTORY: 'create_payment_history', // Not needed - please review
  // Edit Actions
  MODIFY_ORGANISATION_DETAILS: 'Modify Organisation Details',
  MODIFY_SUBSCRIPTION_DETAILS: 'Modify Subscription Details',
  // MODIFY_PAYMENT_HISTORY: 'modify_payment_history', // Not needed - please review
  // Delete Actions
  // DELETE_ORGANISATION_DETAILS: 'delete_organisation_details', // Not needed - please review
  // DELETE_SUBSCRIPTION_DETAILS: 'delete_subscription_details', // Not needed - please review
  // DELETE_PAYMENT_HISTORY: 'delete_payment_history', // Not needed - please review

  // Settings > User Management - This is the parent tab
  // View Actions
  VIEW_USER_MANAGEMENT_SETTINGS: 'View User Management Settings',

  // Settings > User Management > User Profiles
  // View Actions
  VIEW_USER_MANAGEMENT_SETTINGS_USER_PROFILES: 'View User Management Settings User Profiles',
  // Create Actions
  CREATE_USER_MANAGEMENT_SETTINGS_USER_PROFILES: 'Create User Management Settings User Profiles',
  // Edit Actions
  MODIFY_USER_MANAGEMENT_SETTINGS_USER_PROFILES: 'Modify User Management Settings User Profiles',
  // Delete Actions
  DELETE_USER_MANAGEMENT_SETTINGS_USER_PROFILES: 'Delete User Management Settings User Profiles',

  // Settings > User Management > User Positions
  // View Actions
  VIEW_USER_MANAGEMENT_SETTINGS_USER_POSITIONS: 'View User Management Settings User Positions',
  // Create Actions
  CREATE_USER_MANAGEMENT_SETTINGS_USER_POSITIONS: 'Create User Management Settings User Positions',
  // Edit Actions
  MODIFY_USER_MANAGEMENT_SETTINGS_USER_POSITIONS: 'Modify User Management Settings User Positions',
  // Delete Actions
  DELETE_USER_MANAGEMENT_SETTINGS_USER_POSITIONS: 'Delete User Management Settings User Positions',

  // Settings > User Management > User Roles
  // View Actions
  VIEW_USER_MANAGEMENT_SETTINGS_USER_ROLES: 'View User Management Settings User Roles',
  VIEW_USER_MANAGEMENT_SETTINGS_CUSTOM_USER_ROLES: 'View Custom User Management Settings User Roles',
  // Create Actions
  CREATE_USER_MANAGEMENT_SETTINGS_CUSTOM_USER_ROLES: 'Create Custom User Management Settings User Roles',
  // Edit Actions
  MODIFY_USER_MANAGEMENT_SETTINGS_CUSTOM_USER_ROLES: 'Modify Custom User Management Settings User Roles',
  // Delete Actions
  DELETE_USER_MANAGEMENT_SETTINGS_CUSTOM_USER_ROLES: 'Delete Custom User Management Settings User Roles',

  // Settings > Participant Settings
  // View Actions
  VIEW_PARTICIPANT_SETTINGS: 'View Participant Settings',
  VIEW_PARTICIPANT_SETTINGS_STATUS: 'View Participant Settings Status',
  VIEW_PARTICIPANT_SETTINGS_GENERAL_NOTE_TYPES: 'View Participant Settings General Note Types',
  VIEW_PARTICIPANT_SETTINGS_RELATIONSHIP_TYPES: 'View Participant Settings Relationship Types',
  VIEW_PARTICIPANT_SETTINGS_PRIMARY_DISABILITIES: 'View Participant Settings Primary Disabilities',
  VIEW_PARTICIPANT_SETTINGS_PROGRESS_NOTE_TYPES: 'View Participant Settings Progress Note Types',
  VIEW_PARTICIPANT_SETTINGS_EXIT_REASON: 'View Participant Settings Exit Reason',
  // Create Actions
  CREATE_PARTICIPANT_SETTINGS_STATUS: 'Create Participant Settings Status',
  CREATE_PARTICIPANT_SETTINGS_GENERAL_NOTE_TYPES: 'Create Participant Settings General Note Types',
  CREATE_PARTICIPANT_SETTINGS_RELATIONSHIP_TYPES: 'Create Participant Settings Relationship Types',
  CREATE_PARTICIPANT_SETTINGS_PRIMARY_DISABILITIES: 'Create Participant Settings Primary Disabilities',
  CREATE_PARTICIPANT_SETTINGS_PROGRESS_NOTE_TYPES: 'Create Participant Settings Progress Note Types',
  CREATE_PARTICIPANT_SETTINGS_EXIT_REASON: 'Create Participant Settings Exit Reason',
  // Edit Actions
  MODIFY_PARTICIPANT_SETTINGS_STATUS: 'Modify Participant Settings Status',
  MODIFY_PARTICIPANT_SETTINGS_GENERAL_NOTE_TYPES: 'Modify Participant Settings General Note Types',
  MODIFY_PARTICIPANT_SETTINGS_RELATIONSHIP_TYPES: 'Modify Participant Settings Relationship Types',
  MODIFY_PARTICIPANT_SETTINGS_PRIMARY_DISABILITIES: 'Modify Participant Settings Primary Disabilities',
  MODIFY_PARTICIPANT_SETTINGS_PROGRESS_NOTE_TYPES: 'Modify Participant Settings Progress Note Types',
  MODIFY_PARTICIPANT_SETTINGS_EXIT_REASON: 'Modify Participant Settings Exit Reason',
  // Delete Actions
  DELETE_PARTICIPANT_SETTINGS_STATUS: 'Delete Participant Settings Status',
  DELETE_PARTICIPANT_SETTINGS_GENERAL_NOTE_TYPES: 'Delete Participant Settings General Note Types',
  DELETE_PARTICIPANT_SETTINGS_RELATIONSHIP_TYPES: 'Delete Participant Settings Relationship Types',
  DELETE_PARTICIPANT_SETTINGS_PRIMARY_DISABILITIES: 'Delete Participant Settings Primary Disabilities',
  DELETE_PARTICIPANT_SETTINGS_PROGRESS_NOTE_TYPES: 'Delete Participant Settings Progress Note Types',
  DELETE_PARTICIPANT_SETTINGS_EXIT_REASON: 'Delete Participant Settings Exit Reason',

  // Settings > Schedule Settings
  // View Actions
  VIEW_SCHEDULE_SETTINGS: 'View Schedule Settings',
  VIEW_SCHEDULE_SETTINGS_SERVICE_TYPES: 'View Schedule Settings Service Types',
  VIEW_SCHEDULE_SETTINGS_CANCELLATION_CODES: 'View Schedule Settings Cancellation Codes',
  VIEW_SCHEDULE_SETTINGS_SHIFT_LOCATIONS: 'View Schedule Settings Shift Locations',
  // Create Actions
  CREATE_SCHEDULE_SETTINGS_SERVICE_TYPES: 'Create Schedule Settings Service Types',
  CREATE_SCHEDULE_SETTINGS_CANCELLATION_CODES: 'Create Schedule Settings Cancellation Codes',
  CREATE_SCHEDULE_SETTINGS_SHIFT_LOCATIONS: 'Create Schedule Settings Shift Locations',
  // Edit Actions
  MODIFY_SCHEDULE_SETTINGS_SERVICE_TYPES: 'Modify Schedule Settings Service Types',
  MODIFY_SCHEDULE_SETTINGS_CANCELLATION_CODES: 'Modify Schedule Settings Cancellation Codes',
  MODIFY_SCHEDULE_SETTINGS_SHIFT_LOCATIONS: 'Modify Schedule Settings Shift Locations',

  // Settings > Service Team Settings
  // View Actions
  VIEW_SERVICE_TEAM_SETTINGS: 'View Service Team Settings',
  VIEW_SERVICE_TEAM_SETTINGS_PAY_PERIODS: 'View Service Team Settings Pay Periods',
  VIEW_SERVICE_TEAM_SETTINGS_TAGS: 'View Service Team Settings Tags',
  VIEW_SERVICE_TEAM_SETTINGS_NOTE_TYPES: 'View Service Team Settings Note Types',
  VIEW_SERVICE_TEAM_SETTINGS_TIMESHEET_DECLINED_REASONS: 'View Service Team Settings Timesheet Declined Reasons',
  VIEW_SERVICE_TEAM_SETTINGS_SHIFT_CANCELLED_REASONS: 'View Service Team Settings Shift Cancelled Reasons',
  // Create Actions
  // CREATE_SERVICE_TEAM_SETTINGS_PAY_PERIODS: 'create_service_team_pay_periods', // Not needed - please review
  CREATE_SERVICE_TEAM_SETTINGS_TAGS: 'Create Service Team Settings Tags',
  CREATE_SERVICE_TEAM_SETTINGS_NOTE_TYPES: 'Create Service Team Settings Note Types',
  CREATE_SERVICE_TEAM_SETTINGS_TIMESHEET_DECLINED_REASONS: 'Create Service Team Settings Timesheet Declined Reasons',
  CREATE_SERVICE_TEAM_SETTINGS_SHIFT_CANCELLED_REASONS: 'Create Service Team Settings Shift Cancelled Reasons',
  // Edit Actions
  MODIFY_SERVICE_TEAM_SETTINGS_PAY_PERIODS: 'Modify Service Team Settings Pay Periods',
  MODIFY_SERVICE_TEAM_SETTINGS_TAGS: 'Modify Service Team Settings Tags',
  MODIFY_SERVICE_TEAM_SETTINGS_NOTE_TYPES: 'Modify Service Team Settings Note Types',
  MODIFY_SERVICE_TEAM_SETTINGS_TIMESHEET_DECLINED_REASONS: 'Modify Service Team Settings Timesheet Declined Reasons',
  MODIFY_SERVICE_TEAM_SETTINGS_SHIFT_CANCELLED_REASONS: 'Modify Service Team Settings Shift Cancelled Reasons',
  // Delete Actions
  // DELETE_SERVICE_TEAM_SETTINGS_PAY_PERIODS: 'delete_service_team_pay_periods', // Not needed - please review
  DELETE_SERVICE_TEAM_SETTINGS_TAGS: 'Delete Service Team Settings Tags',
  DELETE_SERVICE_TEAM_SETTINGS_NOTE_TYPES: 'Delete Service Team Settings Note Types',
  DELETE_SERVICE_TEAM_SETTINGS_TIMESHEET_DECLINED_REASONS: 'Delete Service Team Settings Timesheet Declined Reasons',
  DELETE_SERVICE_TEAM_SETTINGS_SHIFT_CANCELLED_REASONS: 'Delete Service Team Settings Shift Cancelled Reasons',

  // Settings > Service Provider Settings
  // View Actions
  VIEW_SERVICE_PROVIDER_SETTINGS: 'View Service Provider Settings',
  VIEW_SERVICE_PROVIDER_SETTINGS_POSITIONS: 'View Service Provider Settings Positions',
  VIEW_SERVICE_PROVIDER_SETTINGS_NOTE_TYPES: 'View Service Provider Settings Note Types',
  // Create Actions
  CREATE_SERVICE_PROVIDER_SETTINGS_POSITIONS: 'Create Service Provider Settings Positions',
  CREATE_SERVICE_PROVIDER_SETTINGS_NOTE_TYPES: 'Create Service Provider Settings Note Types',
  // Edit Actions
  MODIFY_SERVICE_PROVIDER_SETTINGS_POSITIONS: 'Modify Service Provider Settings Positions',
  MODIFY_SERVICE_PROVIDER_SETTINGS_NOTE_TYPES: 'Modify Service Provider Settings Note Types',
  // Delete Actions
  DELETE_SERVICE_PROVIDER_SETTINGS_POSITIONS: 'Delete Service Provider Settings Positions',
  DELETE_SERVICE_PROVIDER_SETTINGS_NOTE_TYPES: 'Delete Service Provider Settings Note Types',

  // Settings > Availability Settings
  // View Actions
  VIEW_AVAILABILITY_SETTINGS: 'View Availability Settings',
  VIEW_AVAILABILITY_SETTINGS_AVAILABILITY_TEMPLATES: 'View Availability Settings Availability Templates',
  VIEW_AVAILABILITY_SETTINGS_LEAVE_TYPES: 'View Availability Settings Leave Types',
  // Create Actions
  CREATE_AVAILABILITY_SETTINGS_AVAILABILITY_TEMPLATES: 'Create Availability Settings Availability Templates',
  CREATE_AVAILABILITY_SETTINGS_LEAVE_TYPES: 'Create Availability Settings Leave Types',
  // Edit Actions
  MODIFY_AVAILABILITY_SETTINGS_AVAILABILITY_TEMPLATES: 'Modify Availability Settings Availability Templates',
  MODIFY_AVAILABILITY_SETTINGS_LEAVE_TYPES: 'Modify Availability Settings Leave Types',
  // Delete Actions
  DELETE_AVAILABILITY_SETTINGS_AVAILABILITY_TEMPLATES: 'Delete Availability Settings Availability Templates',
  DELETE_AVAILABILITY_SETTINGS_LEAVE_TYPES: 'Delete Availability Settings Leave Types',

  // Settings > Availability Settings
  // View Actions
  VIEW_NOTIFICATION_SETTINGS: 'View Notification Settings', // Please Review
  // Create Actions
  CREATE_NOTIFICATION_SETTINGS: 'Create Notification Settings', // Please Review
  // Edit Actions
  MODIFY_NOTIFICATION_SETTINGS: 'Modify Notification Settings', // Please Review
  // Delete Actions
  DELETE_NOTIFICATION_SETTINGS: 'Delete Notification Settings', // Please Review

  // Settings > Integration Settings
  // View Actions
  VIEW_INTEGRATION_SETTINGS: 'View Integration Settings', // Please Review
  // Create Actions
  CREATE_INTEGRATION_SETTINGS: 'Create Integration Settings', // Please Review
  // Edit Actions
  MODIFY_INTEGRATION_SETTINGS: 'Modify Integration Settings', // Please Review
  // Delete Actions
  DELETE_INTEGRATION_SETTINGS: 'Delete Integration Settings', // Please Review
};

// The below is not used in the application but is used as a reference
export const roles = {
  STANDARD_USER: {
    description: 'Access to all areas except Incidents and Settings. Able to create records but not edit or delete.',
    permissions: [
      // permissions.VIEW_UNALLOCATED_SERVICES,
      // permissions.CREATE_UNALLOCATED_SERVICES,
      // permissions.MODIFY_UNALLOCATED_SERVICES,
      // permissions.DELETE_UNALLOCATED_SERVICES,
      // permissions.APPROVE_SCHEDULE_INTERNALLY,
      // permissions.APPROVE_TIMESHEET,
      // permissions.CAN_TRANSFER_OWNERSHIP,
      permissions.VIEW_HOME,
      permissions.VIEW_POWERVIEW,
      permissions.VIEW_PARTICIPANT_CENTRE,
      permissions.VIEW_PARTICIPANTS,
      permissions.VIEW_PARTICIPANT_DETAILS,
      permissions.VIEW_PARTICIPANT_CONTACTS,
      permissions.VIEW_PARTICIPANT_GOALS,
      // permissions.VIEW_PARTICIPANT_PROGRESS_NOTES,
      permissions.VIEW_PARTICIPANT_GENERAL_NOTES,
      permissions.VIEW_PARTICIPANT_ALERTS,
      permissions.CREATE_PARTICIPANTS,
      permissions.CREATE_PARTICIPANT_DETAILS,
      permissions.CREATE_PARTICIPANT_CONTACTS,
      permissions.CREATE_PARTICIPANT_GOALS,
      // permissions.CREATE_PARTICIPANT_PROGRESS_NOTES,
      permissions.CREATE_PARTICIPANT_GENERAL_NOTES,
      permissions.CREATE_PARTICIPANT_ALERTS,
      // permissions.MODIFY_PARTICIPANT_DETAILS,
      // permissions.MODIFY_PARTICIPANT_CONTACTS,
      // permissions.MODIFY_PARTICIPANT_GOALS,
      // permissions.MODIFY_PARTICIPANT_PROGRESS_NOTES,
      // permissions.MODIFY_PARTICIPANT_GENERAL_NOTES,
      // permissions.MODIFY_PARTICIPANT_ALERTS,
      // permissions.DELETE_PARTICIPANTS,
      // permissions.DELETE_PARTICIPANT_DETAILS,
      // permissions.DELETE_PARTICIPANT_CONTACTS,
      // permissions.DELETE_PARTICIPANT_GOALS,
      // permissions.DELETE_PARTICIPANT_PROGRESS_NOTES,
      // permissions.DELETE_PARTICIPANT_GENERAL_NOTES,
      // permissions.DELETE_PARTICIPANT_ALERTS,
      permissions.VIEW_CONTACTS,
      permissions.CREATE_CONTACTS,
      // permissions.MODIFY_CONTACTS,
      // permissions.DELETE_CONTACTS,
      permissions.VIEW_SCHEDULING,
      permissions.VIEW_PARTICIPANT_SCHEDULE,
      permissions.CREATE_PARTICIPANT_SCHEDULE,
      // permissions.MODIFY_PARTICIPANT_SCHEDULE,
      // permissions.DELETE_PARTICIPANT_SCHEDULE,
      permissions.VIEW_STAFF_ROSTER,
      permissions.CREATE_STAFF_ROSTER,
      // permissions.MODIFY_STAFF_ROSTER,
      // permissions.DELETE_STAFF_ROSTER,
      permissions.VIEW_SHIFT_RELEASE,
      permissions.CREATE_SHIFT_RELEASE,
      // permissions.MODIFY_SHIFT_RELEASE,
      // permissions.DELETE_SHIFT_RELEASE,
      permissions.VIEW_SHIFT_APPROVAL,
      permissions.CREATE_SHIFT_APPROVAL,
      // permissions.MODIFY_SHIFT_APPROVAL,
      // permissions.DELETE_SHIFT_APPROVAL,
      permissions.VIEW_SERVICE_TEAM,
      permissions.CREATE_SERVICE_TEAM,
      // permissions.MODIFY_SERVICE_TEAM,
      // permissions.DELETE_SERVICE_TEAM,
      permissions.VIEW_SCHEDULING_SUPPORT,
      permissions.CREATE_SCHEDULING_SUPPORT,
      // permissions.MODIFY_SCHEDULING_SUPPORT,
      // permissions.DELETE_SCHEDULING_SUPPORT,
      // permissions.VIEW_NOTIFICATION,
      // permissions.CREATE_NOTIFICATION,
      // permissions.MODIFY_NOTIFICATION,
      // permissions.DELETE_NOTIFICATION,
      permissions.VIEW_SERVICE_DASHBOARD,
      permissions.CREATE_SERVICE_DASHBOARD,
      // permissions.MODIFY_SERVICE_DASHBOARD,
      // permissions.DELETE_SERVICE_DASHBOARD,
      permissions.VIEW_BILLING,
      permissions.VIEW_SERVICE_NDIS_CLAIMS,
      permissions.CREATE_SERVICE_NDIS_CLAIMS,
      // permissions.MODIFY_SERVICE_NDIS_CLAIMS,
      // permissions.DELETE_SERVICE_NDIS_CLAIMS,
      permissions.VIEW_SERVICE_INVOICE,
      permissions.CREATE_SERVICE_INVOICE,
      // permissions.MODIFY_SERVICE_INVOICE,
      // permissions.DELETE_SERVICE_INVOICE,
      permissions.VIEW_PAYROLL,
      permissions.CREATE_PAYROLL,
      // permissions.MODIFY_PAYROLL,
      // permissions.DELETE_PAYROLL,
      permissions.VIEW_RESOURCES,
      permissions.VIEW_SERVICE_PROVIDERS,
      permissions.VIEW_SERVICE_PROVIDER_DETAILS,
      permissions.VIEW_SERVICE_PROVIDER_CONTACTS,
      permissions.VIEW_SERVICE_PROVIDER_NOTES,
      permissions.CREATE_SERVICE_PROVIDERS,
      permissions.CREATE_SERVICE_PROVIDER_DETAILS,
      permissions.CREATE_SERVICE_PROVIDER_CONTACTS,
      permissions.CREATE_SERVICE_PROVIDER_NOTES,
      // permissions.MODIFY_SERVICE_PROVIDERS,
      // permissions.MODIFY_SERVICE_PROVIDER_DETAILS,
      // permissions.MODIFY_SERVICE_PROVIDER_CONTACTS,
      // permissions.MODIFY_SERVICE_PROVIDER_NOTES,
      // permissions.DELETE_SERVICE_PROVIDERS,
      // permissions.DELETE_SERVICE_PROVIDER_DETAILS,
      // permissions.DELETE_SERVICE_PROVIDER_CONTACTS,
      // permissions.DELETE_SERVICE_PROVIDER_NOTES,
      permissions.VIEW_SERVICE_REGION,
      permissions.CREATE_SERVICE_REGION,
      // permissions.MODIFY_SERVICE_REGION,
      // permissions.DELETE_SERVICE_REGION,
      permissions.VIEW_PRODUCTS_SERVICES,
      permissions.VIEW_DOWNLOADS,
      permissions.CREATE_DOWNLOADS,
      // permissions.MODIFY_DOWNLOADS,
      // permissions.DELETE_DOWNLOADS,
      // permissions.VIEW_NOTIFICATION_ALERTS,
      // permissions.CREATE_NOTIFICATION_ALERTS,
      // permissions.MODIFY_NOTIFICATION_ALERTS,
      // permissions.DELETE_NOTIFICATION_ALERTS,
      permissions.VIEW_HELP_ARTICLES,
      permissions.CREATE_HELP_ARTICLES,
      // permissions.MODIFY_HELP_ARTICLES,
      // permissions.DELETE_HELP_ARTICLES,
      permissions.VIEW_SUPPORT_REQUESTS,
      permissions.CREATE_SUPPORT_REQUESTS,
      // permissions.MODIFY_SUPPORT_REQUESTS,
      // permissions.DELETE_SUPPORT_REQUESTS,
      // permissions.VIEW_ORGANISATION_DETAILS,
      // permissions.VIEW_SUBSCRIPTION_DETAILS,
      // permissions.VIEW_PAYMENT_HISTORY,
      // permissions.MODIFY_ORGANISATION_DETAILS,
      // permissions.MODIFY_SUBSCRIPTION_DETAILS,
      // permissions.VIEW_USER_MANAGEMENT_SETTINGS_USER_PROFILES,
      // permissions.CREATE_USER_MANAGEMENT_SETTINGS_USER_PROFILES,
      // permissions.MODIFY_USER_MANAGEMENT_SETTINGS_USER_PROFILES,
      // permissions.DELETE_USER_MANAGEMENT_SETTINGS_USER_PROFILES,
      // permissions.VIEW_USER_MANAGEMENT_SETTINGS_USER_POSITIONS,
      // permissions.CREATE_USER_MANAGEMENT_SETTINGS_USER_POSITIONS,
      // permissions.MODIFY_USER_MANAGEMENT_SETTINGS_USER_POSITIONS,
      // permissions.DELETE_USER_MANAGEMENT_SETTINGS_USER_POSITIONS,
      // permissions.VIEW_USER_MANAGEMENT_SETTINGS_USER_ROLES,
      // permissions.VIEW_USER_MANAGEMENT_SETTINGS_CUSTOM_USER_ROLES,
      // permissions.CREATE_USER_MANAGEMENT_SETTINGS_CUSTOM_USER_ROLES,
      // permissions.MODIFY_USER_MANAGEMENT_SETTINGS_CUSTOM_USER_ROLES,
      // permissions.DELETE_USER_MANAGEMENT_SETTINGS_CUSTOM_USER_ROLES,
      // permissions.VIEW_PARTICIPANT_SETTINGS,
      // permissions.VIEW_PARTICIPANT_SETTINGS_STATUS,
      // permissions.VIEW_PARTICIPANT_SETTINGS_GENERAL_NOTE_TYPES,
      // permissions.VIEW_PARTICIPANT_SETTINGS_RELATIONSHIP_TYPES,
      // permissions.VIEW_PARTICIPANT_SETTINGS_PRIMARY_DISABILITIES,
      // permissions.VIEW_PARTICIPANT_SETTINGS_PROGRESS_NOTE_TYPES,
      // permissions.VIEW_PARTICIPANT_SETTINGS_EXIT_REASON,
      // permissions.CREATE_PARTICIPANT_SETTINGS_STATUS,
      // permissions.CREATE_PARTICIPANT_SETTINGS_GENERAL_NOTE_TYPES,
      // permissions.CREATE_PARTICIPANT_SETTINGS_RELATIONSHIP_TYPES,
      // permissions.CREATE_PARTICIPANT_SETTINGS_PRIMARY_DISABILITIES,
      // permissions.CREATE_PARTICIPANT_SETTINGS_PROGRESS_NOTE_TYPES,
      // permissions.CREATE_PARTICIPANT_SETTINGS_EXIT_REASON,
      // permissions.MODIFY_PARTICIPANT_SETTINGS_STATUS,
      // permissions.MODIFY_PARTICIPANT_SETTINGS_GENERAL_NOTE_TYPES,
      // permissions.MODIFY_PARTICIPANT_SETTINGS_RELATIONSHIP_TYPES,
      // permissions.MODIFY_PARTICIPANT_SETTINGS_PRIMARY_DISABILITIES,
      // permissions.MODIFY_PARTICIPANT_SETTINGS_PROGRESS_NOTE_TYPES,
      // permissions.MODIFY_PARTICIPANT_SETTINGS_EXIT_REASON,
      // permissions.DELETE_PARTICIPANT_SETTINGS_STATUS,
      // permissions.DELETE_PARTICIPANT_SETTINGS_GENERAL_NOTE_TYPES,
      // permissions.DELETE_PARTICIPANT_SETTINGS_RELATIONSHIP_TYPES,
      // permissions.DELETE_PARTICIPANT_SETTINGS_PRIMARY_DISABILITIES,
      // permissions.DELETE_PARTICIPANT_SETTINGS_PROGRESS_NOTE_TYPES,
      // permissions.DELETE_PARTICIPANT_SETTINGS_EXIT_REASON,
      // permissions.VIEW_SCHEDULE_SETTINGS,
      // permissions.VIEW_SCHEDULE_SETTINGS_SERVICE_TYPES,
      // permissions.VIEW_SCHEDULE_SETTINGS_CANCELLATION_CODES,
      // permissions.VIEW_SCHEDULE_SETTINGS_SHIFT_LOCATIONS,
      // permissions.CREATE_SCHEDULE_SETTINGS_SERVICE_TYPES,
      // permissions.CREATE_SCHEDULE_SETTINGS_CANCELLATION_CODES,
      // permissions.CREATE_SCHEDULE_SETTINGS_SHIFT_LOCATIONS,
      // permissions.MODIFY_SCHEDULE_SETTINGS_SERVICE_TYPES,
      // permissions.MODIFY_SCHEDULE_SETTINGS_CANCELLATION_CODES,
      // permissions.MODIFY_SCHEDULE_SETTINGS_SHIFT_LOCATIONS,
      // permissions.VIEW_SERVICE_TEAM_SETTINGS,
      // permissions.VIEW_SERVICE_TEAM_SETTINGS_PAY_PERIODS,
      // permissions.VIEW_SERVICE_TEAM_SETTINGS_TAGS,
      // permissions.VIEW_SERVICE_TEAM_SETTINGS_NOTE_TYPES,
      // permissions.VIEW_SERVICE_TEAM_SETTINGS_TIMESHEET_DECLINED_REASONS,
      // permissions.VIEW_SERVICE_TEAM_SETTINGS_SHIFT_CANCELLED_REASONS,
      // permissions.CREATE_SERVICE_TEAM_SETTINGS_TAGS,
      // permissions.CREATE_SERVICE_TEAM_SETTINGS_NOTE_TYPES,
      // permissions.CREATE_SERVICE_TEAM_SETTINGS_TIMESHEET_DECLINED_REASONS,
      // permissions.CREATE_SERVICE_TEAM_SETTINGS_SHIFT_CANCELLED_REASONS,
      // permissions.MODIFY_SERVICE_TEAM_SETTINGS_PAY_PERIODS,
      // permissions.MODIFY_SERVICE_TEAM_SETTINGS_TAGS,
      // permissions.MODIFY_SERVICE_TEAM_SETTINGS_NOTE_TYPES,
      // permissions.MODIFY_SERVICE_TEAM_SETTINGS_TIMESHEET_DECLINED_REASONS,
      // permissions.MODIFY_SERVICE_TEAM_SETTINGS_SHIFT_CANCELLED_REASONS,
      // permissions.DELETE_SERVICE_TEAM_SETTINGS_TAGS,
      // permissions.DELETE_SERVICE_TEAM_SETTINGS_NOTE_TYPES,
      // permissions.DELETE_SERVICE_TEAM_SETTINGS_TIMESHEET_DECLINED_REASONS,
      // permissions.DELETE_SERVICE_TEAM_SETTINGS_SHIFT_CANCELLED_REASONS,
      // permissions.VIEW_SERVICE_PROVIDER_SETTINGS,
      // permissions.VIEW_SERVICE_PROVIDER_SETTINGS_POSITIONS,
      // permissions.VIEW_SERVICE_PROVIDER_SETTINGS_NOTE_TYPES,
      // permissions.CREATE_SERVICE_PROVIDER_SETTINGS_POSITIONS,
      // permissions.CREATE_SERVICE_PROVIDER_SETTINGS_NOTE_TYPES,
      // permissions.MODIFY_SERVICE_PROVIDER_SETTINGS_POSITIONS,
      // permissions.MODIFY_SERVICE_PROVIDER_SETTINGS_NOTE_TYPES,
      // permissions.VIEW_AVAILABILITY_SETTINGS,
      // permissions.VIEW_AVAILABILITY_SETTINGS_AVAILABILITY_TEMPLATES,
      // permissions.VIEW_AVAILABILITY_SETTINGS_LEAVE_TYPES,
      // permissions.CREATE_AVAILABILITY_SETTINGS_AVAILABILITY_TEMPLATES,
      // permissions.CREATE_AVAILABILITY_SETTINGS_LEAVE_TYPES,
      // permissions.MODIFY_AVAILABILITY_SETTINGS_AVAILABILITY_TEMPLATES,
      // permissions.MODIFY_AVAILABILITY_SETTINGS_LEAVE_TYPES,
      // permissions.DELETE_AVAILABILITY_SETTINGS_AVAILABILITY_TEMPLATES,
      // permissions.DELETE_AVAILABILITY_SETTINGS_LEAVE_TYPES,
      // permissions.VIEW_NOTIFICATION_SETTINGS,
      // permissions.CREATE_NOTIFICATION_SETTINGS,
      // permissions.MODIFY_NOTIFICATION_SETTINGS,
      // permissions.DELETE_NOTIFICATION_SETTINGS,
      // permissions.VIEW_INTEGRATION_SETTINGS,
      // permissions.CREATE_INTEGRATION_SETTINGS,
      // permissions.MODIFY_INTEGRATION_SETTINGS,
      // permissions.DELETE_INTEGRATION_SETTINGS,
    ],
  },
  SYSTEM_ADMINISTRATOR: {
    description: 'Access to everything including settings (except Organisation Settings). Can complete all actions.',
    permissions: [
      permissions.VIEW_UNALLOCATED_SERVICES,
      permissions.CREATE_UNALLOCATED_SERVICES,
      permissions.MODIFY_UNALLOCATED_SERVICES,
      permissions.DELETE_UNALLOCATED_SERVICES,
      permissions.RELEASE_SHIFTS,
      permissions.APPROVE_SCHEDULE_INTERNALLY,
      permissions.APPROVE_TIMESHEET,
      // permissions.CAN_TRANSFER_OWNERSHIP,
      permissions.VIEW_HOME,
      permissions.VIEW_POWERVIEW,
      permissions.VIEW_PARTICIPANT_CENTRE,
      permissions.VIEW_PARTICIPANTS,
      permissions.VIEW_PARTICIPANT_DETAILS,
      permissions.VIEW_PARTICIPANT_CONTACTS,
      permissions.VIEW_PARTICIPANT_GOALS,
      permissions.VIEW_PARTICIPANT_PROGRESS_NOTES,
      permissions.VIEW_PARTICIPANT_GENERAL_NOTES,
      permissions.VIEW_PARTICIPANT_ALERTS,
      permissions.CREATE_PARTICIPANTS,
      permissions.CREATE_PARTICIPANT_DETAILS,
      permissions.CREATE_PARTICIPANT_CONTACTS,
      permissions.CREATE_PARTICIPANT_GOALS,
      permissions.CREATE_PARTICIPANT_PROGRESS_NOTES,
      permissions.CREATE_PARTICIPANT_GENERAL_NOTES,
      permissions.CREATE_PARTICIPANT_ALERTS,
      permissions.MODIFY_PARTICIPANT_DETAILS,
      permissions.MODIFY_PARTICIPANT_CONTACTS,
      permissions.MODIFY_PARTICIPANT_GOALS,
      permissions.MODIFY_PARTICIPANT_PROGRESS_NOTES,
      permissions.MODIFY_PARTICIPANT_GENERAL_NOTES,
      permissions.MODIFY_PARTICIPANT_ALERTS,
      permissions.DELETE_PARTICIPANTS,
      permissions.DELETE_PARTICIPANT_DETAILS,
      permissions.DELETE_PARTICIPANT_CONTACTS,
      permissions.DELETE_PARTICIPANT_GOALS,
      permissions.DELETE_PARTICIPANT_PROGRESS_NOTES,
      permissions.DELETE_PARTICIPANT_GENERAL_NOTES,
      permissions.DELETE_PARTICIPANT_ALERTS,
      permissions.VIEW_CONTACTS,
      permissions.CREATE_CONTACTS,
      permissions.MODIFY_CONTACTS,
      permissions.DELETE_CONTACTS,
      permissions.VIEW_SCHEDULING,
      permissions.VIEW_PARTICIPANT_SCHEDULE,
      permissions.CREATE_PARTICIPANT_SCHEDULE,
      permissions.MODIFY_PARTICIPANT_SCHEDULE,
      permissions.DELETE_PARTICIPANT_SCHEDULE,
      permissions.VIEW_STAFF_ROSTER,
      permissions.CREATE_STAFF_ROSTER,
      permissions.MODIFY_STAFF_ROSTER,
      permissions.DELETE_STAFF_ROSTER,
      permissions.VIEW_SHIFT_RELEASE,
      permissions.CREATE_SHIFT_RELEASE,
      permissions.MODIFY_SHIFT_RELEASE,
      permissions.DELETE_SHIFT_RELEASE,
      permissions.VIEW_SHIFT_APPROVAL,
      permissions.CREATE_SHIFT_APPROVAL,
      permissions.MODIFY_SHIFT_APPROVAL,
      permissions.DELETE_SHIFT_APPROVAL,
      permissions.VIEW_SERVICE_TEAM,
      permissions.CREATE_SERVICE_TEAM,
      permissions.MODIFY_SERVICE_TEAM,
      permissions.DELETE_SERVICE_TEAM,
      permissions.VIEW_SCHEDULING_SUPPORT,
      permissions.CREATE_SCHEDULING_SUPPORT,
      permissions.MODIFY_SCHEDULING_SUPPORT,
      permissions.DELETE_SCHEDULING_SUPPORT,
      permissions.VIEW_NOTIFICATION,
      permissions.CREATE_NOTIFICATION,
      permissions.MODIFY_NOTIFICATION,
      permissions.DELETE_NOTIFICATION,
      permissions.VIEW_SERVICE_DASHBOARD,
      permissions.CREATE_SERVICE_DASHBOARD,
      permissions.MODIFY_SERVICE_DASHBOARD,
      permissions.DELETE_SERVICE_DASHBOARD,
      permissions.VIEW_BILLING,
      permissions.VIEW_SERVICE_NDIS_CLAIMS,
      permissions.CREATE_SERVICE_NDIS_CLAIMS,
      permissions.MODIFY_SERVICE_NDIS_CLAIMS,
      permissions.DELETE_SERVICE_NDIS_CLAIMS,
      permissions.VIEW_SERVICE_INVOICE,
      permissions.CREATE_SERVICE_INVOICE,
      permissions.MODIFY_SERVICE_INVOICE,
      permissions.DELETE_SERVICE_INVOICE,
      permissions.VIEW_PAYROLL,
      permissions.CREATE_PAYROLL,
      permissions.MODIFY_PAYROLL,
      permissions.DELETE_PAYROLL,
      permissions.VIEW_RESOURCES,
      permissions.VIEW_SERVICE_PROVIDERS,
      permissions.VIEW_SERVICE_PROVIDER_DETAILS,
      permissions.VIEW_SERVICE_PROVIDER_CONTACTS,
      permissions.VIEW_SERVICE_PROVIDER_NOTES,
      permissions.CREATE_SERVICE_PROVIDERS,
      permissions.CREATE_SERVICE_PROVIDER_DETAILS,
      permissions.CREATE_SERVICE_PROVIDER_CONTACTS,
      permissions.CREATE_SERVICE_PROVIDER_NOTES,
      permissions.MODIFY_SERVICE_PROVIDERS,
      permissions.MODIFY_SERVICE_PROVIDER_DETAILS,
      permissions.MODIFY_SERVICE_PROVIDER_CONTACTS,
      permissions.MODIFY_SERVICE_PROVIDER_NOTES,
      permissions.DELETE_SERVICE_PROVIDERS,
      permissions.DELETE_SERVICE_PROVIDER_DETAILS,
      permissions.DELETE_SERVICE_PROVIDER_CONTACTS,
      permissions.DELETE_SERVICE_PROVIDER_NOTES,
      permissions.VIEW_SERVICE_REGION,
      permissions.CREATE_SERVICE_REGION,
      permissions.MODIFY_SERVICE_REGION,
      permissions.DELETE_SERVICE_REGION,
      permissions.VIEW_PRODUCTS_SERVICES,
      permissions.VIEW_DOWNLOADS,
      permissions.CREATE_DOWNLOADS,
      permissions.MODIFY_DOWNLOADS,
      permissions.DELETE_DOWNLOADS,
      permissions.VIEW_NOTIFICATION_ALERTS,
      permissions.CREATE_NOTIFICATION_ALERTS,
      permissions.MODIFY_NOTIFICATION_ALERTS,
      permissions.DELETE_NOTIFICATION_ALERTS,
      permissions.VIEW_HELP_ARTICLES,
      permissions.CREATE_HELP_ARTICLES,
      permissions.MODIFY_HELP_ARTICLES,
      permissions.DELETE_HELP_ARTICLES,
      permissions.VIEW_SUPPORT_REQUESTS,
      permissions.CREATE_SUPPORT_REQUESTS,
      permissions.MODIFY_SUPPORT_REQUESTS,
      permissions.DELETE_SUPPORT_REQUESTS,
      // permissions.VIEW_ORGANISATION_DETAILS,
      // permissions.VIEW_SUBSCRIPTION_DETAILS,
      // permissions.VIEW_PAYMENT_HISTORY,
      // permissions.MODIFY_ORGANISATION_DETAILS,
      // permissions.MODIFY_SUBSCRIPTION_DETAILS,
      permissions.VIEW_USER_MANAGEMENT_SETTINGS_USER_PROFILES,
      permissions.CREATE_USER_MANAGEMENT_SETTINGS_USER_PROFILES,
      permissions.MODIFY_USER_MANAGEMENT_SETTINGS_USER_PROFILES,
      permissions.DELETE_USER_MANAGEMENT_SETTINGS_USER_PROFILES,
      permissions.VIEW_USER_MANAGEMENT_SETTINGS_USER_POSITIONS,
      permissions.CREATE_USER_MANAGEMENT_SETTINGS_USER_POSITIONS,
      permissions.MODIFY_USER_MANAGEMENT_SETTINGS_USER_POSITIONS,
      permissions.DELETE_USER_MANAGEMENT_SETTINGS_USER_POSITIONS,
      permissions.VIEW_USER_MANAGEMENT_SETTINGS_USER_ROLES,
      permissions.VIEW_USER_MANAGEMENT_SETTINGS_CUSTOM_USER_ROLES,
      permissions.CREATE_USER_MANAGEMENT_SETTINGS_CUSTOM_USER_ROLES,
      permissions.MODIFY_USER_MANAGEMENT_SETTINGS_CUSTOM_USER_ROLES,
      permissions.DELETE_USER_MANAGEMENT_SETTINGS_CUSTOM_USER_ROLES,
      permissions.VIEW_PARTICIPANT_SETTINGS,
      permissions.VIEW_PARTICIPANT_SETTINGS_STATUS,
      permissions.VIEW_PARTICIPANT_SETTINGS_GENERAL_NOTE_TYPES,
      permissions.VIEW_PARTICIPANT_SETTINGS_RELATIONSHIP_TYPES,
      permissions.VIEW_PARTICIPANT_SETTINGS_PRIMARY_DISABILITIES,
      permissions.VIEW_PARTICIPANT_SETTINGS_PROGRESS_NOTE_TYPES,
      permissions.VIEW_PARTICIPANT_SETTINGS_EXIT_REASON,
      permissions.CREATE_PARTICIPANT_SETTINGS_STATUS,
      permissions.CREATE_PARTICIPANT_SETTINGS_GENERAL_NOTE_TYPES,
      permissions.CREATE_PARTICIPANT_SETTINGS_RELATIONSHIP_TYPES,
      permissions.CREATE_PARTICIPANT_SETTINGS_PRIMARY_DISABILITIES,
      permissions.CREATE_PARTICIPANT_SETTINGS_PROGRESS_NOTE_TYPES,
      permissions.CREATE_PARTICIPANT_SETTINGS_EXIT_REASON,
      permissions.MODIFY_PARTICIPANT_SETTINGS_STATUS,
      permissions.MODIFY_PARTICIPANT_SETTINGS_GENERAL_NOTE_TYPES,
      permissions.MODIFY_PARTICIPANT_SETTINGS_RELATIONSHIP_TYPES,
      permissions.MODIFY_PARTICIPANT_SETTINGS_PRIMARY_DISABILITIES,
      permissions.MODIFY_PARTICIPANT_SETTINGS_PROGRESS_NOTE_TYPES,
      permissions.MODIFY_PARTICIPANT_SETTINGS_EXIT_REASON,
      permissions.DELETE_PARTICIPANT_SETTINGS_STATUS,
      permissions.DELETE_PARTICIPANT_SETTINGS_GENERAL_NOTE_TYPES,
      permissions.DELETE_PARTICIPANT_SETTINGS_RELATIONSHIP_TYPES,
      permissions.DELETE_PARTICIPANT_SETTINGS_PRIMARY_DISABILITIES,
      permissions.DELETE_PARTICIPANT_SETTINGS_PROGRESS_NOTE_TYPES,
      permissions.DELETE_PARTICIPANT_SETTINGS_EXIT_REASON,
      permissions.VIEW_SCHEDULE_SETTINGS,
      permissions.VIEW_SCHEDULE_SETTINGS_SERVICE_TYPES,
      permissions.VIEW_SCHEDULE_SETTINGS_CANCELLATION_CODES,
      permissions.VIEW_SCHEDULE_SETTINGS_SHIFT_LOCATIONS,
      permissions.CREATE_SCHEDULE_SETTINGS_SERVICE_TYPES,
      permissions.CREATE_SCHEDULE_SETTINGS_CANCELLATION_CODES,
      permissions.CREATE_SCHEDULE_SETTINGS_SHIFT_LOCATIONS,
      permissions.MODIFY_SCHEDULE_SETTINGS_SERVICE_TYPES,
      permissions.MODIFY_SCHEDULE_SETTINGS_CANCELLATION_CODES,
      permissions.MODIFY_SCHEDULE_SETTINGS_SHIFT_LOCATIONS,
      permissions.VIEW_SERVICE_TEAM_SETTINGS,
      permissions.VIEW_SERVICE_TEAM_SETTINGS_PAY_PERIODS,
      permissions.VIEW_SERVICE_TEAM_SETTINGS_TAGS,
      permissions.VIEW_SERVICE_TEAM_SETTINGS_NOTE_TYPES,
      permissions.VIEW_SERVICE_TEAM_SETTINGS_TIMESHEET_DECLINED_REASONS,
      permissions.VIEW_SERVICE_TEAM_SETTINGS_SHIFT_CANCELLED_REASONS,
      permissions.CREATE_SERVICE_TEAM_SETTINGS_TAGS,
      permissions.CREATE_SERVICE_TEAM_SETTINGS_NOTE_TYPES,
      permissions.CREATE_SERVICE_TEAM_SETTINGS_TIMESHEET_DECLINED_REASONS,
      permissions.CREATE_SERVICE_TEAM_SETTINGS_SHIFT_CANCELLED_REASONS,
      permissions.MODIFY_SERVICE_TEAM_SETTINGS_PAY_PERIODS,
      permissions.MODIFY_SERVICE_TEAM_SETTINGS_TAGS,
      permissions.MODIFY_SERVICE_TEAM_SETTINGS_NOTE_TYPES,
      permissions.MODIFY_SERVICE_TEAM_SETTINGS_TIMESHEET_DECLINED_REASONS,
      permissions.MODIFY_SERVICE_TEAM_SETTINGS_SHIFT_CANCELLED_REASONS,
      permissions.DELETE_SERVICE_TEAM_SETTINGS_TAGS,
      permissions.DELETE_SERVICE_TEAM_SETTINGS_NOTE_TYPES,
      permissions.DELETE_SERVICE_TEAM_SETTINGS_TIMESHEET_DECLINED_REASONS,
      permissions.DELETE_SERVICE_TEAM_SETTINGS_SHIFT_CANCELLED_REASONS,
      permissions.VIEW_SERVICE_PROVIDER_SETTINGS,
      permissions.VIEW_SERVICE_PROVIDER_SETTINGS_POSITIONS,
      permissions.VIEW_SERVICE_PROVIDER_SETTINGS_NOTE_TYPES,
      permissions.CREATE_SERVICE_PROVIDER_SETTINGS_POSITIONS,
      permissions.CREATE_SERVICE_PROVIDER_SETTINGS_NOTE_TYPES,
      permissions.MODIFY_SERVICE_PROVIDER_SETTINGS_POSITIONS,
      permissions.MODIFY_SERVICE_PROVIDER_SETTINGS_NOTE_TYPES,
      permissions.VIEW_AVAILABILITY_SETTINGS,
      permissions.VIEW_AVAILABILITY_SETTINGS_AVAILABILITY_TEMPLATES,
      permissions.VIEW_AVAILABILITY_SETTINGS_LEAVE_TYPES,
      permissions.CREATE_AVAILABILITY_SETTINGS_AVAILABILITY_TEMPLATES,
      permissions.CREATE_AVAILABILITY_SETTINGS_LEAVE_TYPES,
      permissions.MODIFY_AVAILABILITY_SETTINGS_AVAILABILITY_TEMPLATES,
      permissions.MODIFY_AVAILABILITY_SETTINGS_LEAVE_TYPES,
      permissions.DELETE_AVAILABILITY_SETTINGS_AVAILABILITY_TEMPLATES,
      permissions.DELETE_AVAILABILITY_SETTINGS_LEAVE_TYPES,
      permissions.VIEW_NOTIFICATION_SETTINGS,
      permissions.CREATE_NOTIFICATION_SETTINGS,
      permissions.MODIFY_NOTIFICATION_SETTINGS,
      permissions.DELETE_NOTIFICATION_SETTINGS,
      permissions.VIEW_INTEGRATION_SETTINGS,
      permissions.CREATE_INTEGRATION_SETTINGS,
      permissions.MODIFY_INTEGRATION_SETTINGS,
      permissions.DELETE_INTEGRATION_SETTINGS,
    ],
  },
  OWNER: {
    description: 'Access to everything including Settings. Can complete all actions.',
    permissions: [
      permissions.VIEW_UNALLOCATED_SERVICES,
      permissions.CREATE_UNALLOCATED_SERVICES,
      permissions.MODIFY_UNALLOCATED_SERVICES,
      permissions.DELETE_UNALLOCATED_SERVICES,
      permissions.RELEASE_SHIFTS,
      permissions.VIEW_USER_MANAGEMENT_SETTINGS,
      permissions.APPROVE_SCHEDULE_INTERNALLY,
      permissions.APPROVE_TIMESHEET,
      permissions.CAN_TRANSFER_OWNERSHIP,
      permissions.VIEW_HOME,
      permissions.VIEW_POWERVIEW,
      permissions.VIEW_PARTICIPANTS,
      permissions.VIEW_PARTICIPANT_CENTRE,
      permissions.VIEW_PARTICIPANT_DETAILS,
      permissions.VIEW_PARTICIPANT_CONTACTS,
      permissions.VIEW_PARTICIPANT_GOALS,
      permissions.VIEW_PARTICIPANT_PROGRESS_NOTES,
      permissions.VIEW_PARTICIPANT_GENERAL_NOTES,
      permissions.VIEW_PARTICIPANT_ALERTS,
      permissions.CREATE_PARTICIPANTS,
      permissions.CREATE_PARTICIPANT_DETAILS,
      permissions.CREATE_PARTICIPANT_CONTACTS,
      permissions.CREATE_PARTICIPANT_GOALS,
      permissions.CREATE_PARTICIPANT_PROGRESS_NOTES,
      permissions.CREATE_PARTICIPANT_GENERAL_NOTES,
      permissions.CREATE_PARTICIPANT_ALERTS,
      permissions.MODIFY_PARTICIPANT_DETAILS,
      permissions.MODIFY_PARTICIPANT_CONTACTS,
      permissions.MODIFY_PARTICIPANT_GOALS,
      permissions.MODIFY_PARTICIPANT_PROGRESS_NOTES,
      permissions.MODIFY_PARTICIPANT_GENERAL_NOTES,
      permissions.MODIFY_PARTICIPANT_ALERTS,
      permissions.DELETE_PARTICIPANTS,
      permissions.DELETE_PARTICIPANT_DETAILS,
      permissions.DELETE_PARTICIPANT_CONTACTS,
      permissions.DELETE_PARTICIPANT_GOALS,
      permissions.DELETE_PARTICIPANT_PROGRESS_NOTES,
      permissions.DELETE_PARTICIPANT_GENERAL_NOTES,
      permissions.DELETE_PARTICIPANT_ALERTS,
      permissions.VIEW_CONTACTS,
      permissions.CREATE_CONTACTS,
      permissions.MODIFY_CONTACTS,
      permissions.DELETE_CONTACTS,
      permissions.VIEW_SCHEDULING,
      permissions.VIEW_PARTICIPANT_SCHEDULE,
      permissions.CREATE_PARTICIPANT_SCHEDULE,
      permissions.MODIFY_PARTICIPANT_SCHEDULE,
      permissions.DELETE_PARTICIPANT_SCHEDULE,
      permissions.VIEW_STAFF_ROSTER,
      permissions.CREATE_STAFF_ROSTER,
      permissions.MODIFY_STAFF_ROSTER,
      permissions.DELETE_STAFF_ROSTER,
      permissions.VIEW_SHIFT_RELEASE,
      permissions.CREATE_SHIFT_RELEASE,
      permissions.MODIFY_SHIFT_RELEASE,
      permissions.DELETE_SHIFT_RELEASE,
      permissions.VIEW_SHIFT_APPROVAL,
      permissions.CREATE_SHIFT_APPROVAL,
      permissions.MODIFY_SHIFT_APPROVAL,
      permissions.DELETE_SHIFT_APPROVAL,
      permissions.VIEW_SERVICE_TEAM,
      permissions.CREATE_SERVICE_TEAM,
      permissions.MODIFY_SERVICE_TEAM,
      permissions.DELETE_SERVICE_TEAM,
      permissions.VIEW_SCHEDULING_SUPPORT,
      permissions.CREATE_SCHEDULING_SUPPORT,
      permissions.MODIFY_SCHEDULING_SUPPORT,
      permissions.DELETE_SCHEDULING_SUPPORT,
      permissions.VIEW_NOTIFICATION,
      permissions.CREATE_NOTIFICATION,
      permissions.MODIFY_NOTIFICATION,
      permissions.DELETE_NOTIFICATION,
      permissions.VIEW_SERVICE_DASHBOARD,
      permissions.CREATE_SERVICE_DASHBOARD,
      permissions.MODIFY_SERVICE_DASHBOARD,
      permissions.DELETE_SERVICE_DASHBOARD,
      permissions.VIEW_BILLING,
      permissions.VIEW_SERVICE_NDIS_CLAIMS,
      permissions.CREATE_SERVICE_NDIS_CLAIMS,
      permissions.MODIFY_SERVICE_NDIS_CLAIMS,
      permissions.DELETE_SERVICE_NDIS_CLAIMS,
      permissions.VIEW_SERVICE_INVOICE,
      permissions.CREATE_SERVICE_INVOICE,
      permissions.MODIFY_SERVICE_INVOICE,
      permissions.DELETE_SERVICE_INVOICE,
      permissions.VIEW_PAYROLL,
      permissions.CREATE_PAYROLL,
      permissions.MODIFY_PAYROLL,
      permissions.DELETE_PAYROLL,
      permissions.VIEW_RESOURCES,
      permissions.VIEW_SERVICE_PROVIDERS,
      permissions.VIEW_SERVICE_PROVIDER_DETAILS,
      permissions.VIEW_SERVICE_PROVIDER_CONTACTS,
      permissions.VIEW_SERVICE_PROVIDER_NOTES,
      permissions.CREATE_SERVICE_PROVIDERS,
      permissions.CREATE_SERVICE_PROVIDER_DETAILS,
      permissions.CREATE_SERVICE_PROVIDER_CONTACTS,
      permissions.CREATE_SERVICE_PROVIDER_NOTES,
      permissions.MODIFY_SERVICE_PROVIDERS,
      permissions.MODIFY_SERVICE_PROVIDER_DETAILS,
      permissions.MODIFY_SERVICE_PROVIDER_CONTACTS,
      permissions.MODIFY_SERVICE_PROVIDER_NOTES,
      permissions.DELETE_SERVICE_PROVIDERS,
      permissions.DELETE_SERVICE_PROVIDER_DETAILS,
      permissions.DELETE_SERVICE_PROVIDER_CONTACTS,
      permissions.DELETE_SERVICE_PROVIDER_NOTES,
      permissions.VIEW_SERVICE_REGION,
      permissions.CREATE_SERVICE_REGION,
      permissions.MODIFY_SERVICE_REGION,
      permissions.DELETE_SERVICE_REGION,
      permissions.VIEW_PRODUCTS_SERVICES,
      permissions.VIEW_DOWNLOADS,
      permissions.CREATE_DOWNLOADS,
      permissions.MODIFY_DOWNLOADS,
      permissions.DELETE_DOWNLOADS,
      permissions.VIEW_NOTIFICATION_ALERTS,
      permissions.CREATE_NOTIFICATION_ALERTS,
      permissions.MODIFY_NOTIFICATION_ALERTS,
      permissions.DELETE_NOTIFICATION_ALERTS,
      permissions.VIEW_HELP_ARTICLES,
      permissions.CREATE_HELP_ARTICLES,
      permissions.MODIFY_HELP_ARTICLES,
      permissions.DELETE_HELP_ARTICLES,
      permissions.VIEW_SUPPORT_REQUESTS,
      permissions.CREATE_SUPPORT_REQUESTS,
      permissions.MODIFY_SUPPORT_REQUESTS,
      permissions.DELETE_SUPPORT_REQUESTS,
      permissions.VIEW_ORGANISATION_DETAILS,
      permissions.VIEW_SUBSCRIPTION_DETAILS,
      permissions.VIEW_PAYMENT_HISTORY,
      permissions.MODIFY_ORGANISATION_DETAILS,
      permissions.MODIFY_SUBSCRIPTION_DETAILS,
      permissions.VIEW_USER_MANAGEMENT_SETTINGS_USER_PROFILES,
      permissions.CREATE_USER_MANAGEMENT_SETTINGS_USER_PROFILES,
      permissions.MODIFY_USER_MANAGEMENT_SETTINGS_USER_PROFILES,
      permissions.DELETE_USER_MANAGEMENT_SETTINGS_USER_PROFILES,
      permissions.VIEW_USER_MANAGEMENT_SETTINGS_USER_POSITIONS,
      permissions.CREATE_USER_MANAGEMENT_SETTINGS_USER_POSITIONS,
      permissions.MODIFY_USER_MANAGEMENT_SETTINGS_USER_POSITIONS,
      permissions.DELETE_USER_MANAGEMENT_SETTINGS_USER_POSITIONS,
      permissions.VIEW_USER_MANAGEMENT_SETTINGS_USER_ROLES,
      permissions.VIEW_USER_MANAGEMENT_SETTINGS_CUSTOM_USER_ROLES,
      permissions.CREATE_USER_MANAGEMENT_SETTINGS_CUSTOM_USER_ROLES,
      permissions.MODIFY_USER_MANAGEMENT_SETTINGS_CUSTOM_USER_ROLES,
      permissions.DELETE_USER_MANAGEMENT_SETTINGS_CUSTOM_USER_ROLES,
      permissions.VIEW_PARTICIPANT_SETTINGS,
      permissions.VIEW_PARTICIPANT_SETTINGS_STATUS,
      permissions.VIEW_PARTICIPANT_SETTINGS_GENERAL_NOTE_TYPES,
      permissions.VIEW_PARTICIPANT_SETTINGS_RELATIONSHIP_TYPES,
      permissions.VIEW_PARTICIPANT_SETTINGS_PRIMARY_DISABILITIES,
      permissions.VIEW_PARTICIPANT_SETTINGS_PROGRESS_NOTE_TYPES,
      permissions.VIEW_PARTICIPANT_SETTINGS_EXIT_REASON,
      permissions.CREATE_PARTICIPANT_SETTINGS_STATUS,
      permissions.CREATE_PARTICIPANT_SETTINGS_GENERAL_NOTE_TYPES,
      permissions.CREATE_PARTICIPANT_SETTINGS_RELATIONSHIP_TYPES,
      permissions.CREATE_PARTICIPANT_SETTINGS_PRIMARY_DISABILITIES,
      permissions.CREATE_PARTICIPANT_SETTINGS_PROGRESS_NOTE_TYPES,
      permissions.CREATE_PARTICIPANT_SETTINGS_EXIT_REASON,
      permissions.MODIFY_PARTICIPANT_SETTINGS_STATUS,
      permissions.MODIFY_PARTICIPANT_SETTINGS_GENERAL_NOTE_TYPES,
      permissions.MODIFY_PARTICIPANT_SETTINGS_RELATIONSHIP_TYPES,
      permissions.MODIFY_PARTICIPANT_SETTINGS_PRIMARY_DISABILITIES,
      permissions.MODIFY_PARTICIPANT_SETTINGS_PROGRESS_NOTE_TYPES,
      permissions.MODIFY_PARTICIPANT_SETTINGS_EXIT_REASON,
      permissions.DELETE_PARTICIPANT_SETTINGS_STATUS,
      permissions.DELETE_PARTICIPANT_SETTINGS_GENERAL_NOTE_TYPES,
      permissions.DELETE_PARTICIPANT_SETTINGS_RELATIONSHIP_TYPES,
      permissions.DELETE_PARTICIPANT_SETTINGS_PRIMARY_DISABILITIES,
      permissions.DELETE_PARTICIPANT_SETTINGS_PROGRESS_NOTE_TYPES,
      permissions.DELETE_PARTICIPANT_SETTINGS_EXIT_REASON,
      permissions.VIEW_SCHEDULE_SETTINGS,
      permissions.VIEW_SCHEDULE_SETTINGS_SERVICE_TYPES,
      permissions.VIEW_SCHEDULE_SETTINGS_CANCELLATION_CODES,
      permissions.VIEW_SCHEDULE_SETTINGS_SHIFT_LOCATIONS,
      permissions.CREATE_SCHEDULE_SETTINGS_SERVICE_TYPES,
      permissions.CREATE_SCHEDULE_SETTINGS_CANCELLATION_CODES,
      permissions.CREATE_SCHEDULE_SETTINGS_SHIFT_LOCATIONS,
      permissions.MODIFY_SCHEDULE_SETTINGS_SERVICE_TYPES,
      permissions.MODIFY_SCHEDULE_SETTINGS_CANCELLATION_CODES,
      permissions.MODIFY_SCHEDULE_SETTINGS_SHIFT_LOCATIONS,
      permissions.VIEW_SERVICE_TEAM_SETTINGS,
      permissions.VIEW_SERVICE_TEAM_SETTINGS_PAY_PERIODS,
      permissions.VIEW_SERVICE_TEAM_SETTINGS_TAGS,
      permissions.VIEW_SERVICE_TEAM_SETTINGS_NOTE_TYPES,
      permissions.VIEW_SERVICE_TEAM_SETTINGS_TIMESHEET_DECLINED_REASONS,
      permissions.VIEW_SERVICE_TEAM_SETTINGS_SHIFT_CANCELLED_REASONS,
      permissions.CREATE_SERVICE_TEAM_SETTINGS_TAGS,
      permissions.CREATE_SERVICE_TEAM_SETTINGS_NOTE_TYPES,
      permissions.CREATE_SERVICE_TEAM_SETTINGS_TIMESHEET_DECLINED_REASONS,
      permissions.CREATE_SERVICE_TEAM_SETTINGS_SHIFT_CANCELLED_REASONS,
      permissions.MODIFY_SERVICE_TEAM_SETTINGS_PAY_PERIODS,
      permissions.MODIFY_SERVICE_TEAM_SETTINGS_TAGS,
      permissions.MODIFY_SERVICE_TEAM_SETTINGS_NOTE_TYPES,
      permissions.MODIFY_SERVICE_TEAM_SETTINGS_TIMESHEET_DECLINED_REASONS,
      permissions.MODIFY_SERVICE_TEAM_SETTINGS_SHIFT_CANCELLED_REASONS,
      permissions.DELETE_SERVICE_TEAM_SETTINGS_TAGS,
      permissions.DELETE_SERVICE_TEAM_SETTINGS_NOTE_TYPES,
      permissions.DELETE_SERVICE_TEAM_SETTINGS_TIMESHEET_DECLINED_REASONS,
      permissions.DELETE_SERVICE_TEAM_SETTINGS_SHIFT_CANCELLED_REASONS,
      permissions.VIEW_SERVICE_PROVIDER_SETTINGS,
      permissions.VIEW_SERVICE_PROVIDER_SETTINGS_POSITIONS,
      permissions.VIEW_SERVICE_PROVIDER_SETTINGS_NOTE_TYPES,
      permissions.CREATE_SERVICE_PROVIDER_SETTINGS_POSITIONS,
      permissions.CREATE_SERVICE_PROVIDER_SETTINGS_NOTE_TYPES,
      permissions.MODIFY_SERVICE_PROVIDER_SETTINGS_POSITIONS,
      permissions.MODIFY_SERVICE_PROVIDER_SETTINGS_NOTE_TYPES,
      permissions.VIEW_AVAILABILITY_SETTINGS,
      permissions.VIEW_AVAILABILITY_SETTINGS_AVAILABILITY_TEMPLATES,
      permissions.VIEW_AVAILABILITY_SETTINGS_LEAVE_TYPES,
      permissions.CREATE_AVAILABILITY_SETTINGS_AVAILABILITY_TEMPLATES,
      permissions.CREATE_AVAILABILITY_SETTINGS_LEAVE_TYPES,
      permissions.MODIFY_AVAILABILITY_SETTINGS_AVAILABILITY_TEMPLATES,
      permissions.MODIFY_AVAILABILITY_SETTINGS_LEAVE_TYPES,
      permissions.DELETE_AVAILABILITY_SETTINGS_AVAILABILITY_TEMPLATES,
      permissions.DELETE_AVAILABILITY_SETTINGS_LEAVE_TYPES,
      permissions.VIEW_NOTIFICATION_SETTINGS,
      permissions.CREATE_NOTIFICATION_SETTINGS,
      permissions.MODIFY_NOTIFICATION_SETTINGS,
      permissions.DELETE_NOTIFICATION_SETTINGS,
      permissions.VIEW_INTEGRATION_SETTINGS,
      permissions.CREATE_INTEGRATION_SETTINGS,
      permissions.MODIFY_INTEGRATION_SETTINGS,
      permissions.DELETE_INTEGRATION_SETTINGS,
    ],
  },
  SCHEDULER: {
    description: 'Access to Participant Centre and Scheduling. Able to create, modify and delete. Plus view incidents and private incidents.',
    permissions: [
      permissions.VIEW_UNALLOCATED_SERVICES,
      permissions.CREATE_UNALLOCATED_SERVICES,
      permissions.MODIFY_UNALLOCATED_SERVICES,
      permissions.DELETE_UNALLOCATED_SERVICES,
      permissions.RELEASE_SHIFTS,
      permissions.APPROVE_SCHEDULE_INTERNALLY,
      permissions.APPROVE_TIMESHEET,
      // permissions.CAN_TRANSFER_OWNERSHIP,
      permissions.VIEW_HOME,
      permissions.VIEW_POWERVIEW,
      permissions.VIEW_PARTICIPANT_CENTRE,
      permissions.VIEW_PARTICIPANTS,
      permissions.VIEW_PARTICIPANT_DETAILS,
      permissions.VIEW_PARTICIPANT_CONTACTS,
      permissions.VIEW_PARTICIPANT_GOALS,
      permissions.VIEW_PARTICIPANT_PROGRESS_NOTES,
      permissions.VIEW_PARTICIPANT_GENERAL_NOTES,
      permissions.VIEW_PARTICIPANT_ALERTS,
      permissions.CREATE_PARTICIPANTS,
      permissions.CREATE_PARTICIPANT_DETAILS,
      permissions.CREATE_PARTICIPANT_CONTACTS,
      permissions.CREATE_PARTICIPANT_GOALS,
      permissions.CREATE_PARTICIPANT_PROGRESS_NOTES,
      permissions.CREATE_PARTICIPANT_GENERAL_NOTES,
      permissions.CREATE_PARTICIPANT_ALERTS,
      permissions.MODIFY_PARTICIPANT_DETAILS,
      permissions.MODIFY_PARTICIPANT_CONTACTS,
      permissions.MODIFY_PARTICIPANT_GOALS,
      permissions.MODIFY_PARTICIPANT_PROGRESS_NOTES,
      permissions.MODIFY_PARTICIPANT_GENERAL_NOTES,
      permissions.MODIFY_PARTICIPANT_ALERTS,
      permissions.DELETE_PARTICIPANTS,
      permissions.DELETE_PARTICIPANT_DETAILS,
      permissions.DELETE_PARTICIPANT_CONTACTS,
      permissions.DELETE_PARTICIPANT_GOALS,
      permissions.DELETE_PARTICIPANT_PROGRESS_NOTES,
      permissions.DELETE_PARTICIPANT_GENERAL_NOTES,
      permissions.DELETE_PARTICIPANT_ALERTS,
      permissions.VIEW_CONTACTS,
      permissions.CREATE_CONTACTS,
      permissions.MODIFY_CONTACTS,
      permissions.DELETE_CONTACTS,
      permissions.VIEW_SCHEDULING,
      permissions.VIEW_PARTICIPANT_SCHEDULE,
      permissions.CREATE_PARTICIPANT_SCHEDULE,
      permissions.MODIFY_PARTICIPANT_SCHEDULE,
      permissions.DELETE_PARTICIPANT_SCHEDULE,
      permissions.VIEW_STAFF_ROSTER,
      permissions.CREATE_STAFF_ROSTER,
      permissions.MODIFY_STAFF_ROSTER,
      permissions.DELETE_STAFF_ROSTER,
      permissions.VIEW_SHIFT_RELEASE,
      permissions.CREATE_SHIFT_RELEASE,
      permissions.MODIFY_SHIFT_RELEASE,
      permissions.DELETE_SHIFT_RELEASE,
      permissions.VIEW_SHIFT_APPROVAL,
      permissions.CREATE_SHIFT_APPROVAL,
      permissions.MODIFY_SHIFT_APPROVAL,
      permissions.DELETE_SHIFT_APPROVAL,
      permissions.VIEW_SERVICE_TEAM,
      permissions.CREATE_SERVICE_TEAM,
      permissions.MODIFY_SERVICE_TEAM,
      permissions.DELETE_SERVICE_TEAM,
      permissions.VIEW_SCHEDULING_SUPPORT,
      permissions.CREATE_SCHEDULING_SUPPORT,
      permissions.MODIFY_SCHEDULING_SUPPORT,
      permissions.DELETE_SCHEDULING_SUPPORT,
      permissions.VIEW_NOTIFICATION,
      permissions.CREATE_NOTIFICATION,
      permissions.MODIFY_NOTIFICATION,
      permissions.DELETE_NOTIFICATION,
      permissions.VIEW_SERVICE_DASHBOARD,
      permissions.CREATE_SERVICE_DASHBOARD,
      permissions.MODIFY_SERVICE_DASHBOARD,
      permissions.DELETE_SERVICE_DASHBOARD,
      // permissions.VIEW_BILLING,
      // permissions.VIEW_SERVICE_NDIS_CLAIMS,
      // permissions.CREATE_SERVICE_NDIS_CLAIMS,
      // permissions.MODIFY_SERVICE_NDIS_CLAIMS,
      // permissions.DELETE_SERVICE_NDIS_CLAIMS,
      // permissions.VIEW_SERVICE_INVOICE,
      // permissions.CREATE_SERVICE_INVOICE,
      // permissions.MODIFY_SERVICE_INVOICE,
      // permissions.DELETE_SERVICE_INVOICE,
      // permissions.VIEW_PAYROLL,
      // permissions.CREATE_PAYROLL,
      // permissions.MODIFY_PAYROLL,
      // permissions.DELETE_PAYROLL,
      permissions.VIEW_RESOURCES,
      permissions.VIEW_SERVICE_PROVIDERS,
      permissions.VIEW_SERVICE_PROVIDER_DETAILS,
      permissions.VIEW_SERVICE_PROVIDER_CONTACTS,
      permissions.VIEW_SERVICE_PROVIDER_NOTES,
      permissions.CREATE_SERVICE_PROVIDERS,
      permissions.CREATE_SERVICE_PROVIDER_DETAILS,
      permissions.CREATE_SERVICE_PROVIDER_CONTACTS,
      permissions.CREATE_SERVICE_PROVIDER_NOTES,
      permissions.MODIFY_SERVICE_PROVIDERS,
      permissions.MODIFY_SERVICE_PROVIDER_DETAILS,
      permissions.MODIFY_SERVICE_PROVIDER_CONTACTS,
      permissions.MODIFY_SERVICE_PROVIDER_NOTES,
      permissions.DELETE_SERVICE_PROVIDERS,
      permissions.DELETE_SERVICE_PROVIDER_DETAILS,
      permissions.DELETE_SERVICE_PROVIDER_CONTACTS,
      permissions.DELETE_SERVICE_PROVIDER_NOTES,
      permissions.VIEW_SERVICE_REGION,
      permissions.CREATE_SERVICE_REGION,
      permissions.MODIFY_SERVICE_REGION,
      permissions.DELETE_SERVICE_REGION,
      permissions.VIEW_PRODUCTS_SERVICES,
      permissions.VIEW_DOWNLOADS,
      permissions.CREATE_DOWNLOADS,
      permissions.MODIFY_DOWNLOADS,
      permissions.DELETE_DOWNLOADS,
      permissions.VIEW_NOTIFICATION_ALERTS,
      permissions.CREATE_NOTIFICATION_ALERTS,
      permissions.MODIFY_NOTIFICATION_ALERTS,
      permissions.DELETE_NOTIFICATION_ALERTS,
      permissions.VIEW_HELP_ARTICLES,
      permissions.CREATE_HELP_ARTICLES,
      permissions.MODIFY_HELP_ARTICLES,
      permissions.DELETE_HELP_ARTICLES,
      permissions.VIEW_SUPPORT_REQUESTS,
      permissions.CREATE_SUPPORT_REQUESTS,
      permissions.MODIFY_SUPPORT_REQUESTS,
      permissions.DELETE_SUPPORT_REQUESTS,
      // permissions.VIEW_ORGANISATION_DETAILS,
      // permissions.VIEW_SUBSCRIPTION_DETAILS,
      // permissions.VIEW_PAYMENT_HISTORY,
      // permissions.MODIFY_ORGANISATION_DETAILS,
      // permissions.MODIFY_SUBSCRIPTION_DETAILS,
      // permissions.VIEW_USER_MANAGEMENT_SETTINGS_USER_PROFILES,
      // permissions.CREATE_USER_MANAGEMENT_SETTINGS_USER_PROFILES,
      // permissions.MODIFY_USER_MANAGEMENT_SETTINGS_USER_PROFILES,
      // permissions.DELETE_USER_MANAGEMENT_SETTINGS_USER_PROFILES,
      // permissions.VIEW_USER_MANAGEMENT_SETTINGS_USER_POSITIONS,
      // permissions.CREATE_USER_MANAGEMENT_SETTINGS_USER_POSITIONS,
      // permissions.MODIFY_USER_MANAGEMENT_SETTINGS_USER_POSITIONS,
      // permissions.DELETE_USER_MANAGEMENT_SETTINGS_USER_POSITIONS,
      // permissions.VIEW_USER_MANAGEMENT_SETTINGS_USER_ROLES,
      // permissions.VIEW_USER_MANAGEMENT_SETTINGS_CUSTOM_USER_ROLES,
      // permissions.CREATE_USER_MANAGEMENT_SETTINGS_CUSTOM_USER_ROLES,
      // permissions.MODIFY_USER_MANAGEMENT_SETTINGS_CUSTOM_USER_ROLES,
      // permissions.DELETE_USER_MANAGEMENT_SETTINGS_CUSTOM_USER_ROLES,
      // permissions.VIEW_PARTICIPANT_SETTINGS,
      // permissions.VIEW_PARTICIPANT_SETTINGS_STATUS,
      // permissions.VIEW_PARTICIPANT_SETTINGS_GENERAL_NOTE_TYPES,
      // permissions.VIEW_PARTICIPANT_SETTINGS_RELATIONSHIP_TYPES,
      // permissions.VIEW_PARTICIPANT_SETTINGS_PRIMARY_DISABILITIES,
      // permissions.VIEW_PARTICIPANT_SETTINGS_PROGRESS_NOTE_TYPES,
      // permissions.VIEW_PARTICIPANT_SETTINGS_EXIT_REASON,
      // permissions.CREATE_PARTICIPANT_SETTINGS_STATUS,
      // permissions.CREATE_PARTICIPANT_SETTINGS_GENERAL_NOTE_TYPES,
      // permissions.CREATE_PARTICIPANT_SETTINGS_RELATIONSHIP_TYPES,
      // permissions.CREATE_PARTICIPANT_SETTINGS_PRIMARY_DISABILITIES,
      // permissions.CREATE_PARTICIPANT_SETTINGS_PROGRESS_NOTE_TYPES,
      // permissions.CREATE_PARTICIPANT_SETTINGS_EXIT_REASON,
      // permissions.MODIFY_PARTICIPANT_SETTINGS_STATUS,
      // permissions.MODIFY_PARTICIPANT_SETTINGS_GENERAL_NOTE_TYPES,
      // permissions.MODIFY_PARTICIPANT_SETTINGS_RELATIONSHIP_TYPES,
      // permissions.MODIFY_PARTICIPANT_SETTINGS_PRIMARY_DISABILITIES,
      // permissions.MODIFY_PARTICIPANT_SETTINGS_PROGRESS_NOTE_TYPES,
      // permissions.MODIFY_PARTICIPANT_SETTINGS_EXIT_REASON,
      // permissions.DELETE_PARTICIPANT_SETTINGS_STATUS,
      // permissions.DELETE_PARTICIPANT_SETTINGS_GENERAL_NOTE_TYPES,
      // permissions.DELETE_PARTICIPANT_SETTINGS_RELATIONSHIP_TYPES,
      // permissions.DELETE_PARTICIPANT_SETTINGS_PRIMARY_DISABILITIES,
      // permissions.DELETE_PARTICIPANT_SETTINGS_PROGRESS_NOTE_TYPES,
      // permissions.DELETE_PARTICIPANT_SETTINGS_EXIT_REASON,
      // permissions.VIEW_SCHEDULE_SETTINGS,
      // permissions.VIEW_SCHEDULE_SETTINGS_SERVICE_TYPES,
      // permissions.VIEW_SCHEDULE_SETTINGS_CANCELLATION_CODES,
      // permissions.VIEW_SCHEDULE_SETTINGS_SHIFT_LOCATIONS,
      // permissions.CREATE_SCHEDULE_SETTINGS_SERVICE_TYPES,
      // permissions.CREATE_SCHEDULE_SETTINGS_CANCELLATION_CODES,
      // permissions.CREATE_SCHEDULE_SETTINGS_SHIFT_LOCATIONS,
      // permissions.MODIFY_SCHEDULE_SETTINGS_SERVICE_TYPES,
      // permissions.MODIFY_SCHEDULE_SETTINGS_CANCELLATION_CODES,
      // permissions.MODIFY_SCHEDULE_SETTINGS_SHIFT_LOCATIONS,
      // permissions.VIEW_SERVICE_TEAM_SETTINGS,
      // permissions.VIEW_SERVICE_TEAM_SETTINGS_PAY_PERIODS,
      // permissions.VIEW_SERVICE_TEAM_SETTINGS_TAGS,
      // permissions.VIEW_SERVICE_TEAM_SETTINGS_NOTE_TYPES,
      // permissions.VIEW_SERVICE_TEAM_SETTINGS_TIMESHEET_DECLINED_REASONS,
      // permissions.VIEW_SERVICE_TEAM_SETTINGS_SHIFT_CANCELLED_REASONS,
      // permissions.CREATE_SERVICE_TEAM_SETTINGS_TAGS,
      // permissions.CREATE_SERVICE_TEAM_SETTINGS_NOTE_TYPES,
      // permissions.CREATE_SERVICE_TEAM_SETTINGS_TIMESHEET_DECLINED_REASONS,
      // permissions.CREATE_SERVICE_TEAM_SETTINGS_SHIFT_CANCELLED_REASONS,
      // permissions.MODIFY_SERVICE_TEAM_SETTINGS_PAY_PERIODS,
      // permissions.MODIFY_SERVICE_TEAM_SETTINGS_TAGS,
      // permissions.MODIFY_SERVICE_TEAM_SETTINGS_NOTE_TYPES,
      // permissions.MODIFY_SERVICE_TEAM_SETTINGS_TIMESHEET_DECLINED_REASONS,
      // permissions.MODIFY_SERVICE_TEAM_SETTINGS_SHIFT_CANCELLED_REASONS,
      // permissions.DELETE_SERVICE_TEAM_SETTINGS_TAGS,
      // permissions.DELETE_SERVICE_TEAM_SETTINGS_NOTE_TYPES,
      // permissions.DELETE_SERVICE_TEAM_SETTINGS_TIMESHEET_DECLINED_REASONS,
      // permissions.DELETE_SERVICE_TEAM_SETTINGS_SHIFT_CANCELLED_REASONS,
      // permissions.VIEW_SERVICE_PROVIDER_SETTINGS,
      // permissions.VIEW_SERVICE_PROVIDER_SETTINGS_POSITIONS,
      // permissions.VIEW_SERVICE_PROVIDER_SETTINGS_NOTE_TYPES,
      // permissions.CREATE_SERVICE_PROVIDER_SETTINGS_POSITIONS,
      // permissions.CREATE_SERVICE_PROVIDER_SETTINGS_NOTE_TYPES,
      // permissions.MODIFY_SERVICE_PROVIDER_SETTINGS_POSITIONS,
      // permissions.MODIFY_SERVICE_PROVIDER_SETTINGS_NOTE_TYPES,
      // permissions.VIEW_AVAILABILITY_SETTINGS,
      // permissions.VIEW_AVAILABILITY_SETTINGS_AVAILABILITY_TEMPLATES,
      // permissions.VIEW_AVAILABILITY_SETTINGS_LEAVE_TYPES,
      // permissions.CREATE_AVAILABILITY_SETTINGS_AVAILABILITY_TEMPLATES,
      // permissions.CREATE_AVAILABILITY_SETTINGS_LEAVE_TYPES,
      // permissions.MODIFY_AVAILABILITY_SETTINGS_AVAILABILITY_TEMPLATES,
      // permissions.MODIFY_AVAILABILITY_SETTINGS_LEAVE_TYPES,
      // permissions.DELETE_AVAILABILITY_SETTINGS_AVAILABILITY_TEMPLATES,
      // permissions.DELETE_AVAILABILITY_SETTINGS_LEAVE_TYPES,
      // permissions.VIEW_NOTIFICATION_SETTINGS,
      // permissions.CREATE_NOTIFICATION_SETTINGS,
      // permissions.MODIFY_NOTIFICATION_SETTINGS,
      // permissions.DELETE_NOTIFICATION_SETTINGS,
      // permissions.VIEW_INTEGRATION_SETTINGS,
      // permissions.CREATE_INTEGRATION_SETTINGS,
      // permissions.MODIFY_INTEGRATION_SETTINGS,
      // permissions.DELETE_INTEGRATION_SETTINGS,
    ],
  },
  FINANCE: {
    description: 'Access to Billing tab able to create, modify (approve and delete records)',
    permissions: [
      // permissions.VIEW_UNALLOCATED_SERVICES,
      // permissions.CREATE_UNALLOCATED_SERVICES,
      // permissions.MODIFY_UNALLOCATED_SERVICES,
      // permissions.DELETE_UNALLOCATED_SERVICES,
      permissions.APPROVE_SCHEDULE_INTERNALLY,
      permissions.APPROVE_TIMESHEET,
      // permissions.CAN_TRANSFER_OWNERSHIP,
      permissions.VIEW_HOME,
      // permissions.VIEW_POWERVIEW,
      // permissions.VIEW_PARTICIPANT_CENTRE,
      // permissions.VIEW_PARTICIPANTS,
      // permissions.VIEW_PARTICIPANT_DETAILS,
      // permissions.VIEW_PARTICIPANT_CONTACTS,
      // permissions.VIEW_PARTICIPANT_GOALS,
      // permissions.VIEW_PARTICIPANT_PROGRESS_NOTES,
      // permissions.VIEW_PARTICIPANT_GENERAL_NOTES,
      // permissions.VIEW_PARTICIPANT_ALERTS,
      // permissions.CREATE_PARTICIPANTS,
      // permissions.CREATE_PARTICIPANT_DETAILS,
      // permissions.CREATE_PARTICIPANT_CONTACTS,
      // permissions.CREATE_PARTICIPANT_GOALS,
      // permissions.CREATE_PARTICIPANT_PROGRESS_NOTES,
      // permissions.CREATE_PARTICIPANT_GENERAL_NOTES,
      // permissions.CREATE_PARTICIPANT_ALERTS,
      // permissions.MODIFY_PARTICIPANTS,
      // permissions.MODIFY_PARTICIPANT_DETAILS,
      // permissions.MODIFY_PARTICIPANT_CONTACTS,
      // permissions.MODIFY_PARTICIPANT_GOALS,
      // permissions.MODIFY_PARTICIPANT_PROGRESS_NOTES,
      // permissions.MODIFY_PARTICIPANT_GENERAL_NOTES,
      // permissions.MODIFY_PARTICIPANT_ALERTS,
      // permissions.DELETE_PARTICIPANTS,
      // permissions.DELETE_PARTICIPANT_DETAILS,
      // permissions.DELETE_PARTICIPANT_CONTACTS,
      // permissions.DELETE_PARTICIPANT_GOALS,
      // permissions.DELETE_PARTICIPANT_PROGRESS_NOTES,
      // permissions.DELETE_PARTICIPANT_GENERAL_NOTES,
      // permissions.DELETE_PARTICIPANT_ALERTS,
      // permissions.VIEW_CONTACTS,
      // permissions.CREATE_CONTACTS,
      // permissions.MODIFY_CONTACTS,
      // permissions.DELETE_CONTACTS,
      // permissions.VIEW_SCHEDULING,
      // permissions.VIEW_PARTICIPANT_SCHEDULE,
      // permissions.CREATE_PARTICIPANT_SCHEDULE,
      // permissions.MODIFY_PARTICIPANT_SCHEDULE,
      // permissions.DELETE_PARTICIPANT_SCHEDULE,
      // permissions.VIEW_STAFF_ROSTER,
      // permissions.CREATE_STAFF_ROSTER,
      // permissions.MODIFY_STAFF_ROSTER,
      // permissions.DELETE_STAFF_ROSTER,
      // permissions.VIEW_SHIFT_RELEASE,
      // permissions.CREATE_SHIFT_RELEASE,
      // permissions.MODIFY_SHIFT_RELEASE,
      // permissions.DELETE_SHIFT_RELEASE,
      // permissions.VIEW_SHIFT_APPROVAL,
      // permissions.CREATE_SHIFT_APPROVAL,
      // permissions.MODIFY_SHIFT_APPROVAL,
      // permissions.DELETE_SHIFT_APPROVAL,
      // permissions.VIEW_SERVICE_TEAM,
      // permissions.CREATE_SERVICE_TEAM,
      // permissions.MODIFY_SERVICE_TEAM,
      // permissions.DELETE_SERVICE_TEAM,
      // permissions.VIEW_SCHEDULING_SUPPORT,
      // permissions.CREATE_SCHEDULING_SUPPORT,
      // permissions.MODIFY_SCHEDULING_SUPPORT,
      // permissions.DELETE_SCHEDULING_SUPPORT,
      // permissions.VIEW_NOTIFICATION,
      // permissions.CREATE_NOTIFICATION,
      // permissions.MODIFY_NOTIFICATION,
      // permissions.DELETE_NOTIFICATION,
      // permissions.VIEW_SERVICE_DASHBOARD,
      // permissions.CREATE_SERVICE_DASHBOARD,
      // permissions.MODIFY_SERVICE_DASHBOARD,
      // permissions.DELETE_SERVICE_DASHBOARD,
      permissions.VIEW_BILLING,
      permissions.VIEW_SERVICE_NDIS_CLAIMS,
      permissions.CREATE_SERVICE_NDIS_CLAIMS,
      permissions.MODIFY_SERVICE_NDIS_CLAIMS,
      permissions.DELETE_SERVICE_NDIS_CLAIMS,
      permissions.VIEW_SERVICE_INVOICE,
      permissions.CREATE_SERVICE_INVOICE,
      permissions.MODIFY_SERVICE_INVOICE,
      permissions.DELETE_SERVICE_INVOICE,
      permissions.VIEW_PAYROLL,
      permissions.CREATE_PAYROLL,
      permissions.MODIFY_PAYROLL,
      permissions.DELETE_PAYROLL,
      // permissions.VIEW_RESOURCES,
      // permissions.VIEW_SERVICE_PROVIDERS,
      // permissions.VIEW_SERVICE_PROVIDER_DETAILS,
      // permissions.VIEW_SERVICE_PROVIDER_CONTACTS,
      // permissions.VIEW_SERVICE_PROVIDER_NOTES,
      // permissions.CREATE_SERVICE_PROVIDERS,
      // permissions.CREATE_SERVICE_PROVIDER_DETAILS,
      // permissions.CREATE_SERVICE_PROVIDER_CONTACTS,
      // permissions.CREATE_SERVICE_PROVIDER_NOTES,
      // permissions.MODIFY_SERVICE_PROVIDERS,
      // permissions.MODIFY_SERVICE_PROVIDER_DETAILS,
      // permissions.MODIFY_SERVICE_PROVIDER_CONTACTS,
      // permissions.MODIFY_SERVICE_PROVIDER_NOTES,
      // permissions.DELETE_SERVICE_PROVIDERS,
      // permissions.DELETE_SERVICE_PROVIDER_DETAILS,
      // permissions.DELETE_SERVICE_PROVIDER_CONTACTS,
      // permissions.DELETE_SERVICE_PROVIDER_NOTES,
      // permissions.VIEW_SERVICE_REGION,
      // permissions.CREATE_SERVICE_REGION,
      // permissions.MODIFY_SERVICE_REGION,
      // permissions.DELETE_SERVICE_REGION,
      // permissions.VIEW_PRODUCTS_SERVICES,
      permissions.VIEW_DOWNLOADS,
      permissions.CREATE_DOWNLOADS,
      permissions.MODIFY_DOWNLOADS,
      permissions.DELETE_DOWNLOADS,
      // permissions.VIEW_NOTIFICATION_ALERTS,
      // permissions.CREATE_NOTIFICATION_ALERTS,
      // permissions.MODIFY_NOTIFICATION_ALERTS,
      // permissions.DELETE_NOTIFICATION_ALERTS,
      permissions.VIEW_HELP_ARTICLES,
      permissions.CREATE_HELP_ARTICLES,
      permissions.MODIFY_HELP_ARTICLES,
      permissions.DELETE_HELP_ARTICLES,
      permissions.VIEW_SUPPORT_REQUESTS,
      permissions.CREATE_SUPPORT_REQUESTS,
      permissions.MODIFY_SUPPORT_REQUESTS,
      permissions.DELETE_SUPPORT_REQUESTS,
      // permissions.VIEW_ORGANISATION_DETAILS,
      // permissions.VIEW_SUBSCRIPTION_DETAILS,
      permissions.VIEW_PAYMENT_HISTORY,
      // permissions.MODIFY_ORGANISATION_DETAILS,
      permissions.MODIFY_SUBSCRIPTION_DETAILS,
      // permissions.VIEW_USER_MANAGEMENT_SETTINGS_USER_PROFILES,
      // permissions.CREATE_USER_MANAGEMENT_SETTINGS_USER_PROFILES,
      // permissions.MODIFY_USER_MANAGEMENT_SETTINGS_USER_PROFILES,
      // permissions.DELETE_USER_MANAGEMENT_SETTINGS_USER_PROFILES,
      // permissions.VIEW_USER_MANAGEMENT_SETTINGS_USER_POSITIONS,
      // permissions.CREATE_USER_MANAGEMENT_SETTINGS_USER_POSITIONS,
      // permissions.MODIFY_USER_MANAGEMENT_SETTINGS_USER_POSITIONS,
      // permissions.DELETE_USER_MANAGEMENT_SETTINGS_USER_POSITIONS,
      // permissions.VIEW_USER_MANAGEMENT_SETTINGS_USER_ROLES,
      // permissions.VIEW_USER_MANAGEMENT_SETTINGS_CUSTOM_USER_ROLES,
      // permissions.CREATE_USER_MANAGEMENT_SETTINGS_CUSTOM_USER_ROLES,
      // permissions.MODIFY_USER_MANAGEMENT_SETTINGS_CUSTOM_USER_ROLES,
      // permissions.DELETE_USER_MANAGEMENT_SETTINGS_CUSTOM_USER_ROLES,
      // permissions.VIEW_PARTICIPANT_SETTINGS,
      // permissions.VIEW_PARTICIPANT_SETTINGS_STATUS,
      // permissions.VIEW_PARTICIPANT_SETTINGS_GENERAL_NOTE_TYPES,
      // permissions.VIEW_PARTICIPANT_SETTINGS_RELATIONSHIP_TYPES,
      // permissions.VIEW_PARTICIPANT_SETTINGS_PRIMARY_DISABILITIES,
      // permissions.VIEW_PARTICIPANT_SETTINGS_PROGRESS_NOTE_TYPES,
      // permissions.VIEW_PARTICIPANT_SETTINGS_EXIT_REASON,
      // permissions.CREATE_PARTICIPANT_SETTINGS_STATUS,
      // permissions.CREATE_PARTICIPANT_SETTINGS_GENERAL_NOTE_TYPES,
      // permissions.CREATE_PARTICIPANT_SETTINGS_RELATIONSHIP_TYPES,
      // permissions.CREATE_PARTICIPANT_SETTINGS_PRIMARY_DISABILITIES,
      // permissions.CREATE_PARTICIPANT_SETTINGS_PROGRESS_NOTE_TYPES,
      // permissions.CREATE_PARTICIPANT_SETTINGS_EXIT_REASON,
      // permissions.MODIFY_PARTICIPANT_SETTINGS_STATUS,
      // permissions.MODIFY_PARTICIPANT_SETTINGS_GENERAL_NOTE_TYPES,
      // permissions.MODIFY_PARTICIPANT_SETTINGS_RELATIONSHIP_TYPES,
      // permissions.MODIFY_PARTICIPANT_SETTINGS_PRIMARY_DISABILITIES,
      // permissions.MODIFY_PARTICIPANT_SETTINGS_PROGRESS_NOTE_TYPES,
      // permissions.MODIFY_PARTICIPANT_SETTINGS_EXIT_REASON,
      // permissions.DELETE_PARTICIPANT_SETTINGS_STATUS,
      // permissions.DELETE_PARTICIPANT_SETTINGS_GENERAL_NOTE_TYPES,
      // permissions.DELETE_PARTICIPANT_SETTINGS_RELATIONSHIP_TYPES,
      // permissions.DELETE_PARTICIPANT_SETTINGS_PRIMARY_DISABILITIES,
      // permissions.DELETE_PARTICIPANT_SETTINGS_PROGRESS_NOTE_TYPES,
      // permissions.DELETE_PARTICIPANT_SETTINGS_EXIT_REASON,
      // permissions.VIEW_SCHEDULE_SETTINGS,
      // permissions.VIEW_SCHEDULE_SETTINGS_SERVICE_TYPES,
      // permissions.VIEW_SCHEDULE_SETTINGS_CANCELLATION_CODES,
      // permissions.VIEW_SCHEDULE_SETTINGS_SHIFT_LOCATIONS,
      // permissions.CREATE_SCHEDULE_SETTINGS_SERVICE_TYPES,
      // permissions.CREATE_SCHEDULE_SETTINGS_CANCELLATION_CODES,
      // permissions.CREATE_SCHEDULE_SETTINGS_SHIFT_LOCATIONS,
      // permissions.MODIFY_SCHEDULE_SETTINGS_SERVICE_TYPES,
      // permissions.MODIFY_SCHEDULE_SETTINGS_CANCELLATION_CODES,
      // permissions.MODIFY_SCHEDULE_SETTINGS_SHIFT_LOCATIONS,
      // permissions.VIEW_SERVICE_TEAM_SETTINGS,
      permissions.VIEW_SERVICE_TEAM_SETTINGS_PAY_PERIODS,
      // permissions.VIEW_SERVICE_TEAM_SETTINGS_TAGS,
      // permissions.VIEW_SERVICE_TEAM_SETTINGS_NOTE_TYPES,
      // permissions.VIEW_SERVICE_TEAM_SETTINGS_TIMESHEET_DECLINED_REASONS,
      // permissions.VIEW_SERVICE_TEAM_SETTINGS_SHIFT_CANCELLED_REASONS,
      // permissions.CREATE_SERVICE_TEAM_SETTINGS_TAGS,
      // permissions.CREATE_SERVICE_TEAM_SETTINGS_NOTE_TYPES,
      // permissions.CREATE_SERVICE_TEAM_SETTINGS_TIMESHEET_DECLINED_REASONS,
      // permissions.CREATE_SERVICE_TEAM_SETTINGS_SHIFT_CANCELLED_REASONS,
      permissions.MODIFY_SERVICE_TEAM_SETTINGS_PAY_PERIODS,
      // permissions.MODIFY_SERVICE_TEAM_SETTINGS_TAGS,
      // permissions.MODIFY_SERVICE_TEAM_SETTINGS_NOTE_TYPES,
      // permissions.MODIFY_SERVICE_TEAM_SETTINGS_TIMESHEET_DECLINED_REASONS,
      // permissions.MODIFY_SERVICE_TEAM_SETTINGS_SHIFT_CANCELLED_REASONS,
      // permissions.DELETE_SERVICE_TEAM_SETTINGS_TAGS,
      // permissions.DELETE_SERVICE_TEAM_SETTINGS_NOTE_TYPES,
      // permissions.DELETE_SERVICE_TEAM_SETTINGS_TIMESHEET_DECLINED_REASONS,
      // permissions.DELETE_SERVICE_TEAM_SETTINGS_SHIFT_CANCELLED_REASONS,
      // permissions.VIEW_SERVICE_PROVIDER_SETTINGS,
      // permissions.VIEW_SERVICE_PROVIDER_SETTINGS_POSITIONS,
      // permissions.VIEW_SERVICE_PROVIDER_SETTINGS_NOTE_TYPES,
      // permissions.CREATE_SERVICE_PROVIDER_SETTINGS_POSITIONS,
      // permissions.CREATE_SERVICE_PROVIDER_SETTINGS_NOTE_TYPES,
      // permissions.MODIFY_SERVICE_PROVIDER_SETTINGS_POSITIONS,
      // permissions.MODIFY_SERVICE_PROVIDER_SETTINGS_NOTE_TYPES,
      // permissions.VIEW_AVAILABILITY_SETTINGS,
      // permissions.VIEW_AVAILABILITY_SETTINGS_AVAILABILITY_TEMPLATES,
      // permissions.VIEW_AVAILABILITY_SETTINGS_LEAVE_TYPES,
      // permissions.CREATE_AVAILABILITY_SETTINGS_AVAILABILITY_TEMPLATES,
      // permissions.CREATE_AVAILABILITY_SETTINGS_LEAVE_TYPES,
      // permissions.MODIFY_AVAILABILITY_SETTINGS_AVAILABILITY_TEMPLATES,
      // permissions.MODIFY_AVAILABILITY_SETTINGS_LEAVE_TYPES,
      // permissions.DELETE_AVAILABILITY_SETTINGS_AVAILABILITY_TEMPLATES,
      // permissions.DELETE_AVAILABILITY_SETTINGS_LEAVE_TYPES,
      // permissions.VIEW_NOTIFICATION_SETTINGS,
      // permissions.CREATE_NOTIFICATION_SETTINGS,
      // permissions.MODIFY_NOTIFICATION_SETTINGS,
      // permissions.DELETE_NOTIFICATION_SETTINGS,
      // permissions.VIEW_INTEGRATION_SETTINGS,
      // permissions.CREATE_INTEGRATION_SETTINGS,
      // permissions.MODIFY_INTEGRATION_SETTINGS,
      // permissions.DELETE_INTEGRATION_SETTINGS,
    ],
  },
  INTAKE: {
    description: 'Access to Participant Centre and to create / modify records.',
    permissions: [
      // permissions.VIEW_UNALLOCATED_SERVICES,
      // permissions.CREATE_UNALLOCATED_SERVICES,
      // permissions.MODIFY_UNALLOCATED_SERVICES,
      // permissions.DELETE_UNALLOCATED_SERVICES,
      // permissions.APPROVE_SCHEDULE_INTERNALLY,
      // permissions.APPROVE_TIMESHEET,
      // permissions.CAN_TRANSFER_OWNERSHIP,
      permissions.VIEW_HOME,
      permissions.VIEW_POWERVIEW,
      permissions.VIEW_PARTICIPANT_CENTRE,
      permissions.VIEW_PARTICIPANTS,
      permissions.VIEW_PARTICIPANT_DETAILS,
      permissions.VIEW_PARTICIPANT_CONTACTS,
      permissions.VIEW_PARTICIPANT_GOALS,
      // permissions.VIEW_PARTICIPANT_PROGRESS_NOTES,
      permissions.VIEW_PARTICIPANT_GENERAL_NOTES,
      permissions.VIEW_PARTICIPANT_ALERTS,
      permissions.CREATE_PARTICIPANTS,
      permissions.CREATE_PARTICIPANT_DETAILS,
      permissions.CREATE_PARTICIPANT_CONTACTS,
      permissions.CREATE_PARTICIPANT_GOALS,
      // permissions.CREATE_PARTICIPANT_PROGRESS_NOTES,
      permissions.CREATE_PARTICIPANT_GENERAL_NOTES,
      permissions.CREATE_PARTICIPANT_ALERTS,
      permissions.MODIFY_PARTICIPANT_DETAILS,
      permissions.MODIFY_PARTICIPANT_CONTACTS,
      permissions.MODIFY_PARTICIPANT_GOALS,
      // permissions.MODIFY_PARTICIPANT_PROGRESS_NOTES,
      permissions.MODIFY_PARTICIPANT_GENERAL_NOTES,
      permissions.MODIFY_PARTICIPANT_ALERTS,
      // permissions.DELETE_PARTICIPANTS,
      // permissions.DELETE_PARTICIPANT_DETAILS,
      // permissions.DELETE_PARTICIPANT_CONTACTS,
      // permissions.DELETE_PARTICIPANT_GOALS,
      // permissions.DELETE_PARTICIPANT_PROGRESS_NOTES,
      // permissions.DELETE_PARTICIPANT_GENERAL_NOTES,
      // permissions.DELETE_PARTICIPANT_ALERTS,
      // permissions.VIEW_CONTACTS,
      // permissions.CREATE_CONTACTS,
      // permissions.MODIFY_CONTACTS,
      // permissions.DELETE_CONTACTS,
      // permissions.VIEW_SCHEDULING,
      // permissions.VIEW_PARTICIPANT_SCHEDULE,
      // permissions.CREATE_PARTICIPANT_SCHEDULE,
      // permissions.MODIFY_PARTICIPANT_SCHEDULE,
      // permissions.DELETE_PARTICIPANT_SCHEDULE,
      // permissions.VIEW_STAFF_ROSTER,
      // permissions.CREATE_STAFF_ROSTER,
      // permissions.MODIFY_STAFF_ROSTER,
      // permissions.DELETE_STAFF_ROSTER,
      // permissions.VIEW_SHIFT_RELEASE,
      // permissions.CREATE_SHIFT_RELEASE,
      // permissions.MODIFY_SHIFT_RELEASE,
      // permissions.DELETE_SHIFT_RELEASE,
      // permissions.VIEW_SHIFT_APPROVAL,
      // permissions.CREATE_SHIFT_APPROVAL,
      // permissions.MODIFY_SHIFT_APPROVAL,
      // permissions.DELETE_SHIFT_APPROVAL,
      // permissions.VIEW_SERVICE_TEAM,
      // permissions.CREATE_SERVICE_TEAM,
      // permissions.MODIFY_SERVICE_TEAM,
      // permissions.DELETE_SERVICE_TEAM,
      // permissions.VIEW_SCHEDULING_SUPPORT,
      // permissions.CREATE_SCHEDULING_SUPPORT,
      // permissions.MODIFY_SCHEDULING_SUPPORT,
      // permissions.DELETE_SCHEDULING_SUPPORT,
      // permissions.VIEW_NOTIFICATION,
      // permissions.CREATE_NOTIFICATION,
      // permissions.MODIFY_NOTIFICATION,
      // permissions.DELETE_NOTIFICATION,
      // permissions.VIEW_SERVICE_DASHBOARD,
      // permissions.CREATE_SERVICE_DASHBOARD,
      // permissions.MODIFY_SERVICE_DASHBOARD,
      // permissions.DELETE_SERVICE_DASHBOARD,
      // permissions.VIEW_BILLING,
      // permissions.VIEW_SERVICE_NDIS_CLAIMS,
      // permissions.CREATE_SERVICE_NDIS_CLAIMS,
      // permissions.MODIFY_SERVICE_NDIS_CLAIMS,
      // permissions.DELETE_SERVICE_NDIS_CLAIMS,
      // permissions.VIEW_SERVICE_INVOICE,
      // permissions.CREATE_SERVICE_INVOICE,
      // permissions.MODIFY_SERVICE_INVOICE,
      // permissions.DELETE_SERVICE_INVOICE,
      // permissions.VIEW_PAYROLL,
      // permissions.CREATE_PAYROLL,
      // permissions.MODIFY_PAYROLL,
      // permissions.DELETE_PAYROLL,
      permissions.VIEW_RESOURCES,
      permissions.VIEW_SERVICE_PROVIDERS,
      permissions.VIEW_SERVICE_PROVIDER_DETAILS,
      permissions.VIEW_SERVICE_PROVIDER_CONTACTS,
      permissions.VIEW_SERVICE_PROVIDER_NOTES,
      permissions.CREATE_SERVICE_PROVIDERS,
      permissions.CREATE_SERVICE_PROVIDER_DETAILS,
      permissions.CREATE_SERVICE_PROVIDER_CONTACTS,
      permissions.CREATE_SERVICE_PROVIDER_NOTES,
      permissions.MODIFY_SERVICE_PROVIDERS,
      permissions.MODIFY_SERVICE_PROVIDER_DETAILS,
      permissions.MODIFY_SERVICE_PROVIDER_CONTACTS,
      permissions.MODIFY_SERVICE_PROVIDER_NOTES,
      permissions.DELETE_SERVICE_PROVIDERS,
      permissions.DELETE_SERVICE_PROVIDER_DETAILS,
      permissions.DELETE_SERVICE_PROVIDER_CONTACTS,
      permissions.DELETE_SERVICE_PROVIDER_NOTES,
      permissions.VIEW_SERVICE_REGION,
      permissions.CREATE_SERVICE_REGION,
      permissions.MODIFY_SERVICE_REGION,
      permissions.DELETE_SERVICE_REGION,
      permissions.VIEW_PRODUCTS_SERVICES,
      // permissions.VIEW_DOWNLOADS,
      // permissions.CREATE_DOWNLOADS,
      // permissions.MODIFY_DOWNLOADS,
      // permissions.DELETE_DOWNLOADS,
      // permissions.VIEW_NOTIFICATION_ALERTS,
      // permissions.CREATE_NOTIFICATION_ALERTS,
      // permissions.MODIFY_NOTIFICATION_ALERTS,
      // permissions.DELETE_NOTIFICATION_ALERTS,
      // permissions.VIEW_HELP_ARTICLES,
      // permissions.CREATE_HELP_ARTICLES,
      // permissions.MODIFY_HELP_ARTICLES,
      // permissions.DELETE_HELP_ARTICLES,
      // permissions.VIEW_SUPPORT_REQUESTS,
      // permissions.CREATE_SUPPORT_REQUESTS,
      // permissions.MODIFY_SUPPORT_REQUESTS,
      // permissions.DELETE_SUPPORT_REQUESTS,
      // permissions.VIEW_ORGANISATION_DETAILS,
      // permissions.VIEW_SUBSCRIPTION_DETAILS,
      // permissions.VIEW_PAYMENT_HISTORY,
      // permissions.MODIFY_ORGANISATION_DETAILS,
      // permissions.MODIFY_SUBSCRIPTION_DETAILS,
      // permissions.VIEW_USER_MANAGEMENT_SETTINGS_USER_PROFILES,
      // permissions.CREATE_USER_MANAGEMENT_SETTINGS_USER_PROFILES,
      // permissions.MODIFY_USER_MANAGEMENT_SETTINGS_USER_PROFILES,
      // permissions.DELETE_USER_MANAGEMENT_SETTINGS_USER_PROFILES,
      // permissions.VIEW_USER_MANAGEMENT_SETTINGS_USER_POSITIONS,
      // permissions.CREATE_USER_MANAGEMENT_SETTINGS_USER_POSITIONS,
      // permissions.MODIFY_USER_MANAGEMENT_SETTINGS_USER_POSITIONS,
      // permissions.DELETE_USER_MANAGEMENT_SETTINGS_USER_POSITIONS,
      // permissions.VIEW_USER_MANAGEMENT_SETTINGS_USER_ROLES,
      // permissions.VIEW_USER_MANAGEMENT_SETTINGS_CUSTOM_USER_ROLES,
      // permissions.CREATE_USER_MANAGEMENT_SETTINGS_CUSTOM_USER_ROLES,
      // permissions.MODIFY_USER_MANAGEMENT_SETTINGS_CUSTOM_USER_ROLES,
      // permissions.DELETE_USER_MANAGEMENT_SETTINGS_CUSTOM_USER_ROLES,
      // permissions.VIEW_PARTICIPANT_SETTINGS,
      // permissions.VIEW_PARTICIPANT_SETTINGS_STATUS,
      // permissions.VIEW_PARTICIPANT_SETTINGS_GENERAL_NOTE_TYPES,
      // permissions.VIEW_PARTICIPANT_SETTINGS_RELATIONSHIP_TYPES,
      // permissions.VIEW_PARTICIPANT_SETTINGS_PRIMARY_DISABILITIES,
      // permissions.VIEW_PARTICIPANT_SETTINGS_PROGRESS_NOTE_TYPES,
      // permissions.VIEW_PARTICIPANT_SETTINGS_EXIT_REASON,
      // permissions.CREATE_PARTICIPANT_SETTINGS_STATUS,
      // permissions.CREATE_PARTICIPANT_SETTINGS_GENERAL_NOTE_TYPES,
      // permissions.CREATE_PARTICIPANT_SETTINGS_RELATIONSHIP_TYPES,
      // permissions.CREATE_PARTICIPANT_SETTINGS_PRIMARY_DISABILITIES,
      // permissions.CREATE_PARTICIPANT_SETTINGS_PROGRESS_NOTE_TYPES,
      // permissions.CREATE_PARTICIPANT_SETTINGS_EXIT_REASON,
      // permissions.MODIFY_PARTICIPANT_SETTINGS_STATUS,
      // permissions.MODIFY_PARTICIPANT_SETTINGS_GENERAL_NOTE_TYPES,
      // permissions.MODIFY_PARTICIPANT_SETTINGS_RELATIONSHIP_TYPES,
      // permissions.MODIFY_PARTICIPANT_SETTINGS_PRIMARY_DISABILITIES,
      // permissions.MODIFY_PARTICIPANT_SETTINGS_PROGRESS_NOTE_TYPES,
      // permissions.MODIFY_PARTICIPANT_SETTINGS_EXIT_REASON,
      // permissions.DELETE_PARTICIPANT_SETTINGS_STATUS,
      // permissions.DELETE_PARTICIPANT_SETTINGS_GENERAL_NOTE_TYPES,
      // permissions.DELETE_PARTICIPANT_SETTINGS_RELATIONSHIP_TYPES,
      // permissions.DELETE_PARTICIPANT_SETTINGS_PRIMARY_DISABILITIES,
      // permissions.DELETE_PARTICIPANT_SETTINGS_PROGRESS_NOTE_TYPES,
      // permissions.DELETE_PARTICIPANT_SETTINGS_EXIT_REASON,
      // permissions.VIEW_SCHEDULE_SETTINGS,
      // permissions.VIEW_SCHEDULE_SETTINGS_SERVICE_TYPES,
      // permissions.VIEW_SCHEDULE_SETTINGS_CANCELLATION_CODES,
      // permissions.VIEW_SCHEDULE_SETTINGS_SHIFT_LOCATIONS,
      // permissions.CREATE_SCHEDULE_SETTINGS_SERVICE_TYPES,
      // permissions.CREATE_SCHEDULE_SETTINGS_CANCELLATION_CODES,
      // permissions.CREATE_SCHEDULE_SETTINGS_SHIFT_LOCATIONS,
      // permissions.MODIFY_SCHEDULE_SETTINGS_SERVICE_TYPES,
      // permissions.MODIFY_SCHEDULE_SETTINGS_CANCELLATION_CODES,
      // permissions.MODIFY_SCHEDULE_SETTINGS_SHIFT_LOCATIONS,
      // permissions.VIEW_SERVICE_TEAM_SETTINGS,
      // permissions.VIEW_SERVICE_TEAM_SETTINGS_PAY_PERIODS,
      // permissions.VIEW_SERVICE_TEAM_SETTINGS_TAGS,
      // permissions.VIEW_SERVICE_TEAM_SETTINGS_NOTE_TYPES,
      // permissions.VIEW_SERVICE_TEAM_SETTINGS_TIMESHEET_DECLINED_REASONS,
      // permissions.VIEW_SERVICE_TEAM_SETTINGS_SHIFT_CANCELLED_REASONS,
      // permissions.CREATE_SERVICE_TEAM_SETTINGS_TAGS,
      // permissions.CREATE_SERVICE_TEAM_SETTINGS_NOTE_TYPES,
      // permissions.CREATE_SERVICE_TEAM_SETTINGS_TIMESHEET_DECLINED_REASONS,
      // permissions.CREATE_SERVICE_TEAM_SETTINGS_SHIFT_CANCELLED_REASONS,
      // permissions.MODIFY_SERVICE_TEAM_SETTINGS_PAY_PERIODS,
      // permissions.MODIFY_SERVICE_TEAM_SETTINGS_TAGS,
      // permissions.MODIFY_SERVICE_TEAM_SETTINGS_NOTE_TYPES,
      // permissions.MODIFY_SERVICE_TEAM_SETTINGS_TIMESHEET_DECLINED_REASONS,
      // permissions.MODIFY_SERVICE_TEAM_SETTINGS_SHIFT_CANCELLED_REASONS,
      // permissions.DELETE_SERVICE_TEAM_SETTINGS_TAGS,
      // permissions.DELETE_SERVICE_TEAM_SETTINGS_NOTE_TYPES,
      // permissions.DELETE_SERVICE_TEAM_SETTINGS_TIMESHEET_DECLINED_REASONS,
      // permissions.DELETE_SERVICE_TEAM_SETTINGS_SHIFT_CANCELLED_REASONS,
      // permissions.VIEW_SERVICE_PROVIDER_SETTINGS,
      // permissions.VIEW_SERVICE_PROVIDER_SETTINGS_POSITIONS,
      // permissions.VIEW_SERVICE_PROVIDER_SETTINGS_NOTE_TYPES,
      // permissions.CREATE_SERVICE_PROVIDER_SETTINGS_POSITIONS,
      // permissions.CREATE_SERVICE_PROVIDER_SETTINGS_NOTE_TYPES,
      // permissions.MODIFY_SERVICE_PROVIDER_SETTINGS_POSITIONS,
      // permissions.MODIFY_SERVICE_PROVIDER_SETTINGS_NOTE_TYPES,
      // permissions.VIEW_AVAILABILITY_SETTINGS,
      // permissions.VIEW_AVAILABILITY_SETTINGS_AVAILABILITY_TEMPLATES,
      // permissions.VIEW_AVAILABILITY_SETTINGS_LEAVE_TYPES,
      // permissions.CREATE_AVAILABILITY_SETTINGS_AVAILABILITY_TEMPLATES,
      // permissions.CREATE_AVAILABILITY_SETTINGS_LEAVE_TYPES,
      // permissions.MODIFY_AVAILABILITY_SETTINGS_AVAILABILITY_TEMPLATES,
      // permissions.MODIFY_AVAILABILITY_SETTINGS_LEAVE_TYPES,
      // permissions.DELETE_AVAILABILITY_SETTINGS_AVAILABILITY_TEMPLATES,
      // permissions.DELETE_AVAILABILITY_SETTINGS_LEAVE_TYPES,
      // permissions.VIEW_NOTIFICATION_SETTINGS,
      // permissions.CREATE_NOTIFICATION_SETTINGS,
      // permissions.MODIFY_NOTIFICATION_SETTINGS,
      // permissions.DELETE_NOTIFICATION_SETTINGS,
      // permissions.VIEW_INTEGRATION_SETTINGS,
      // permissions.CREATE_INTEGRATION_SETTINGS,
      // permissions.MODIFY_INTEGRATION_SETTINGS,
      // permissions.DELETE_INTEGRATION_SETTINGS,
    ],
  },
};