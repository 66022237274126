import React, { useCallback, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import styled from 'styled-components';
import DataTable from 'components/common/DataTable';
import SearchInput from 'components/common/InputFields/SearchInput';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { closeModalAtom } from 'atoms/modalAtom';
import { Controller, useForm } from 'react-hook-form';
import TextInput from 'components/common/InputFields/TextInput';
import variables from 'styles/variables';
import IsActiveDropdown from 'components/common/IsActiveDropdown';
import { RelationshipType } from 'types/dbSchema/participantSettings';
import SelectInput from 'components/common/InputFields/SelectInput';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Data, PaginationModel } from 'components/common/DataTable/types';
import useUserOrganisations from 'hooks/useUserOrganisations';
import useInvalidateData from 'hooks/useInvalidateData';
import { createRelationshipType, listRelationShipTypes, updateRelationshipType } from 'api/organisations/settings/participants/relationship-types';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import useErrorMessage from 'hooks/useErrorMessage';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';

// This file handle all of the relationship types for the participant settings
// If it is too long, we can abstract the individual components into their own files
const RelationshipTypes = () => {
  const [searchValue, setSearchValue] = useState('');
  const [, setModalState] = useAtom(modalStateAtom);
  const [paginationModel, setPaginationModel] = useState<PaginationModel>({ page: 0, pageSize: 20 });
  const [organisations] = useUserOrganisations();
  useInvalidateData('relationship-types');

  // UseQuery hook to fetch the data, handle caching, handle loading states, pagination, and error states
  const { isError, data, isPending, isFetching, isLoading } = useQuery({
    queryKey: ['relationship-types', paginationModel],
    queryFn: () => listRelationShipTypes(organisations[0].organisation.globalId, paginationModel.page + 1, paginationModel.pageSize),
    placeholderData: keepPreviousData,
    enabled: organisations.length > 0,
    refetchOnWindowFocus: false,
    staleTime: cachingInvalidation.relationshipTypes,
  });

  // Function to open/close the modal.
  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: RelationshipTypesForm,
      props: {
        dataTableName: 'relationship-types',
        type: 'create',
      }
    }));
  }, [setModalState]);

  return (
    <StyledBox>
      <SearchBox>
        <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} placeholderText='Search' />
        <Button variant='contained' sx={{ padding: '8px 16px' }} onClick={handleModalOpen} disabled>
          <AddOutlinedIcon sx={{ marginRight: '8px' }} />
          Add
        </Button>
      </SearchBox>
      {/* The box height is set to 100vh - the height of the SearchBox, tabs, and Nav. This is needed because the MUI datatable needs a fixed height */}
      <Box height='calc(100vh - 295px)'>
        <DataTable
          data={data as Data}
          columns={columns}
          checkboxSelection={false}
          dataTableName='relationship-types'
          isPending={isPending || isFetching || isLoading}
          isError={isError}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          title='Relationship Types'
          paginationMode='server'
          onNoData={handleModalOpen}
        />
      </Box>
    </StyledBox >
  );
};

// Add/Edit form
// For MVP 1, we are not implementing the edit functionality
// But I have created the form for future use
const RelationshipTypesForm = ({ type, relationshipType }: { type: 'create' | 'edit'; relationshipType: RelationshipType }) => {
  const [, closeModal] = useAtom(closeModalAtom);
  const [organisations] = useUserOrganisations();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const showError = useErrorMessage();
  const { control, handleSubmit, formState: { errors, isDirty } } = useForm({
    mode: 'onChange',
    defaultValues: relationshipType || {
      isActive: true // Default value for isActive is required for the IsActiveDropdown component
    }
  });

  // Function to create a new Relationship Type
  // Invalidates the query to fetch the latest data, and closes the modal
  const handleCreate = async (data: RelationshipType) => {
    try {
      await createRelationshipType(organisations[0].organisation.globalId, data);
      setInvalidateData((prev) => ({ ...prev, 'relationship-types': true }));
      closeModal();
    } catch (error) {
      showError((error as Error).message);
    }
  };

  // Function to update a Relationship Type.
  // Invalidates the query to fetch the latest data, and closes the modal
  const handleEdit = async (data: RelationshipType) => {
    try {
      await updateRelationshipType(organisations[0].organisation.globalId, data);
      setInvalidateData((prev) => ({ ...prev, 'relationship-types': true }));
      closeModal();
    } catch (error) {
      showError((error as Error).message);
    }
  };

  // Function to handle form submission
  const handleSubmitForm = async (data: RelationshipType) => {
    if (type === 'create') {
      await handleCreate(data);
    }
    else {
      await handleEdit(data);
    }
  };

  return (
    <form style={{ width: '500px' }} onSubmit={handleSubmit(handleSubmitForm)}>
      <Stack height='fit-content' padding='32px' gap='24px'>
        <Typography variant='h6' fontWeight='600'>
          {type === 'create' ? 'Add Relationship Type' : 'Edit Relationship Type'}
        </Typography>
        <Controller
          name="name"
          control={control}
          rules={{
            required: 'Name is required',
            maxLength: { value: 40, message: 'Name should not exceed 40 characters' },
            pattern: {
              value: /^[a-zA-Z0-9!@#$%^&*()_+-=[\]{};':"|,.<>/? ]*$/,
              message: 'Name must be alphanumeric'
            },
          }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='name'
              label='Name'
              placeholder='Enter Relationship Type Name'
              error={errors.name ? true : false}
              errorText={errors?.name?.message}
              isMandatory
            />
          }
        />
        <Controller
          name="systemStatus"
          control={control}
          rules={{ required: 'System Status is required' }}
          render={({ field }) =>
            <SelectInput
              {...field}
              id='systemStatus'
              label='System Status'
              error={errors.systemStatus ? true : false}
              errorText={errors?.systemStatus?.message}
              placeholder='Select System Status'
              isMandatory
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]}
            />
          }
        />
        <Stack flexDirection='row' justifyContent='flex-start' alignItems='center' gap='16px'>
          <Typography variant='subtitle2' fontWeight='500'>
            Status
          </Typography>
          <Controller
            name="isActive"
            control={control}
            rules={{
              validate: value => value !== undefined && value !== null || 'Status is required'
            }}
            render={({ field }) =>
              <IsActiveDropdown
                isActive={(field.value === undefined || field.value === null) ? true : field.value}
                setIsActive={field.onChange}
              />
            }
          />
        </Stack>
      </Stack>
      <Stack flexDirection='row' gap={2} width='100%' justifyContent='flex-end' maxWidth='960px' padding='16px 32px' boxSizing='border-box' sx={{ backgroundColor: variables.colors.lightNeutral.subtle, borderRadius: '0 0 12px 12px' }} >
        <Button variant='outlined' color='primary' onClick={closeModal}>
          Cancel
        </Button>
        <Button type='submit' variant='contained' color='primary' disabled={!isDirty}>
          {type === 'create' ? 'Create' : 'Update'}
        </Button>
      </Stack>
    </form>
  );
};

// Renders Edit Icon for Grid column definitions - opens add/edit form in modal
// Not used for MVP 1
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Edit = ({ relationshipType }: { relationshipType: RelationshipType }) => {
  const [, setModalState] = useAtom(modalStateAtom);

  if (!relationshipType || !relationshipType.isEditable) return null;

  // Function to open/close the modal.
  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: RelationshipTypesForm,
      props: {
        dataTableName: 'relationship-types',
        type: 'edit',
        relationshipType: relationshipType,
      }
    }));
  }, [setModalState, relationshipType]);

  return (
    <Stack justifyContent='center' alignItems='center' sx={{ cursor: 'pointer' }} onClick={handleModalOpen}>
      <EditOutlinedIcon sx={{ color: '#9CA3AF' }} />
    </Stack>
  );
};

// Handles the status dropdown in the DataTable
// For MVP 1, we are not implementing the edit functionality, so the dropdown is disabled
// I have created the component for future use - just remove this comment and the disabled prop
const EditStatus = ({ relationshipType }: { relationshipType: RelationshipType }) => {
  const [organisations] = useUserOrganisations();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  useInvalidateData('relationship-types');
  const showError = useErrorMessage();

  const handleEdit = async (status: boolean) => {
    try {
      await updateRelationshipType(organisations[0].organisation.globalId, { ...relationshipType, isActive: status });
      setInvalidateData((prev) => ({ ...prev, 'relationship-types': true }));
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <IsActiveDropdown
      isActive={relationshipType.isActive}
      setIsActive={handleEdit}
      // disabled={!relationshipType.isEditable} // For MVP 1, the dropdown is disabled
      disabled
    />
  );
};


const StyledBox = styled(Box)`
  height: 100%;
  width: 100%;
  background-color: white;
`;

const SearchBox = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 24px;
  gap: 16px;
`;

// Grid column definitions for DataTable
const columns: GridColDef[] = [
  // For MVP 1, we are not implementing the edit functionality
  // {
  //   field: 'edit',
  //   headerName: '',
  //   width: 50,
  //   renderCell: (params) => (
  //     <Edit relationshipType={params.row} />
  //   ),
  //   sortable: false,
  // },
  {
    field: 'name',
    headerName: 'Relationship Type Name',
    width: 250,
    renderCell: (params) => (
      <Typography variant='subtitle2'>
        {params.row.name}
      </Typography>
    ),
  },
  {
    field: 'systemStatus',
    headerName: 'System Status',
    flex: 1,
    renderCell: (params) => (
      <Typography variant='body2' color={variables.colors.text.secondary} textTransform='capitalize'>
        {params.row?.systemStatus && params.row.systemStatus.toString()}
      </Typography>
    ),
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    renderCell: (params) => (
      <EditStatus relationshipType={params.row} />
    ),
  },
];


export default RelationshipTypes;