import { TCurrentDataPointAtom } from 'atoms/types';
import { atom } from 'jotai';

// This atom is used to store the current data point that is being viewed/edited.
// We do this to save on API calls and to make the app more responsive.
// i.e. instead of clicking on a data point and making an API call to get the data point, we can just use this atom.
// If the data point is not in the atom, the types is incorrect, or it is out of sync (shouldn't be but as a safeguard), we can make an API call to get it.
// by using the relevant hook for data syncing.
export const currentDataPointAtom = atom<TCurrentDataPointAtom>({
  type: '',
  object: null,
});

export const triggerRefreshCurrentDataPointAtom = atom(false);