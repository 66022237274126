import React, { FC } from 'react';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useAtom } from 'jotai';
import { currentDataPointAtom } from 'atoms';
import variables from 'styles/variables';
import { ServiceProviderContact } from 'types/dbSchema/serviceProviders';

export interface ViewIconProps {
  serviceProviderContact: ServiceProviderContact;
}

const ViewIcon: FC<ViewIconProps> = ({ serviceProviderContact }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [, setCurrentDataPoint] = useAtom(currentDataPointAtom);

  const handleViewContact = () => {
    setCurrentDataPoint({
      object: serviceProviderContact,
      type: 'service-provider-contacts',
    });
    navigate(`/resources/service-providers/view/${id}/contact/${serviceProviderContact.id}`);
  };

  return (
    <StyledBox onClick={handleViewContact}>
      <RemoveRedEyeOutlinedIcon sx={{ color: variables.colors.icon.standard }} />
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

export default ViewIcon;