import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import ViewIcon from './ViewIcon';
import variables from 'styles/variables';
import { Typography } from '@mui/material';
import DownloadAttachmentsIcon from 'components/common/DownloadAttachmentsIcon';

export const Columns: GridColDef[] = [
  {
    field: 'view',
    headerName: '',
    width: 50,
    sortable: false,
    renderCell: (params) => (
      <ViewIcon serviceProviderNote={params.row} />
    ),
  },
  {
    field: 'download',
    headerName: '',
    width: 100,
    sortable: false,
    renderCell: (params) => (
      <DownloadAttachmentsIcon attachments={params.row.attachments} />
    ),
  },
  {
    field: 'noteType',
    headerName: 'Note Type',
    width: 150,
    sortable: true,
  },
  {
    field: 'note',
    headerName: 'Note',
    flex: 1,
    sortable: true,
    renderCell: (params) => (
      <Typography variant='body2' color={variables.colors.text.secondary} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
        {params.value}
      </Typography>
    ),
  },
  {
    field: 'createdBy',
    headerName: 'Created By',
    width: 150,
    sortable: true,
    renderCell: (params) => (
      <Typography variant='body2' color={variables.colors.text.secondary}>
        {params.value}
      </Typography>
    ),
  },
  {
    field: 'createdDate',
    headerName: 'Created Date',
    width: 150,
    sortable: true,
    renderCell: (params) => (
      <Typography variant='body2' color={variables.colors.text.secondary}>
        {params.value?.format('DD/MM/YYYY')}
      </Typography>
    ),
  },
  {
    field: 'lastModified',
    headerName: 'Last Modified',
    width: 150,
    sortable: true,
    renderCell: (params) => (
      <Typography variant='body2' color={variables.colors.text.secondary}>
        {params.value?.format('DD/MM/YYYY')}
      </Typography>
    ),
  },
];