import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import IsActiveDropdown from 'components/common/IsActiveDropdown';
import ViewIcon from './ViewIcon';
import { Stack, Typography } from '@mui/material';
import variables from 'styles/variables';

export const Columns: GridColDef[] = [
  {
    field: 'view',
    headerName: '',
    width: 50,
    renderCell: (params) => (
      <ViewIcon serviceProvider={params.row} />
    ),
    sortable: false,
  },
  {
    field: 'companyName',
    headerName: 'Company Name',
    width: 300,
    renderCell: (params) => (
      <Stack>
        <Typography variant='subtitle2'>{params.row.companyName}</Typography>
        <Typography variant='body2' color={variables.colors.text.secondary} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          {params.row.email}
        </Typography>
      </Stack>
    ),
    sortable: false,
  },
  {
    field: 'abn',
    headerName: 'ABN',

    minWidth: 200,
    renderCell: (params) => (
      <Typography variant='body2'>{params.row.abn}</Typography>
    ),
    sortable: false,
  },
  {
    field: 'mobileNumber',
    headerName: 'Mobile Number',

    minWidth: 200,
    renderCell: (params) => (
      <Typography variant='body2'>{params.row.mobileNumber}</Typography>
    ),
    sortable: false,
  },
  {
    field: 'address',
    headerName: 'Address',
    flex: 1,
    minWidth: 250,
    renderCell: (params) => (
      <Stack>
        <Typography variant='body2' sx={{ whiteSpace: 'wrap' }}>
          {params.row.address.streetAddress}, {params.row.address.suburb}, {params.row.address.state}, {params.row.address.country}, {params.row.address.postCode}
        </Typography>
      </Stack>
    ),
    sortable: false,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    renderCell: (params) => (
      <IsActiveDropdown
        isActive={params.row.isActive}
        setIsActive={() => { console.log('setIsActive'); }} // TODO: When APIs are ready, replace this with actual API call
      />
    ),
  },
];