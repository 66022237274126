import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAtom } from 'jotai';
import { currentDataPointAtom } from 'atoms';
import useUserOrganisations from '../useUserOrganisations';
import { AvailabilityTemplate } from 'types/dbSchema/availabilitySettings';
import { dummyAvailabilitiesTemplates } from 'utils/helpers/getDBData';

// This hook is used to sync user profile data with the current data point atom
export function useTemplateDataSync() {
  const { id } = useParams();
  const [currentDataPoint, setCurrentDataPoint] = useAtom(currentDataPointAtom);
  const [organisations] = useUserOrganisations();

  useEffect(() => {
    if (!organisations.length) return;
    const getTemplate = async (id: string) => {
      // Replace this URL with the actual endpoint to fetch user profile data
      const data = dummyAvailabilitiesTemplates.find((template) => template.id === id);
      setCurrentDataPoint({
        object: data as AvailabilityTemplate,
        type: 'availability-template',
      });
    };

    if (id) {
      if (currentDataPoint?.object?.id !== id) {
        getTemplate(id);
      }
    }
  }, [id, currentDataPoint, setCurrentDataPoint, organisations.length]);

  // Check if the data is still loading
  const isLoading = !currentDataPoint.object || currentDataPoint.object?.id !== id;

  return { isLoading, currentDataPoint };
}