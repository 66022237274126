import React, { FC, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import { GeneralNote } from 'types/dbSchema/generalNotes';
import { closeModalAtom } from 'atoms/modalAtom';
import { useAtom } from 'jotai';
import DragAndDropFileUpload from 'components/common/InputFields/DragAndDropFileUpload';
import { UploadedFile } from 'components/common/InputFields/DragAndDropFileUpload/types';
import TitleWithClose from 'components/common/TitleWithClose';
import { Controller, useForm } from 'react-hook-form';
import TextInput from 'components/common/InputFields/TextInput';
import SelectInput from 'components/common/InputFields/SelectInput';
import TextareaInput from 'components/common/InputFields/TextareaInput';
import variables from 'styles/variables';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from 'components/common/Tooltip';
import { SubmittedState } from 'components/common/InputFields/DragAndDropFileUpload/FileStates';
import { ServiceProviderNote } from 'types/dbSchema/serviceProviderNotes';

export interface ServiceProviderNoteProps {
  type: 'create' | 'edit';
  generalNote: ServiceProviderNote | null;
}

const ServiceProviderNoteForm: FC<ServiceProviderNoteProps> = ({ type, generalNote }) => {
  const [currentFiles, setCurrentFiles] = useState<UploadedFile[]>([]);
  const validFileTypes = ['text/csv', 'application/pdf', 'png', 'jpeg', 'jpg'];
  const [, closeModal] = useAtom(closeModalAtom);
  const maxTotalSize = 104857600;
  const maxFileSize = 104857600;
  const { control, handleSubmit, formState: { errors } } = useForm({
    mode: 'onChange',
    defaultValues: {
      generalNoteType: generalNote?.noteType || '',
      subject: generalNote?.subject || '',
      note: generalNote?.note || '',
      attachments: generalNote?.attachments || [],
      createdBy: generalNote?.createdBy || '',
      createdDate: generalNote?.createdDate || '',
      lastModified: generalNote?.lastModified || ''
    } as GeneralNote
  });

  const onSubmit = (data: GeneralNote) => {
    console.log(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid='general-note-form'>
      <Stack height='100%' width='100%' justifyContent='space-between'>
        <Stack padding='32px' gap='16px' sx={{ overflowY: 'auto' }}>
          <Box>
            <TitleWithClose title={type === 'create' ? 'Add New Note' : 'Edit Note'} handleClose={closeModal} />
          </Box>
          <Stack gap='16px' paddingTop='16px'>
            <Controller
              control={control}
              name='generalNoteType'
              rules={{
                required: 'Note Type is required'
              }}
              render={({ field }) => (
                <SelectInput
                  {...field}
                  id='generalNoteType'
                  label='Note Type'
                  error={errors.generalNoteType ? true : false}
                  errorText={errors.generalNoteType?.message}
                  isMandatory
                  options={[
                    { value: 'General', label: 'General' },
                    { value: 'Reminder', label: 'Reminder' }
                  ]}
                />
              )}
            />
            <Controller
              control={control}
              name='subject'
              rules={{
                maxLength: { value: 100, message: 'Subject is too long' }
              }}
              render={({ field }) => (
                <TextInput
                  {...field}
                  label='Subject'
                  id='subject'
                  error={errors.subject ? true : false}
                  errorText={errors.subject?.message}
                  helperText='Max. 100 characters'
                />
              )}
            />
            <Controller
              control={control}
              name='note'
              rules={{
                required: 'Note is required',
                maxLength: { value: 200, message: 'Note is too long' }
              }}
              render={({ field }) => (
                <TextareaInput
                  {...field}
                  id='note'
                  label='Note'
                  error={errors.note ? true : false}
                  errorText={errors.note?.message}
                  isMandatory
                />
              )}
            />
          </Stack>
          <Stack gap='4px'>
            <Stack flexDirection='row' gap='4px' alignItems='center'>
              <Typography variant='subtitle2' fontWeight='500' color={variables.colors.text.primary}>
                Documents
              </Typography>
              <Tooltip
                toolTipTitle='Supported documents'
                toolTipText={`You can upload multiple files, summing up to maximum ${(maxTotalSize / 1048576).toFixed(0)}MB. Files supported: ${validFileTypes.join(', ')}.`}
                toolTipTriggerComponent={
                  <Box height='16px' width='16px' position='relative' minWidth='16px'>
                    <InfoOutlinedIcon sx={{ height: '100%', width: '100%' }} />
                  </Box>
                }
              />
            </Stack>
            <DragAndDropFileUpload
              setCurrentFiles={setCurrentFiles}
              currentFiles={currentFiles}
              validFileTypes={validFileTypes}
              maxTotalSize={maxTotalSize}
              maxFileSize={maxFileSize}
              multiple
            />
            {/* TODO: When we know what the attachments/file storage looks like we need to update the below */}
            {generalNote?.attachments.map((file, index) => (
              <SubmittedState
                key={index}
                uploadedFile={file}
                showDeleteIcon
                removeFile={() => setCurrentFiles((prevFiles) => prevFiles.filter((_, i) => i !== index))}
              />
            ))}
          </Stack>
        </Stack>
        <ButtonStack>
          <Button variant='outlined' color='primary' onClick={closeModal}>Cancel</Button>
          <Button variant='contained' color='primary' type='submit'>Add Note</Button>
        </ButtonStack>
      </Stack >
    </form>
  );
};

const ButtonStack = styled(Stack)`
  gap: 16px;
  justify-content: flex-end;
  flex-direction: row;
  padding: 24px 32px;
`;

export default ServiceProviderNoteForm;