import React, { FC } from 'react';
import { LinearProgress } from '@mui/material';
import ViewContact from 'components/features/ViewContact';
import { useFetchDataPoint } from 'hooks/useFetchRecordById';
import { getExternalContactByID } from 'api/organisations/externalcontacts';

const ViewContactPage: FC = () => {
  const { isLoading, currentDataPoint, hasError } = useFetchDataPoint({ getByIDFunc: getExternalContactByID });
  if (isLoading) return <LinearProgress />;
  if (hasError || !currentDataPoint) return null;

  return (
    <ViewContact externalContact={currentDataPoint} />
  );
};

export default ViewContactPage;