import { Box, Stack, Typography } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import GoalHistory from './GoalHistory';
import { ViewParticipantsGoalProps } from '..';
import variables from 'styles/variables';
import Tooltip from 'components/common/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ParticipantGoalStatusDropdown from 'components/features/DataTables/ParticipantGoalsDataTable/Controls/Columns/ParticipantGoalStatusDropdown';
import Label from 'components/common/Label';
import { isPastDate } from 'utils/helpers';
import Score from 'components/common/Score';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import Line from 'components/common/Line';
import LineHeader from 'components/common/LineHeader';
import { format, parseISO } from 'date-fns';

const GoalDetails: FC<ViewParticipantsGoalProps> = ({ participantGoal }) => {
  const [isReviewDataPast, setIsReviewDataPast] = useState(false);

  useEffect(() => {
    isPastDate(participantGoal.reviewDate) ? setIsReviewDataPast(true) : setIsReviewDataPast(false);
  }, [participantGoal.reviewDate]);

  return (
    <OuterStack>
      <DetailsStack>
        <LineHeader>
          Goal Details
        </LineHeader>
        <Line flexDirection='column' alignItems='flex-start'>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            Goal
          </Typography>
          <Typography variant='subtitle1'>
            {participantGoal.goal}
          </Typography>
          <Typography variant='body2'>
            {participantGoal.description}
          </Typography>
        </Line>
        <Line>
          <Stack flexDirection='row' gap='16px' width={'100%'} justifyContent='space-between'>
            <Stack gap='8px' width='25%'>
              <Typography variant='body2' color={variables.colors.text.secondary}>
                Goal Type
              </Typography>
              <Label text={participantGoal.goalType.toUpperCase()} />
            </Stack>
            <Stack gap='8px' width='25%'>
              <Typography variant='body2' color={variables.colors.text.secondary}>
                Status
              </Typography>
              <ParticipantGoalStatusDropdown goal={participantGoal} />
            </Stack>
            <Stack gap='8px' width='25%'>
              <Typography variant='body2' color={variables.colors.text.secondary}>
                Review Date
              </Typography>
              <Stack flexDirection='row' gap='8px' alignItems='center'>
                <Typography variant='subtitle2' color={isReviewDataPast ? 'error' : variables.colors.text.primary}>
                  {participantGoal.reviewDate && format(parseISO(participantGoal.reviewDate), 'dd/MM/yyyy, hh:mm a')}
                </Typography>
                {isReviewDataPast && <WarningOutlinedIcon color='error' sx={{ height: '20px', width: '20px', }} />}
              </Stack>
            </Stack>
            <Stack gap='8px' width='25%'>
              <Stack flexDirection='row' gap='8px' alignItems='center'>
                <Typography variant='body2' color={variables.colors.text.secondary}>
                  Score
                </Typography>
                <Tooltip
                  position='right'
                  toolTipTitle='Score'
                  toolTipText='Score is an average calculated based on the progress notes linked to the goal.'
                  toolTipTriggerComponent={
                    <Box height='16px' width='16px' position='relative' minWidth='16px'>
                      <InfoOutlinedIcon sx={{ height: '100%', width: '100%', color: variables.colors.text.secondary }} />
                    </Box>
                  }
                />
              </Stack>
              <Score score={participantGoal.score} />
            </Stack>
          </Stack>
        </Line>
        <Line noBottomBorder>
          <Stack flexDirection='row' gap='16px' width={'100%'} justifyContent='space-between'>
            <Stack gap='8px' width='100%'>
              <Typography variant='body2' color={variables.colors.text.secondary}>
                Created by
              </Typography>
              <Stack gap='8px' flexDirection='row'>
                <Typography variant='body2' color={variables.colors.text.secondary}>
                  {participantGoal.created.userFullName.firstName} {participantGoal.created.userFullName.lastName}
                </Typography>
                <Typography variant='subtitle2'>
                  {participantGoal.created.date && format(parseISO(participantGoal.created.date), 'dd/MM/yyyy, hh:mm a')}
                </Typography>
              </Stack>
            </Stack>
            <Stack gap='8px' width='100%'>
              <Typography variant='body2' color={variables.colors.text.secondary}>
                Last update
              </Typography>
              <Stack gap='8px' flexDirection='row'>
                <Typography variant='body2' color={variables.colors.text.secondary}>
                  {participantGoal.lastUpdated.userFullName.firstName} {participantGoal.lastUpdated.userFullName.lastName}
                </Typography>
                <Typography variant='subtitle2'>
                  {participantGoal.lastUpdated.date && format(parseISO(participantGoal.lastUpdated.date), 'dd/MM/yyyy, hh:mm a')}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Line>
      </DetailsStack>
      <GoalHistory participantGoal={participantGoal} />
    </OuterStack>
  );
};

const OuterStack = styled(Stack)`
  width: 100%;
  height: 100%;
  padding: 24px;
  gap: 32px;
`;

const DetailsStack = styled(Stack)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
`;

export default GoalDetails;