import React, { FC } from 'react';
import { LinearProgress } from '@mui/material';
import ViewParticipantGoal from 'components/features/ViewParticipantGoal';
import { useParticipantGoalDataSync } from 'hooks/dataSync/useParticipantGoalDataSync';

const ViewParticipantGoalPage: FC = () => {
  const { isLoading, currentDataPoint } = useParticipantGoalDataSync();

  if (isLoading || !currentDataPoint.object) return <LinearProgress />;

  return <ViewParticipantGoal participantGoal={currentDataPoint.object} />;
};

export default ViewParticipantGoalPage;