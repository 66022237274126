import React, { FC } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import TextInput from 'components/common/InputFields/TextInput';
import { Organisation } from 'types/dbSchema/organisation';

export interface BankingInformationProps {
  control: Control<Organisation>;
  errors: FieldErrors<Organisation>;
}

const BankingInformation: FC<BankingInformationProps> = ({ control, errors }) => {
  return (
    <DetailsStack>
      <Typography variant='h6' fontWeight='600' color='text.primary'>
        Banking Information
      </Typography>
      <Row numberOfChildren={3}>
        <Controller
          name="accountName"
          control={control}
          rules={{
            required: 'Account Name is required',
            pattern: { value: /^[a-zA-Z-]+$/, message: 'Account Name must only contain letters' },
            minLength: { value: 2, message: 'Account Name must be at least 2 characters long' },
            maxLength: { value: 50, message: 'Account Name must be at most 50 characters long' }
          }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='accountName'
              label='Account Name'
              error={errors.accountName ? true : false}
              errorText={errors.accountName?.message}
              isMandatory
            />}
        />
        <Controller
          name="bsb"
          control={control}
          rules={{
            required: 'BSB is required',
            minLength: { value: 6, message: 'BSB is too short' },
            maxLength: { value: 6, message: 'BSB is too long' },
          }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='bsb'
              label='BSB'
              error={errors.bsb ? true : false}
              errorText={errors.bsb?.message}
              isMandatory
            />}
        />
        <Controller
          name="accountNumber"
          control={control}
          rules={{
            required: 'Account Number is required',
            minLength: { value: 8, message: 'Account Number is too short' },
            maxLength: { value: 12, message: 'Account Number is too long' },
          }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='accountNumber'
              label='Bank Account Number'
              error={errors.accountNumber ? true : false}
              errorText={errors.accountNumber?.message}
              isMandatory
            />}
        />
      </Row>
    </DetailsStack>
  );
};

const DetailsStack = styled(Stack)`
  background-color: white;
  padding: 32px;
  gap: 24px;
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;
  max-width: 960px;
`;

export interface RowProps {
  numberOfChildren?: number;
}

const Row = styled(Stack).withConfig({
  shouldForwardProp: (prop) => prop !== 'numberOfChildren'
}) <RowProps>`
  gap: 24px;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start; /* Changed to flex-start to accommodate varying numbers of children */
  align-items: flex-start;

  & > * {
    flex: 0 0 calc((100% - 48px) / ${({ numberOfChildren }) => numberOfChildren});
    max-width: calc((100% - 48px) / ${({ numberOfChildren }) => numberOfChildren});
  }
`;

export default BankingInformation;