import { Button, LinearProgress, Stack, Typography } from '@mui/material';
import { getUserById, updateUserById } from 'api/organisations/users';
import { currentDataPointAtom, modalStateAtom } from 'atoms';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import { closeModalAtom } from 'atoms/modalAtom';
import NumberInput from 'components/common/InputFields/NumberInput';
import StatusDropdown from 'components/common/StatusDropdown';
import useErrorMessage from 'hooks/useErrorMessage';
import { useFetchDataPoint } from 'hooks/useFetchRecordById';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { useAtom } from 'jotai';
import React, { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import variables from 'styles/variables';
import { ServiceRegion } from 'types/dbSchema/serviceRegions';
import { UserPosition } from 'types/dbSchema/userPositions';
import { User, userProfileStatuses } from 'types/dbSchema/userProfiles';
import { formatISODate } from 'utils/helpers';

const ServiceTeamEmployeeDetails = () => {
  const { isLoading, currentDataPoint, hasError } = useFetchDataPoint({ getByIDFunc: getUserById });
  if (isLoading) return <LinearProgress />;
  if (hasError || !currentDataPoint) return null;

  const user = currentDataPoint as User;

  return (
    <Stack width='100%' height='100%' gap='32px' padding='32px 24px' alignItems='center' sx={{ backgroundColor: 'white', overflowY: 'auto' }}>
      <PersonalDetails user={user} />
      <ContactDetails user={user} />
      <EmploymentDetails user={user} />
    </Stack >
  );
};

const PersonalDetails: FC<{ user: User }> = ({ user }) => {
  return (
    <Stack gap='16px' width='100%' maxWidth='1000px'>
      <Typography fontSize='18px' fontWeight='600'>
        Personal Details
      </Typography>
      <DetailsStack>
        <Stack width='100%' flexDirection='row' justifyContent='space-between' alignItems='center' alignContent='center'>
          <Stack alignSelf='flex-start' width='100%' gap='8px'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Name
            </Typography>
            <Stack gap='4px' flexDirection='row'>
              {user?.salutation &&
                <Typography variant='body2' color={variables.colors.text.main} textTransform='capitalize'>
                  {user.salutation}
                </Typography>
              }
              <Typography variant='body2' color={variables.colors.text.main} fontWeight='600'>
                {user.fullName.firstName} {user.fullName.lastName}
              </Typography>
            </Stack>
          </Stack>
          <Stack alignSelf='flex-start' width='100%' gap='8px'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Date of Birth
            </Typography>
            <Typography variant='body2' color={variables.colors.text.main}>
              {user?.dob && formatISODate(user.dob)}
            </Typography>
          </Stack>
          <Stack alignSelf='flex-start' width='100%' gap='8px'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Gender
            </Typography>
            <Typography variant='body2' color={variables.colors.text.main}>
              {user?.gender}
            </Typography>
          </Stack>
          <Stack alignSelf='flex-start' width='100%' gap='8px'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Pronoun
            </Typography>
            <Typography variant='body2' color={variables.colors.text.main}>
              {user?.pronoun}
            </Typography>
          </Stack>
        </Stack>
      </DetailsStack>
    </Stack>
  );
};

const ContactDetails: FC<{ user: User }> = ({ user }) => {
  return (
    <Stack gap='16px' width='100%' maxWidth='1000px'>
      <Typography fontSize='18px' fontWeight='600'>
        Contact Details
      </Typography>
      <DetailsStack>
        <Stack width='100%' flexDirection='row' justifyContent='space-between' alignItems='center' alignContent='center'>
          <Stack alignSelf='flex-start' width='100%' gap='8px'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Work Email
            </Typography>
            <Typography variant='body2' color={variables.colors.text.main}>
              {user?.workEmail}
            </Typography>
          </Stack>
          <Stack alignSelf='flex-start' width='100%' gap='8px'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Mobile
            </Typography>
            <Typography variant='body2' color={variables.colors.text.main}>
              {user.primaryMobile}
            </Typography>
          </Stack>
          <Stack alignSelf='flex-start' width='100%' gap='8px'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Phone
            </Typography>
            <Typography variant='body2' color={variables.colors.text.main}>
              {user?.phone}
            </Typography>
          </Stack>
        </Stack>
        <Stack width='100%' flexDirection='row' justifyContent='space-between' alignItems='center' alignContent='center'>
          <Stack alignSelf='flex-start' width='100%' gap='8px'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Address
            </Typography>
            <Typography variant='body2' textTransform='capitalize'>
              {`${user.address.streetAddress}, ${user.address.suburb}, ${user.address.state}, ${user.address.country},  ${user.address.postCode}`}
            </Typography>
          </Stack>
        </Stack>
      </DetailsStack>
    </Stack>
  );
};

const EmploymentDetails: FC<{ user: User }> = ({ user }) => {
  const [modalState, setModalState] = useAtom(modalStateAtom);

  const handleModalOpen = () => {
    setModalState({
      ...modalState,
      status: 'open',
      position: 'center',
      component: MaxHoursModal,
      props: {
        dataTableName: 'user-profile',
        user,
      }
    });
  };

  return (
    <Stack gap='16px' width='100%' maxWidth='1000px'>
      <Typography fontSize='18px' fontWeight='600'>
        Employment Details
      </Typography>
      <DetailsStack>
        <Stack width='100%' flexDirection='row' justifyContent='space-between' alignItems='center' alignContent='center'>
          <Stack alignSelf='flex-start' width='100%' gap='8px'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Position
            </Typography>
            <Typography variant='body2' color={variables.colors.text.main} fontWeight='600'>
              {(user.position as UserPosition).name}
            </Typography>
          </Stack>
          <Stack alignSelf='flex-start' width='100%' gap='8px'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Employee Type
            </Typography>
            <Typography variant='body2' color={variables.colors.text.main}>
              {user?.employeeType}
            </Typography>
          </Stack>
          <Stack alignSelf='flex-start' width='100%' gap='8px'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Service Region
            </Typography>
            <Typography variant='body2' color={variables.colors.text.main}>
              {user?.serviceRegion && (user.serviceRegion as ServiceRegion).name}
            </Typography>
          </Stack>
        </Stack>
        <Stack width='100%' flexDirection='row' justifyContent='space-between' alignItems='center' alignContent='center'>
          <Stack alignSelf='flex-start' width='100%' gap='8px'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Employee Number
            </Typography>
            <Typography variant='body2' color={variables.colors.text.main}>
              {user?.employeeNo}
            </Typography>
          </Stack>
          <Stack alignSelf='flex-start' width='100%' gap='8px'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Employee Start Date
            </Typography>
            <Typography variant='body2' color={variables.colors.text.main}>
              {user?.employmentStartDate && formatISODate(user.employmentStartDate)}
            </Typography>
          </Stack>
          <Stack alignSelf='flex-start' width='100%' gap='8px'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Status
            </Typography>
            <StatusDropdown
              statuses={userProfileStatuses}
              currentStatus={user.status}
              disabled
            />
          </Stack>
        </Stack>
      </DetailsStack>
      <DetailsStack flexDirection='row' justifyContent='space-between' alignItems='center' alignContent='center'>
        <Stack width='100%' gap='8px'>
          <Typography variant='subtitle2'>
            Maximum Regular Working Hours
          </Typography>
          <Typography variant='body2' color={variables.colors.text.main}>
            This information is used to calculate the team member’s overtime.
          </Typography>
        </Stack>
        <Button variant='contained' color='primary' sx={{ alignSelf: 'center', minWidth: 'fit-content' }} onClick={handleModalOpen}>
          Add maximum hours
        </Button>
      </DetailsStack>
    </Stack>
  );
};

const MaxHoursModal: FC<{ user: User }> = ({ user }) => {
  const [, closeModal] = useAtom(closeModalAtom);
  const [organisations] = useUserOrganisations();
  const [isLoading, setIsLoading] = useState(false);
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const showError = useErrorMessage();
  const [isEdit, setIsEdit] = useState(false);
  const { control, handleSubmit, formState: { errors, isDirty } } = useForm({
    mode: 'onChange',
    defaultValues: {
      maxHours: undefined
    }
  });


  const handleMaxHoursChange = async (number: number | undefined) => {
    try {
      console.log('number', number);
      closeModal();
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <form style={{ position: 'relative', width: '500px', padding: '32px' }} onSubmit={handleSubmit((data) => handleMaxHoursChange(data.maxHours))}>
      {/* {isLoading && <LoadingOverlay sx={{ borderRadius: '12px' }} />} */}
      <Stack height='fit-content' gap='24px'>
        <Stack>
          <Typography variant='h6' fontWeight='600' mb={2}>
            Maximum Regular Working Hours
          </Typography>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            Define the employee’s maximum regular working hours per week. This information will be used to calculate when hours have entered overtime.
          </Typography>
        </Stack>
        <Controller
          name="maxHours"
          control={control}
          rules={{ required: 'Max hours is required' }}
          render={({ field }) =>
            <NumberInput
              {...field}
              id='maxHours'
              label='Maximum Regular Working Hours (per week)'
              placeholder='Enter position'
              error={errors.maxHours ? true : false}
              errorText={errors?.maxHours?.message}
              isMandatory
              helperText='Example: 38.5'
            />
          }
        />
        <Stack flexDirection='row' justifyContent='flex-end' gap='16px' padding='24px 0 0 0'>
          <Button variant='outlined' color='primary' onClick={closeModal}>
            Cancel
          </Button>
          <Button type='submit' variant='contained' color='primary' disabled={!isDirty}>
            Save
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};


const DetailsStack = styled(Stack)`
  padding: 24px;
  gap: 32px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  background: #F8F7FA;
`;

export default ServiceTeamEmployeeDetails;