import React, { FC, useEffect, useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import { Controller, useForm } from 'react-hook-form';
import { closeModalAtom } from 'atoms/modalAtom';
import TextInput from 'components/common/InputFields/TextInput';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import LoadingOverlay from 'components/common/LoadingOverlay';
import useErrorMessage from 'hooks/useErrorMessage';
import IsActiveDropdown from 'components/common/IsActiveDropdown';
import { createExternalContact, updateExternalContact } from 'api/organisations/externalcontacts';
import { ExternalContact } from 'types/dbSchema/externalContacts';
import useCurrentUser from 'hooks/useCurrentUser';
import { triggerRefreshCurrentDataPointAtom } from 'atoms';

export interface ExternalContactFormProps {
  externalContact?: ExternalContact;
}

const ExternalContactForm: FC<ExternalContactFormProps> = ({ externalContact }) => {
  const [, closeModal] = useAtom(closeModalAtom);
  const [organisations] = useUserOrganisations();
  const [isLoading, setIsLoading] = useState(false);
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const showError = useErrorMessage();
  const [isEdit, setIsEdit] = useState(false);
  const [currentUser] = useCurrentUser();
  const [, setTriggerRefresh] = useAtom(triggerRefreshCurrentDataPointAtom);
  const { control, handleSubmit, formState: { errors, isDirty } } = useForm({
    mode: 'onChange',
    defaultValues: externalContact || {
      isActive: true // Default value for isActive is required for the IsActiveDropdown component
    }
  });

  useEffect(() => {
    externalContact ? setIsEdit(true) : setIsEdit(false);
  }, [externalContact]);

  const handleEditExternalContact = async (data: ExternalContact) => {
    try {
      await updateExternalContact(organisations[0].organisation.globalId, data);
      setInvalidateData((prev) => ({ ...prev, 'external-contacts': true }));
      setTriggerRefresh(true);
      closeModal();
    } catch (error) {
      showError((error as Error).message);
    }
  };

  const handleCreateExternalContact = async (data: ExternalContact) => {
    if (!currentUser) {
      showError('Current User not found');
      return;
    }
    try {
      await createExternalContact(organisations[0].organisation.globalId, { ...data, createdBy: currentUser?.id });
      setInvalidateData((prev) => ({ ...prev, 'external-contacts': true }));
      closeModal();
    } catch (error) {
      showError((error as Error).message);
    }
  };

  const submitForm = async (data?: ExternalContact) => {
    try {
      if (!data) return;
      setIsLoading(true);
      if (isEdit) {
        handleEditExternalContact(data as ExternalContact);
      } else {
        handleCreateExternalContact(data as ExternalContact);
      }
      setIsLoading(false);
    } catch (error) {
      showError((error as Error).message);
      setIsLoading(false);
    }
  };

  return (
    <form style={{ position: 'relative', width: '500px', padding: '32px' }} onSubmit={handleSubmit((data) => submitForm(data))}>
      {isLoading && <LoadingOverlay sx={{ borderRadius: '12px' }} />}
      <Stack height='fit-content' gap='24px'>
        <Typography variant='h6' fontWeight='600' mb={2}>
          {isEdit ? 'Edit Contact' : 'Add New Contact'}
        </Typography>
        <Stack gap='20px' flexDirection='row'>
          <Controller
            name="fullName.firstName"
            control={control}
            rules={{
              required: 'First Name is required',
              minLength: { value: 2, message: 'First Name must be at least 2 characters' }
            }}
            render={({ field }) =>
              <TextInput
                {...field}
                id='firstName'
                label='First Name'
                error={errors.fullName?.firstName ? true : false}
                errorText={errors.fullName?.firstName?.message}
                isMandatory
              />
            }
          />
          <Controller
            name="fullName.lastName"
            control={control}
            rules={{
              required: 'Last Name is required',
              minLength: { value: 2, message: 'Last Name must be at least 2 characters' }
            }}
            render={({ field }) =>
              <TextInput
                {...field}
                id='lastName'
                label='Description'
                error={errors.fullName?.lastName ? true : false}
                errorText={errors?.fullName?.lastName?.message}
                isMandatory
              />
            }
          />
        </Stack>
        <Stack gap='20px' flexDirection='row'>
          <Controller
            name="mobile"
            control={control}
            rules={{
              required: 'Mobile is required',
              pattern: { value: /^[0-9]{10}$/, message: 'Invalid mobile number' },
            }}
            render={({ field }) =>
              <TextInput
                {...field}
                id='mobile'
                label='Mobile'
                error={errors.mobile ? true : false}
                errorText={errors?.mobile?.message}
                isMandatory
              />
            }
          />
          <Controller
            name="phone"
            control={control}
            rules={{
              required: 'Phone is required'
            }}
            render={({ field }) =>
              <TextInput
                {...field}
                id='phone'
                label='Phone'
                error={errors.phone ? true : false}
                errorText={errors?.phone?.message}
                isMandatory
              />
            }
          />
        </Stack>
        <Controller
          name="email"
          control={control}
          rules={{
            required: 'Email is required',
            pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, message: 'Invalid Email Address' },
          }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='email'
              label='Email'
              error={errors.email ? true : false}
              errorText={errors?.email?.message}
              isMandatory
            />
          }
        />
        <Controller
          name="isActive"
          control={control}
          rules={{
            validate: value => value !== undefined && value !== null || 'Status is required'
          }}
          render={({ field }) =>
            <IsActiveDropdown
              isActive={(field.value === undefined || field.value === null) ? true : field.value}
              setIsActive={field.onChange}
            />
          }
        />
        <Stack flexDirection='row' justifyContent='flex-end' gap='16px' padding='24px 0 0 0'>
          <Button variant='outlined' color='primary' onClick={closeModal}>
            Cancel
          </Button>
          <Button type='submit' variant='contained' color='primary' disabled={!isDirty}>
            {isEdit ? 'Update' : 'Create'}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default ExternalContactForm;