import { Address, PaginationBase, Pronouns } from '..';
import { ChangeLogData } from '../../components/common/ChangeLog/types';
import { RelationshipType } from './participantSettings';
import { ServiceRegion } from './serviceRegions';
import { UserPosition } from './userPositions';
import { UserRole } from './userRoles';

export type UserType = 'Web App User' | 'Mobile App User';
export const userTypes: UserType[] = ['Web App User', 'Mobile App User'];
export type SystemUserStatus = 'Active' | 'Inactive' | 'PendingInvite' | 'InviteSent' | 'ExpiredInvite';
export const systemUserStatuses: SystemUserStatus[] = ['Active', 'Inactive', 'PendingInvite', 'InviteSent', 'ExpiredInvite']; // TODO: to be removed
export type EmployeeType = 'Full-Time' | 'Part-Time' | 'Casual' | 'Contractor';
export const employeeTypes = ['Full-Time', 'Part-Time', 'Casual', 'Contractor'];

export interface UserProfileCellProps {
  userProfile: User;
  displayOnly?: boolean;
  nameOnly?: boolean;
}

export interface User {
  id: string;
  profilePicture?: string;
  salutation?: string;
  fullName: FullName;
  pronoun?: Pronouns;
  phone?: string;
  primaryMobile: string;
  workEmail: string;
  personalEmail?: string;
  dob: string; //  "2024-07-02T02:52:51.560Z" This format
  gender: string;
  address: Address;
  employmentStartDate?: string; //  "2024-07-02T02:52:51.560Z" This format
  employeeNo?: string;
  serviceRegion?: ServiceRegion | string; // in POST & PATCH will be the ServiceRegion ID. In GET it will be the position.
  manager?: Manager | string; // This is a User Profile record - in POST & PATCH will be the userprofile ID. In GET it will be the user profile.
  employeeType?: string;
  nextOfKinFullName: string;
  nextOfKinMobile: string;
  nextOfKinPhone?: string;
  nextOfKinRelationship: RelationshipType | string; // in POST & PATCH will be the RelationshipType ID. In GET it will be the relationship type.
  lastLoginTime?: string; //  "2024-07-02T02:52:51.560Z" This format
  lastLoginTimeMobileApp?: string; //  "2024-07-02T02:52:51.560Z" This format
  changeLog: ChangeLogData[];
  timezone: string; // +10, +9 etc.
  userRoles: UserRole[] | string[]; // in POST & PATCH will be the UserRole IDs. In GET it will be the user roles.
  webAppUserStatus: boolean;
  mobileAppUserStatus: boolean;
  position: UserPosition | string; // in POST & PATCH will be the position ID. In GET it will be the position.
  status: SystemUserStatus; // 'Inactive' | 'PendingInvite' | 'InviteSent' | 'ExpiredInvite';
  isActive: boolean;
  isInvite: boolean;
}

export type UsersList = PaginationBase<User>;
export interface Manager {
  id: string;
  fullName: string;
}

export interface UserAndDate {
  userID: string;  // User Profile ID
  userFullName: FullName;
  date: string; // "2024-07-02T02:52:51.560Z" This format
}

export interface FullName {
  firstName: string;
  middleName?: string;
  lastName: string;
}

export const userProfileStatuses = [
  {
    label: 'Active',
    value: 'Active',
    fontColor: '#356328',
    backgroundColor: '#69C64F4D',
  },
  {
    label: 'Inactive',
    value: 'Inactive',
    fontColor: '#5C068C',
    backgroundColor: '#5C068C26',
  },
  {
    label: 'Invite Sent',
    value: 'Invite Sent',
    fontColor: '#0277BD',
    backgroundColor: '#03A9F433',
  },
  {
    label: 'Pending Invite',
    value: 'Pending Invite',
    fontColor: '#C62828',
    backgroundColor: '#E5393533',
  },
  {
    label: 'No Access',
    value: 'No Access',
    fontColor: '#212121',
    backgroundColor: '#2121210A',
  },
  {
    label: 'Expired Invite',
    value: 'Expired Invite',
    fontColor: '#EF6C00',
    backgroundColor: '#FFB30033',
  }
];