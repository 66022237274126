import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import styled from 'styled-components';
import DataTable from 'components/common/DataTable';
import SearchInput from 'components/common/InputFields/SearchInput';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { closeModalAtom } from 'atoms/modalAtom';
import { Controller, useForm } from 'react-hook-form';
import TextInput from 'components/common/InputFields/TextInput';
import variables from 'styles/variables';
import IsActiveDropdown from 'components/common/IsActiveDropdown';
import { LeaveType, leaveTypesColorList } from 'types/dbSchema/availabilitySettings';
import ColorDisplayBox from 'components/common/ColorDisplayBox';
import ColorSelectInput from 'components/common/InputFields/ColorSelectInput';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Data, PaginationModel } from 'components/common/DataTable/types';
import useInvalidateData from 'hooks/useInvalidateData';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import useErrorMessage from 'hooks/useErrorMessage';
import { createLeaveType, listLeaveTypes, updateLeaveType } from 'api/organisations/settings/availability-settings/leave-types';
import { dummyLeaveTypes } from 'utils/helpers/getDBData';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';

// This file handle all of the leave types for the scheduling settings
// If it is too long, we can abstract the individual components into their own files
const LeaveTypes = () => {
  const [searchValue, setSearchValue] = useState('');
  const [, setModalState] = useAtom(modalStateAtom);
  const [paginationModel, setPaginationModel] = useState<PaginationModel>({ page: 0, pageSize: 20 });
  const [organisations] = useUserOrganisations();
  useInvalidateData('leave-type');

  // UseQuery hook to fetch the data, handle caching, handle loading states, pagination, and error states
  const { isError, data, isPending, isFetching, isLoading } = useQuery({
    queryKey: ['leave-type', paginationModel],
    // queryFn: () => listLeaveTypes(organisations[0].organisation.globalId, paginationModel.page + 1, paginationModel.pageSize),
    queryFn: () => dummyLeaveTypes,
    placeholderData: keepPreviousData,
    enabled: organisations.length > 0,
    refetchOnWindowFocus: false,
    staleTime: cachingInvalidation.leaveTypes,
  });

  // Filter out the colors that are already in use
  // const filteredColorList = useMemo(() => data ? (data?.items as LeaveType[]).map(row => row.color) : [], [data]);
  const filteredColorList = useMemo(() => data ? (data as LeaveType[]).map(row => row.color) : [], [data]);

  // Function to open/close the modal.
  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: LeaveTypesForm,
      props: {
        dataTableName: 'leave-type',
        filterOut: filteredColorList,
        type: 'create',
      }
    }));
  }, [setModalState, filteredColorList]);

  // Grid column definitions for DataTable
  // We have nested the column array into this component to be able to pass the filteredColorList to the Edit component
  // There may be a more elegant solution but this works - moving on
  const columns: GridColDef[] = [
    {
      field: 'edit',
      headerName: '',
      width: 50,
      renderCell: (params) => (
        <Edit leaveType={params.row} filteredColorList={filteredColorList} />
      ),
      sortable: false,
    },
    {
      field: 'name',
      headerName: 'Leave Type',
      width: 200,
      renderCell: (params) => (
        <Typography variant='subtitle2'>
          {params.row.name}
        </Typography>
      ),
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 3,
      minWidth: 200,
      renderCell: (params) => (
        <Typography variant='body2' color={variables.colors.text.secondary}>
          {params.row.description}
        </Typography>
      ),
    },
    {
      field: 'color',
      headerName: 'Color',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <ColorDisplayBox color={params.row.color} />
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      renderCell: (params) => (
        <EditStatus leaveType={params.row} />
      ),
    },
  ];

  return (
    <StyledBox>
      <SearchBox>
        <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} placeholderText='Search' />
        <Button variant='contained' sx={{ padding: '8px 16px' }} onClick={handleModalOpen}>
          <AddOutlinedIcon sx={{ marginRight: '8px' }} />
          Add
        </Button>
      </SearchBox>
      {/* The box height is set to 100vh - the height of the SearchBox, tabs, and Nav. This is needed because the MUI datatable needs a fixed height */}
      <Box height='calc(100vh - 295px)'>
        <DataTable
          // data={data as Data}
          data={{ items: dummyLeaveTypes, totalCount: 1, pageNumber: paginationModel.page, pageSize: paginationModel.pageSize }}
          columns={columns}
          checkboxSelection={false}
          dataTableName='leave-type'
          isPending={isFetching || isPending || isLoading}
          isError={isError}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          title='Leave Types'
          paginationMode='server'
          onNoData={handleModalOpen}
        />
      </Box>
    </StyledBox>
  );
};

// Add/Edit form
const LeaveTypesForm = ({ type, leaveType, filterOut }: { type: 'create' | 'edit'; leaveType: LeaveType, filterOut: string[] }) => {
  const [, closeModal] = useAtom(closeModalAtom);
  const [organisations] = useUserOrganisations();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const showError = useErrorMessage();
  const { control, handleSubmit, formState: { errors, isDirty } } = useForm({
    mode: 'onChange',
    defaultValues: leaveType || {
      isActive: true // Default value for isActive is required for the IsActiveDropdown component
    }
  });

  // Function to create a new Leave Type
  // Invalidates the query to fetch the latest data, and closes the modal
  const handleCreate = async (data: LeaveType) => {
    try {
      await createLeaveType(organisations[0].organisation.globalId, data);
      setInvalidateData((prev) => ({ ...prev, 'leave-type': true }));
      closeModal();
    } catch (error) {
      showError((error as Error).message);
    }
  };

  // Function to update a Leave Type.
  // Invalidates the query to fetch the latest data, and closes the modal
  const handleEdit = async (data: LeaveType) => {
    try {
      await updateLeaveType(organisations[0].organisation.globalId, data);
      setInvalidateData((prev) => ({ ...prev, 'leave-type': true }));
      closeModal();
    } catch (error) {
      showError((error as Error).message);
    }
  };

  // Function to handle form submission
  const handleSubmitForm = async (data: LeaveType) => {
    if (type === 'create') {
      await handleCreate(data);
    }
    else {
      await handleEdit(data);
    }
  };

  return (
    <form style={{ width: '500px' }} onSubmit={handleSubmit(handleSubmitForm)}>
      <Stack height='fit-content' padding='32px' gap='24px'>
        <Typography variant='h6' fontWeight='600'>
          {type === 'create' ? 'Add Leave Type' : 'Edit Leave Type'}
        </Typography>
        <Controller
          name="name"
          control={control}
          rules={{
            required: 'Leave Type is required',
            maxLength: { value: 40, message: 'Leave Type should not exceed 40 characters' },
            pattern: {
              value: /^[a-zA-Z0-9!@#$%^&*()_+-=[\]{};':"|,.<>/? ]*$/,
              message: 'Leave Type must be alphanumeric'
            },
          }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='leaveType'
              label='Name'
              placeholder='Enter Leave Type'
              error={errors.name ? true : false}
              errorText={errors?.name?.message}
              isMandatory
            />
          }
        />
        <Controller
          name="color"
          control={control}
          rules={{ required: 'color is required' }}
          render={({ field }) => (
            <ColorSelectInput
              {...field}
              id="color"
              label="Color"
              error={errors.color ? true : false}
              errorText={errors.color?.message}
              placeholder="Select"
              filterOut={filterOut}
              colorList={leaveTypesColorList}
              isMandatory
            />
          )}
        />
        <Stack flexDirection='row' justifyContent='flex-start' alignItems='center' gap='16px'>
          <Typography variant='subtitle2' fontWeight='500'>
            Status
          </Typography>
          <Controller
            name="isActive"
            control={control}
            rules={{
              validate: value => value !== undefined && value !== null || 'Status is required'
            }}
            render={({ field }) =>
              <IsActiveDropdown
                isActive={(field.value === undefined || field.value === null) ? true : field.value}
                setIsActive={field.onChange}
              />
            }
          />
        </Stack>
      </Stack>
      <Stack flexDirection='row' gap={2} width='100%' justifyContent='flex-end' maxWidth='960px' padding='16px 32px' boxSizing='border-box' sx={{ backgroundColor: variables.colors.lightNeutral.subtle, borderRadius: '0 0 12px 12px' }} >
        <Button variant='outlined' color='primary' onClick={closeModal}>
          Cancel
        </Button>
        <Button type='submit' variant='contained' color='primary' disabled={!isDirty}>
          {type === 'create' ? 'Create' : 'Update'}
        </Button>
      </Stack>
    </form>
  );
};

// Renders Edit Icon for Grid column definitions - opens add/edit form in modal
const Edit = ({ leaveType, filteredColorList }: { leaveType: LeaveType, filteredColorList: string[] }) => {
  const [, setModalState] = useAtom(modalStateAtom);
  if (!leaveType || !leaveType.isEditable) return null;

  // Function to open/close the modal.
  // The filteredColorList is passed to the form to filter out the colors that are already in use
  // We also filter the color of the current row to be able to select it
  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: LeaveTypesForm,
      props: {
        dataTableName: 'leave-type',
        type: 'edit',
        leaveType: leaveType,
        filterOut: filteredColorList.filter(color => color !== leaveType.color)
      }
    }));
  }, [setModalState, leaveType]);

  return (
    <Stack justifyContent='center' alignItems='center' sx={{ cursor: 'pointer' }} onClick={handleModalOpen}>
      <EditOutlinedIcon sx={{ color: '#9CA3AF' }} />
    </Stack>
  );
};

// Handles the status dropdown in the DataTable
const EditStatus = ({ leaveType }: { leaveType: LeaveType }) => {
  const [organisations] = useUserOrganisations();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  useInvalidateData('leave-type');
  const showError = useErrorMessage();

  const handleEdit = async (status: boolean) => {
    try {
      await updateLeaveType(organisations[0].organisation.globalId, { ...leaveType, isActive: status });
      setInvalidateData((prev) => ({ ...prev, 'leave-type': true }));
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <IsActiveDropdown
      isActive={leaveType.isActive}
      setIsActive={handleEdit}
      disabled={!leaveType.isEditable}
    />
  );
};

const StyledBox = styled(Box)`
  height: 100%;
  width: 100%;
  background-color: white;
`;

const SearchBox = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 24px;
  gap: 16px;
`;

export default LeaveTypes;