import React, { FC } from 'react';
import { LinearProgress } from '@mui/material';
import ViewServicerProviderContact from 'components/features/ViewServiceProviderContact';
import { useFetchDataPoint } from 'hooks/useFetchRecordById';
import { getExternalContactByID } from 'api/organisations/externalcontacts';

const ViewServiceProviderContactPage: FC = () => {
  const { isLoading, currentDataPoint, hasError } = useFetchDataPoint({ getByIDFunc: getExternalContactByID });
  if (isLoading) return <LinearProgress />;
  if (hasError || !currentDataPoint) return null;

  return <ViewServicerProviderContact contact={currentDataPoint.object} />;
};

export default ViewServiceProviderContactPage;