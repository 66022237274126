import { GeneralNote } from "types/dbSchema/generalNotes";
import { Participant } from "types/dbSchema/participants";
import { ExitReason, GeneralNoteType, ParticipantStatus, PrimaryDisability } from "types/dbSchema/participantSettings";
import { ServiceRegion } from "types/dbSchema/serviceRegions";
import { Tag } from "types/dbSchema/serviceTeamSettings";
import { Manager } from "types/dbSchema/userProfiles";

export const convertParticipant = (participant: Participant): Participant => {
  console.log('🚀 ~ convertParticipant ~ participant:', participant);
  return {
    ...participant,
    status: (participant.status as ParticipantStatus).id,
    // serviceRegion: (participant.serviceRegion as ServiceRegion).id,
    servicePreferences: (participant.servicePreferences as Tag[]).map((servicePreference) => servicePreference.id),
    exitReason: participant.exitReason ? (participant.exitReason as ExitReason).id : undefined,
    assignedTo: (participant.assignedTo as Manager).id,
    primaryDisability: participant.primaryDisability ? (participant.primaryDisability as PrimaryDisability).id : undefined,
  };
};

export const convertGeneralNote = (generalNote: GeneralNote): GeneralNote => {
  return {
    ...generalNote,
    generalNoteType: (generalNote.generalNoteType as GeneralNoteType).id,
    participant: (generalNote.participant as Manager),
    createdBy: (generalNote.participant as Manager),
  };
};