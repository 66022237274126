import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import ViewIcon from './ViewIcon';
import variables from 'styles/variables';
import { Typography } from '@mui/material';
import DownloadAttachmentsIcon from 'components/common/DownloadAttachmentsIcon';
import { formatISODateTime } from 'utils/helpers';
import { Manager } from 'types/dbSchema/userProfiles';
import { GeneralNoteType } from 'types/dbSchema/participantSettings';

export const Columns: GridColDef[] = [
  {
    field: 'view',
    headerName: '',
    width: 50,
    sortable: false,
    renderCell: (params) => (
      <ViewIcon generalNote={params.row} />
    ),
  },
  {
    field: 'download',
    headerName: '',
    width: 75,
    sortable: false,
    renderCell: (params) => (
      <DownloadAttachmentsIcon attachments={params.row.attachments} />
    ),
  },
  {
    field: 'generalNoteType',
    headerName: 'Note Type',
    width: 200,
    sortable: true,
    renderCell: (params) => (
      <Typography variant='body2'>
        {(params.row.generalNoteType as GeneralNoteType).name}
      </Typography>
    ),
  },
  {
    field: 'note',
    headerName: 'Note',
    flex: 1,
    minWidth: 200,
    sortable: true,
    renderCell: (params) => (
      <Typography variant='body2' color={variables.colors.text.secondary} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
        {params.row.note}
      </Typography>
    ),
  },
  {
    field: 'createdBy',
    headerName: 'Created By',
    width: 150,
    sortable: true,
    renderCell: (params) => (
      <Typography variant='body2' color={variables.colors.text.secondary}>
        {(params.row.createdBy as Manager)?.fullName}
      </Typography>
    ),
  },
  {
    field: 'createdDate',
    headerName: 'Created Date',
    width: 200,
    sortable: true,
    renderCell: (params) => (
      <Typography variant='body2' color={variables.colors.text.secondary}>
        {params.row?.createdDate && formatISODateTime(params.row?.createdDate)}
      </Typography>
    ),
  },
  {
    field: 'lastModified',
    headerName: 'Last Modified',
    width: 200,
    sortable: true,
    renderCell: (params) => (
      <Typography variant='body2' color={variables.colors.text.secondary}>
        {params.row?.lastModified && formatISODateTime(params.row?.lastModified)}
      </Typography>
    ),
  },
];