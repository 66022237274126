import { Box, Stack, Typography } from '@mui/material';
import FilterWithDropdown from 'components/common/FilterWithDropdown';
import { IFilter, SelectedFilter } from 'components/common/FilterWithDropdown/types';
import SearchInput from 'components/common/InputFields/SearchInput';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import variables from 'styles/variables';
import { ViewParticipantsGoalProps } from '../..';
import RichTextInput from 'components/common/InputFields/RichTextInput';
import UserProfileCell from 'components/features/UserProfileCell';
import { GoalHistory as IGoalHistory } from 'types/dbSchema/participantGoals';
import GoalHistoryCard from '../GoalHistoryCard';
import { dummyUserProfileDataNEW } from 'utils/helpers/getDBData';

const GoalHistory: FC<ViewParticipantsGoalProps> = ({ participantGoal }) => {
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilter[]>([]);
  const [searchText, setSearchText] = useState('');
  const [filterOpen, setFilterOpen] = useState('');
  const filters = [
    {
      field: 'goalType',
      name: 'Goal Type',
      options: ['General Comment', 'Goal Review']
    },
    {
      field: 'status',
      name: 'Status',
      options: ['Not Started', 'In Progress', 'Completed']
    },
  ];

  // TODO: When APIs are ready, replace this with actual API call
  const handleNewComment = (text: string) => {
    console.log(text);
  };

  return (
    <Stack width='100%'>
      <GoalHistoryStackHeader>
        <Stack flexDirection='row' gap='24px' width='100%' justifyContent='space-between'>
          <Typography variant='h6' fontWeight='600' color='text.primary'>
            Goal History
          </Typography>
        </Stack>
        <Stack flexDirection='row' gap='16px' height='36px' minWidth='fit-content'>
          <Filters>
            {filters.map((filter: IFilter, index: number) => (
              <FilterWithDropdown
                key={index}
                filter={filter}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                filterOpen={filterOpen}
                setFilterOpen={setFilterOpen}
              />
            ))}
            {selectedFilters.length > 0 &&
              <ResetFiltersBox onClick={() => setSelectedFilters([])}>
                <Typography variant='body2' fontWeight='600' color='secondary' >
                  Reset filters
                </Typography>
              </ResetFiltersBox>
            }
          </Filters>
          <SearchInput searchValue={searchText} setSearchValue={setSearchText} placeholderText='Search' height='100%' />
        </Stack>
      </GoalHistoryStackHeader>
      <RichTextBox>
        <Stack gap='16px' flexDirection='row' alignItems='center' marginBottom='16px'>
          <UserProfileCell displayOnly userProfile={dummyUserProfileDataNEW[0]} nameOnly />
        </Stack>
        <RichTextInput
          characterLimit={500}
          buttonText='Add Comment'
          onSubmit={handleNewComment}
          minHeight='108px'
        />
      </RichTextBox >
      <Stack gap='16px' padding='32px 0'>
        {participantGoal.goalHistory.map((goalHistory: IGoalHistory, index: number) => (
          <GoalHistoryCard key={index} goalHistory={goalHistory} />
        ))}
      </Stack>
    </Stack >
  );
};

const GoalHistoryStackHeader = styled(Stack)`
  width: 100%;
  flex-direction: row;
`;

const RichTextBox = styled(Box)`
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  padding: 16px;
  /* min-height: 190px; */
  height: fit-content;
  width: 100%;
  background: white;
   margin-top: 32px;
`;

const Filters = styled(Stack)`
  width: fit-content;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;

const ResetFiltersBox = styled(Stack)`
  cursor: pointer;
  padding: 8px;
  border-left: 1px solid ${variables.colors.secondary.outlineBorder};
  min-width: fit-content;
`;


export default GoalHistory;