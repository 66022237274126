import React, { FC, useCallback, useEffect, useState } from 'react';
import { Box, Button, Input, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import { AddOutlined, CloseOutlined } from '@mui/icons-material';
import variables from 'styles/variables';
import { useNavigate, useParams } from 'react-router-dom';
import { Participant } from 'types/dbSchema/participants';
import { Control, Controller, FieldErrors, useForm } from 'react-hook-form';
import { LinkBetweenParticipantAndContact } from 'types/dbSchema/participantContact';
import IsActiveDropdown from 'components/common/IsActiveDropdown';
import TextInput from 'components/common/InputFields/TextInput';
import RelationshipTypeLookupField from 'components/common/LookupInputField/RelationshipTypeLookupField';
import CheckboxInput from 'components/common/InputFields/CheckboxInput';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { closeModalAtom } from 'atoms/modalAtom';
import PersonAddDisabledOutlinedIcon from '@mui/icons-material/PersonAddDisabledOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import SearchInput from 'components/common/InputFields/SearchInput';
import { ExternalContact } from 'types/dbSchema/externalContacts';

const LinkContactToParticipantForm: FC = () => {
  const { id } = useParams();
  const [selectedContact, setSelectedContact] = useState<ExternalContact | null>(null);
  const [linkOrCreate, setLinkOrCreate] = useState<'link' | 'create'>('link');
  const navigate = useNavigate();
  const [, setModalState] = useAtom(modalStateAtom);
  const { control, handleSubmit, formState: { errors, isDirty } } = useForm({
    mode: 'onChange',
    defaultValues: {
      isActive: true // Default value for isActive is required for the IsActiveDropdown component
    } as LinkBetweenParticipantAndContact
  });

  const { control: contactControl, handleSubmit: handleContactSubmit, formState: { errors: contactErrors, isDirty: isContactDirty } } = useForm({
    mode: 'onChange',
    defaultValues: {
      isActive: true // Default value for isActive is required for the IsActiveDropdown component
    } as ContactAndParticipantLinkFormProps
  });

  useEffect(() => {
    if (!id || id === 'undefined') navigate('/participant-centre/participants');
  }, [id, navigate]);

  const handleCancel = () => navigate(-1);

  // Function to open/close the modal.
  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: LinkModal,
      props: {
        selectedContact: selectedContact,
      }
    }));
  }, [setModalState]);

  const handleCreateLinkBetweenContactAndParticipant = (data: LinkBetweenParticipantAndContact) => {
    // TODO: Implement the logic to search if the contact already exists in the system.
    const alreadyExists = true;
    if (alreadyExists) {
      handleModalOpen();
    } else {
      console.log(data);
    }
  };

  const handleCreateNewContact = (data: ExternalContact) => {
    // TODO: Implement the logic to search if the contact already exists in the system.
    const alreadyExists = true;
    if (alreadyExists) {
      handleModalOpen();
    } else {
      console.log(data);
    }
  };

  const onSubmit = (data: LinkBetweenParticipantAndContact | ExternalContact) => {
    if (linkOrCreate === 'link') {
      handleCreateLinkBetweenContactAndParticipant(data as LinkBetweenParticipantAndContact);
    } else {
      handleCreateNewContact(data as ExternalContact);
    }
  };

  if (!id || id === 'undefined') return null;

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <Banner>
        <MaxWidthContainer>
          <Stack flexDirection='row' gap={1} alignItems='center' minWidth='fit-content'>
            <AddOutlined sx={{ borderRadius: '100%', border: ' 2px dashed #81D4FA', color: '#81D4FA', padding: '4px', height: '28px', width: '28px', boxSizing: 'border-box' }} />
            <Typography variant='h5'>
              Link Contact
            </Typography>
          </Stack>
          <CloseOutlined sx={{ cursor: 'pointer' }} onClick={handleCancel} />
        </MaxWidthContainer>
      </Banner>
      <Stack padding='32px' width='100%' height='fit-content' justifyContent='center' alignItems='center' boxSizing='border-box' gap='32px' overflow='auto'>
        <DetailsStack>
          <Typography variant='h6' fontWeight='600'>
            Link a contact
          </Typography>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            {`Easily link contacts to participants by searching existing entries. 
            If the contact isn't listed, utilise the "add new contact" shortcut to include them in the Contacts Console, simultaneously linking them to the participant profile.`}
          </Typography>
          <Stack flexDirection='row' gap={2} width='100%' justifyContent='flex-start'>
            <Button variant={linkOrCreate === 'link' ? 'contained' : 'outlined'} onClick={() => setLinkOrCreate('link')}>Link a Contact</Button>
            <Button variant={linkOrCreate === 'create' ? 'contained' : 'outlined'} onClick={() => setLinkOrCreate('create')}>Add New Contact</Button>
          </Stack>
        </DetailsStack>
        {linkOrCreate === 'link' ? <LinkContactDetails control={control} errors={errors} /> : <CreateContact control={contactControl} errors={contactErrors} />}
        <Stack flexDirection='row' gap={2} width='100%' justifyContent='flex-end' maxWidth='960px' boxSizing='border-box'>
          <Button variant='text' onClick={handleCancel}>Cancel</Button>
          <Button variant='contained' type='submit'>Link Contact</Button>
        </Stack>
      </Stack>
    </StyledForm>
  );
};


export interface LinkDetailsProps {
  control: Control<LinkBetweenParticipantAndContact>;
  errors: FieldErrors<LinkBetweenParticipantAndContact>;
}

export const LinkContactDetails: FC<LinkDetailsProps> = ({ control, errors }) => {

  return (
    <DetailsStack>
      <Stack padding='24px' sx={{ backgroundColor: '#F9FAFB' }}>
        <Stack gap='8px'>
          <Typography variant='h6' fontWeight='600'>
            Link a contact
          </Typography>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            Find contacts across Contacts Console, Participants and Service Provider lists. Search by adding name and/or email.
          </Typography>
        </Stack>
      </Stack>
      <Stack gap='32px'>
        <Typography variant='h6' fontWeight='600'>
          Contact Details
        </Typography>
        <Stack flexDirection='row' gap='32px' width='100%'>
          <Stack flexDirection='row' border={`1px dashed ${variables.colors.primary.lighter}`} height='200px' borderRadius='8px' padding='16px' gap='16px' width='100%'>
            <Typography variant='body2' color={variables.colors.text.secondary}>
              Your selected contact will be displayed here.
            </Typography>
          </Stack>
          <Stack width='100%' gap='24px'>
            <Controller
              name="relationship"
              control={control}
              rules={{
                required: 'Next of Kin Relationship is required',
              }}
              render={({ field }) =>
                <RelationshipTypeLookupField
                  {...field}
                  id='relationship'
                  label='Relationship'
                  placeholder='Select'
                  validationError={errors.relationship ? true : false}
                  errorText={errors.relationship?.message}
                  isMandatory
                />
              }
            />
            <Stack>
              <Typography variant='subtitle2' fontWeight='500' color={variables.colors.text.primary} sx={{ marginBottom: '4px' }}>
                Contact Tags
              </Typography>
              <Controller
                name="isPrimaryContact"
                control={control}
                render={({ field }) =>
                  <CheckboxInput
                    {...field}
                    id='isPrimaryContact'
                    label="Primary Contact"
                    error={errors.isPrimaryContact ? true : false}
                    checked={field.value ? true : false}
                  />}
              />
              <Controller
                name="isEmergencyContact"
                control={control}
                render={({ field }) =>
                  <CheckboxInput
                    {...field}
                    id='isEmergencyContact'
                    label="Emergency Contact"
                    error={errors.isEmergencyContact ? true : false}
                    checked={field.value ? true : false}
                  />}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </DetailsStack>
  );
};

// export interface CreateContactDetailsProps {
//   control: Control<ExternalContact>;
//   errors: FieldErrors<ExternalContact>;
// }

export type ContactAndParticipantLinkFormProps = ExternalContact & LinkBetweenParticipantAndContact;


export interface CreateContactDetailsProps {
  control: Control<ContactAndParticipantLinkFormProps>;
  errors: FieldErrors<ContactAndParticipantLinkFormProps>;
}

export const CreateContact: FC<CreateContactDetailsProps> = ({ control, errors }) => {
  return (
    <DetailsStack>
      <Stack gap='8px'>
        <Typography variant='subtitle1'>
          Add New Contact
        </Typography>
        <Typography variant='body2' color={variables.colors.text.secondary}>
          Contacts will be created in the Contacts Console list and establish a link to Participants Contacts for seamless integration.
        </Typography>
      </Stack>
      <Stack gap='20px' flexDirection='row'>
        <Controller
          name="fullName.firstName"
          control={control}
          rules={{ required: 'First Name is required' }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='firstName'
              label='First Name'
              error={errors.fullName?.firstName ? true : false}
              errorText={errors.fullName?.firstName?.message}
              isMandatory
            />
          }
        />
        <Controller
          name="fullName.lastName"
          control={control}
          rules={{ required: 'Last Name is required' }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='lastName'
              label='Description'
              error={errors.fullName?.lastName ? true : false}
              errorText={errors?.fullName?.lastName?.message}
              isMandatory
            />
          }
        />
      </Stack>
      <Stack gap='20px' flexDirection='row'>
        <Controller
          name="relationship"
          control={control}
          rules={{
            required: 'Next of Kin Relationship is required',
          }}
          render={({ field }) =>
            <RelationshipTypeLookupField
              {...field}
              id='relationship'
              label='Relationship'
              placeholder='Select'
              validationError={errors.relationship ? true : false}
              errorText={errors.relationship?.message}
              isMandatory
            />
          }
        />
        <Controller
          name="email"
          control={control}
          rules={{
            required: 'Email is required',
            pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, message: 'Invalid Email Address' },
          }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='email'
              label='Email'
              error={errors.email ? true : false}
              errorText={errors?.email?.message}
              isMandatory
            />
          }
        />
      </Stack>
      <Stack gap='20px' flexDirection='row'>
        <Controller
          name="mobile"
          control={control}
          rules={{
            required: 'Mobile is required',
            pattern: { value: /^[0-9]{10}$/, message: 'Invalid mobile number' },
          }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='mobile'
              label='Mobile'
              error={errors.mobile ? true : false}
              errorText={errors?.mobile?.message}
              isMandatory
            />
          }
        />
        <Controller
          name="phone"
          control={control}
          rules={{ required: 'Phone is required' }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='phone'
              label='Phone'
              error={errors.phone ? true : false}
              errorText={errors?.phone?.message}
              isMandatory
            />
          }
        />
      </Stack>
      <Stack>
        <Typography variant='subtitle2' fontWeight='500' color={variables.colors.text.primary} sx={{ marginBottom: '4px' }}>
          Contact Tags
        </Typography>
        <Stack gap='20px' flexDirection='row'>
          <Controller
            name="isPrimaryContact"
            control={control}
            render={({ field }) =>
              <CheckboxInput
                {...field}
                id='isPrimaryContact'
                label="Primary Contact"
                error={errors.isPrimaryContact ? true : false}
                checked={field.value ? true : false}
              />}
          />
          <Controller
            name="isEmergencyContact"
            control={control}
            render={({ field }) =>
              <CheckboxInput
                {...field}
                id='isEmergencyContact'
                label="Emergency Contact"
                error={errors.isEmergencyContact ? true : false}
                checked={field.value ? true : false}
              />}
          />
        </Stack>
      </Stack>
      <Stack flexDirection='row' justifyContent='flex-start' alignItems='center' gap='16px'>
        <Typography variant='subtitle2' fontWeight='500'>
          Status
        </Typography>
        <Controller
          name="isActive"
          control={control}
          rules={{
            validate: value => value !== undefined && value !== null || 'Status is required'
          }}
          render={({ field }) =>
            <IsActiveDropdown
              isActive={(field.value === undefined || field.value === null) ? true : field.value}
              setIsActive={field.onChange}
            />
          }
        />
      </Stack>
    </DetailsStack>
  );
};

export const LinkModal = ({ selectedContact }: { selectedContact: ExternalContact }) => {
  const [, closeModal] = useAtom(closeModalAtom);

  const handleLink = () => {
    console.log('Linked contact from Participant', selectedContact);
    closeModal();
  };

  return (
    <Stack alignItems="center" width='500px'>
      <Stack flexDirection="row" alignItems="center" gap='24px' sx={{ padding: '32px' }}>
        <Box sx={{ borderRadius: '100%', background: '#FFECB3', padding: '8px', height: '40px', width: '40px', display: 'flex', alignSelf: 'flex-start', boxSizing: 'border-box' }}>
          <PersonAddDisabledOutlinedIcon color='warning' />
        </Box>
        <Stack gap='16px'>
          <Typography variant='h6'>
            Contact already in the system
          </Typography>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            The contact was found in the system. Would you like to link this contact with the participant?
          </Typography>
        </Stack>
      </Stack>
      <Box sx={{ padding: '0 32px 32px 32px', width: '100%' }}>
        <Stack flexDirection='row' border={`1px dashed ${variables.colors.primary.lighter}`} borderRadius='8px' padding='16px' gap='16px' width='100%' sx={{ boxSizing: 'border-box' }}>
          <Box sx={{
            borderRadius: '100%', background: 'rgba(75, 85, 99, 0.12)', padding: '8px', height: '40px', width: '40px', display: 'flex', alignSelf: 'flex-start', boxSizing: 'border-box'
          }}>
            <AccountBoxOutlinedIcon sx={{ color: variables.colors.icon.standard }} />
          </Box>
          <Stack>
            <Typography fontSize='18px' fontWeight='600'>
              Full Name
            </Typography>
            <Stack flexDirection='row' gap='8px'>
              <Typography variant='body2' color={variables.colors.text.secondary}>
                Email
              </Typography>
              <Typography variant='body2'>
                Email
              </Typography>
            </Stack>
            <Stack flexDirection='row' gap='8px'>
              <Typography variant='body2' color={variables.colors.text.secondary}>
                Mobile
              </Typography>
              <Typography variant='body2'>
                Mobile
              </Typography>
            </Stack>
            <Stack flexDirection='row' gap='8px'>
              <Typography variant='body2' color={variables.colors.text.secondary}>
                Phone
              </Typography>
              <Typography variant='body2'>
                Phone
              </Typography>
            </Stack>
            <Stack flexDirection='row' gap='8px'>
              <Typography variant='body2' color={variables.colors.text.secondary}>
                Service Provider
              </Typography>
              <Typography variant='body2'>
                Service Provider
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Box >
      <ButtonStack>
        <Button variant='outlined' onClick={closeModal}>Cancel</Button>
        <Button variant='contained' color='primary' onClick={handleLink}>Link this contact</Button>
      </ButtonStack>
    </Stack >
  );
};

const ButtonStack = styled(Stack)`
      flex-direction: row;
      width: 100%;
      padding: 16px 32px;
      justify-content: flex-end;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
      border-radius: 0px 0px 12px 12px;
      background: #F9FAFB;
      box-sizing: border-box;
      `;

const Banner = styled.div`
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
      padding: 16px;
      background-color: ${variables.colors.primary.darker};
      width: 100%;
      box-sizing: border-box;
      max-width: 100%;
      color: white;
      position: fixed;
      padding: 16px 32px;
      top: 0;
      left: 0;
      z-index: 1000;
      height: 70px;
      `;

const MaxWidthContainer = styled.div`
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      box-sizing: border-box;
      max-width: 960px;
      `;

const StyledForm = styled.form`
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 32px;
      width: 100%;
      box-sizing: border-box;
      position: relative;
      height: fit-content;
      background-color: #F3F4F6;
      margin-top: 70px;
      min-height: calc(100vh - 70px);
      `;

const DetailsStack = styled(Stack)`
      background-color: white;
      padding: 32px;
      gap: 24px;
      border-radius: 8px;
      box-sizing: border-box;
      width: 100%;
      max-width: 960px;
      `;

export default LinkContactToParticipantForm;