import React, { FC } from 'react';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useAtom } from 'jotai';
import { currentDataPointAtom } from 'atoms';
import variables from 'styles/variables';
import { ExternalContact } from 'types/dbSchema/externalContacts';

export interface ViewIconProps {
  participantContact: ExternalContact;
}

const ViewIcon: FC<ViewIconProps> = ({ participantContact }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [, setCurrentDataPoint] = useAtom(currentDataPointAtom);

  const handleViewGoal = () => {
    setCurrentDataPoint({
      object: participantContact,
      type: 'participant-goal',
    });
    navigate(`/participant-centre/participants/view/${id}/contacts/${participantContact.id}`);
  };

  return (
    <StyledBox onClick={handleViewGoal}>
      <RemoveRedEyeOutlinedIcon sx={{ color: variables.colors.icon.standard }} />
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

export default ViewIcon;