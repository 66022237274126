import React, { FC } from 'react';
import { LinearProgress } from '@mui/material';
import ViewParticipantContact from 'components/features/ViewParticipant/Contacts/ViewParticipantContact';
import { useFetchDataPoint } from 'hooks/useFetchRecordById';
import { getParticipantById } from 'api/organisations/participants';

const ViewParticipantContactPage: FC = () => {
  const { isLoading, currentDataPoint, hasError } = useFetchDataPoint({ getByIDFunc: getParticipantById });
  if (isLoading) return <LinearProgress />;
  if (hasError || !currentDataPoint) return null;

  return <ViewParticipantContact participantContact={currentDataPoint.object} />;
};

export default ViewParticipantContactPage;