import React, { createElement, FC } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/features/Layout';
import ProtectedRoute from './components/common/ProtectedRoute';
import { pages, uniquePages, pagesWithoutLayout } from 'utils/helpers/pagesRoutes';
import { IPages } from './types';
import PermissionWrapper from 'components/common/PermissionWrapper';

// The App component is the root component of the application.
const App: FC = () => {
  return (
    <Routes>
      {/* Pages that handle their own layout, authorization, and authentication (e.g. login, return) */}
      {renderUniqueRoutes(uniquePages)}

      {/* Pages accessible to authenticated users only but without the layout */}
      {renderRoutesProtected(pagesWithoutLayout)}

      {/* Pages accessible to authenticated users only and with the layout (i.e. the main app) */}
      <Route path='/' element={<ProtectedRoute component={<Layout />} />}>
        {renderRoutes(pages)}
      </Route>
      {/* Redirect any unmatched routes to the home page */}
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};

const renderUniqueRoutes = (pageList: IPages[]) => {
  return pageList.map((page, index) => (
    <Route
      key={index}
      path={page.to}
      element={page.pageComponent && createElement(page.pageComponent, page.props)}
    />
  ));
};

const renderRoutes = (pageList: IPages[]) => {
  return pageList.map((page, index) => (
    <Route
      key={index}
      path={page.to}
      element={
        page.pageComponent &&
        <PermissionWrapper requiredPermissions={page.requiredPermissions} redirect='/'>
          {createElement(page.pageComponent, page.props)}
        </PermissionWrapper>
      }
    />
  ));
};

const renderRoutesProtected = (pageList: IPages[]) => {
  return pageList.map((page, index) => {
    if (page.pageComponent) {
      return (
        <Route
          key={index}
          path={page.to}
          element={<ProtectedRoute component={
            <PermissionWrapper requiredPermissions={page.requiredPermissions} redirect='/' >
              {createElement(page.pageComponent, page.props)}
            </PermissionWrapper>
          } />}
        />
      );
    }
    return null; // Return null if page.pageComponent is false
  });
};
export default App;