import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import variables from 'styles/variables';
import { useNavigate } from 'react-router-dom';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import { closeModalAtom } from 'atoms/modalAtom';
import { Organisation } from 'types/dbSchema/organisation';
import Details from './Details';
import ContactDetails from './ContactDetails';
import OwnerDetails from './OwnerDetails';
import BankingInformation from './BankingInformation';
import useUserOrganisations from 'hooks/useUserOrganisations';
import useErrorMessage from 'hooks/useErrorMessage';
import { dummyUserProfileDataNEW } from 'utils/helpers/getDBData';
import { editOrganisation } from 'api';

export interface ModalState {
  isOpen: boolean;
  type: ModalType;
}

export type ModalType = 'update' | 'cancel';

const OrganisationDetailsForm: FC = () => {
  const [organisations] = useUserOrganisations();
  const [modalState, setModalState] = useAtom(modalStateAtom);
  const { control, handleSubmit, formState: { errors }, getValues, setValue } = useForm<Organisation>({
    defaultValues: organisations[0].organisation || {},
    mode: 'onChange'
  });

  // We are not submitting the form here, we trigger the validation checks.
  // If validation passes, open a modal which will handle the submission
  // This will allow us to show a confirmation modal before actually submitting the form to the DB
  const onSubmit = () => {
    if (Object.keys(errors).length !== 0) return;
    setModalState({
      ...modalState,
      status: 'open',
      position: 'center',
      component: EditOrganisationModalContent,
      props: {
        ...modalState.props,
        formData: getValues(),
      },
    });
  };

  const handleCancel = () => {
    setModalState({
      ...modalState,
      status: 'open',
      position: 'center',
      component: EditOrganisationModalContent,
      props: {
        type: 'cancel',
        formData: getValues(),
      },
    });
  };

  const handleModalStateChange = (type: ModalType) => {
    setModalState({
      ...modalState,
      props: {
        type: type,
      },
    });
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <Banner>
        <MaxWidthContainer>
          <Stack flexDirection='row' gap={1} alignItems='center' minWidth='fit-content'>
            <EditOutlinedIcon sx={{ borderRadius: '100%', border: ' 2px dashed #81D4FA', color: '#81D4FA', padding: '4px', height: '28px', width: '28px', boxSizing: 'border-box' }} />
            <Typography variant='h5'>
              Edit Organisation Details
            </Typography>
          </Stack>
          <Stack flexDirection='row' gap={2} width='100%' justifyContent='flex-end' maxWidth='960px'>
            <Button variant='outlined' sx={{ color: 'white', border: '1px solid white' }} onClick={handleCancel}>Cancel</Button>
            <Button variant='contained' type="submit" sx={{ color: 'white', backgroundColor: variables.colors.secondary.main, ':hover': { backgroundColor: variables.colors.secondary.darker } }} onClick={() => handleModalStateChange('update')}>Update</Button>
          </Stack>
        </MaxWidthContainer>
      </Banner>
      <Stack padding='32px' width='100%' height='fit-content' justifyContent='center' alignItems='center' boxSizing='border-box' gap='32px' overflow='auto'>
        <Details control={control} errors={errors} />
        <ContactDetails
          control={control}
          errors={errors}
          setValue={setValue}
        />
        {/* TODO: How are we grabbing the owner user profile from the organisation data? */}
        <OwnerDetails owner={dummyUserProfileDataNEW[0]} />
        <BankingInformation control={control} errors={errors} />
      </Stack>
    </StyledForm>
  );
};

export interface EditOrganisationModalContentProps {
  formData: Organisation;
}

const EditOrganisationModalContent: FC<EditOrganisationModalContentProps> = ({ formData }) => {
  const navigate = useNavigate();
  const [modalState] = useAtom(modalStateAtom);
  const [, closeModal] = useAtom(closeModalAtom);
  const showError = useErrorMessage();
  const [organisations] = useUserOrganisations();

  const handleUpdate = () => {
    try {
      // TODO: When APIs are ready, replace the console.log with the API call
      // NOTE: If postal address is empty, set it to business address
      const updateOrg = editOrganisation(formData);
      console.log('🚀 ~ handleUpdate ~ updateOrg:', updateOrg);
      closeModal();
      navigate('/settings/organisation/organisation-settings');
    } catch (error) {
      showError((error as Error).message);
    }
  };

  const handleLeave = () => {
    closeModal();
    navigate('/settings/organisation/organisation-settings');
  };

  return (
    <Stack width='500px' boxSizing='border-box'>
      <Stack flexDirection='row' gap='24px' width='100%' padding='32px' boxSizing='border-box'>
        <Stack>
          <Stack sx={{ backgroundColor: modalState.props.type === 'cancel' ? variables.colors.error.subtle : variables.colors.primary.subtle, borderRadius: '100%', width: '40px', height: '40px', justifyContent: 'center', alignItems: 'center' }} >
            {modalState.props.type === 'update'
              ? <EditOutlinedIcon sx={{ color: variables.colors.primary.darker, width: '24px' }} />
              : <CancelOutlinedIcon sx={{ color: variables.colors.error.main, width: '24px' }} />
            }
          </Stack>
        </Stack>
        <Stack gap='12px'>
          <Typography variant='h6'>
            {modalState.props.type === 'update'
              ? 'Update Organisation?'
              : 'Are you sure you want to leave?'
            }
          </Typography>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            {modalState.props.type === 'update'
              ? 'Please confirm to update your Organisation details.'
              : 'Any information added will be lost.'
            }
          </Typography>
        </Stack>
      </Stack>
      <Stack flexDirection='row' gap={2} width='100%' justifyContent='flex-end' maxWidth='960px' padding='16px 32px' boxSizing='border-box' sx={{ backgroundColor: variables.colors.lightNeutral.subtle, borderRadius: '0 0 12px 12px' }} >
        {modalState.props.type === 'cancel' ? (
          <>
            <Button variant='text' onClick={closeModal} sx={{ border: `1px solid ${variables.colors.lightNeutral.darker}` }}>Stay</Button>
            <Button variant='contained' color='error' onClick={handleLeave}>Leave</Button>
          </>
        ) : (
          <>
            <Button variant='text' onClick={closeModal}>Cancel</Button>
            <Button variant='contained' onClick={handleUpdate}>Confirm</Button>
          </>
        )}
      </Stack>
    </Stack>
  );
};

const Banner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 16px;
  background-color: ${variables.colors.primary.darker};
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
  color: white;
  position: fixed;
  padding: 16px 32px;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 70px;
`;

const MaxWidthContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  max-width: 960px;
  padding: 0 32px;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  height: fit-content;
  background-color: #F3F4F6;
  margin-top: 70px;
`;

export default OrganisationDetailsForm;