import React, { FC } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';
import styled from 'styled-components';
import TextInput from 'components/common/InputFields/TextInput';
import { Organisation } from 'types/dbSchema/organisation';
import { isValidABN } from 'utils/helpers';

export interface DetailsProps {
  control: Control<Organisation>;
  errors: FieldErrors<Organisation>;
}

const Details: FC<DetailsProps> = ({ control, errors }) => {

  return (
    <DetailsStack>
      <Typography variant='h6' fontWeight='600' color='text.primary'>
        Organisation Details
      </Typography>
      <Row numberOfChildren={2}>
        <Controller
          name="name"
          control={control}
          rules={{
            required: 'Organisation Name is required',
            pattern: { value: /^[a-zA-Z-]+$/, message: 'Organisation Name must only contain letters' },
            minLength: { value: 2, message: 'Organisation Name must be at least 2 characters long' },
            maxLength: { value: 50, message: 'Organisation Name must be at most 50 characters long' }
          }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='organisationName'
              label='Organisation Name'
              error={errors.name ? true : false}
              errorText={errors.name?.message}
              isMandatory
            />}
        />
        <Controller
          name="abn"
          control={control}
          rules={{
            required: 'ABN is required',
            minLength: { value: 2, message: 'ABN is too short' },
            maxLength: { value: 30, message: 'ABN is too long' },
            validate: {
              correctFormat: value => isValidABN(value) || 'ABN is invalid', // Custom validation rule
            }
          }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='ABN'
              label='ABN'
              error={errors.abn ? true : false}
              errorText={errors.abn?.message}
            />}
        />
      </Row>
      <Row numberOfChildren={2}>
        <Controller
          name="ndisOrganisationId"
          control={control}
          rules={{
            required: 'NDIS Organisation Number is required',
            minLength: { value: 2, message: 'NDIS Organisation Number is too short' },
            maxLength: { value: 30, message: 'NDIS Organisation Number is too long' }
          }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='ndisOrganisationId'
              label='NDIS Organisation ID'
              error={errors.ndisOrganisationId ? true : false}
              errorText={errors.ndisOrganisationId?.message}
              isMandatory
            />}
        />
        <Controller
          name="website"
          control={control}
          rules={{
            minLength: { value: 2, message: 'website is too short' },
            maxLength: { value: 50, message: 'website is too long' },
          }}
          render={({ field }) =>
            <TextInput
              {...field}
              id='website'
              label='Website'
              error={errors.website ? true : false}
              errorText={errors.website?.message}
            />}
        />
      </Row>
    </DetailsStack>
  );
};

const DetailsStack = styled(Stack)`
  background-color: white;
  padding: 32px;
  gap: 24px;
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;
  max-width: 960px;
`;

export interface RowProps {
  numberOfChildren?: number;
}

const Row = styled(Stack).withConfig({
  shouldForwardProp: (prop) => prop !== 'numberOfChildren'
}) <RowProps>`
  gap: 24px;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start; /* Changed to flex-start to accommodate varying numbers of children */
  align-items: flex-start;

  & > * {
    flex: 0 0 calc((100% - 48px) / ${({ numberOfChildren }) => numberOfChildren});
    max-width: calc((100% - 48px) / ${({ numberOfChildren }) => numberOfChildren});
  }
`;

export default Details;