import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import DataTableControls from './Controls';
import DataTable from 'components/common/DataTable';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Columns } from './Controls/Columns';
import { dummyDataServiceProviderNotes } from 'utils/helpers/getDBData';
import { ViewServiceProviderProps } from 'components/features/ViewServiceProvider/types';
import { PaginationModel } from 'components/common/DataTable/types';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import ServiceProviderNoteForm from 'components/features/Forms/ServiceProviderNoteForm';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';

const ServiceProviderNotesDataTable: FC<ViewServiceProviderProps> = ({ serviceProvider }) => {
  const [searchText, setSearchText] = useState('');
  const [paginationModel, setPaginationModel] = useState<PaginationModel>({ page: 0, pageSize: 20 });
  const [modalState, setModalState] = useAtom(modalStateAtom);

  const handleModalOpen = () => {
    setModalState({
      ...modalState,
      status: 'open',
      position: 'right',
      component: ServiceProviderNoteForm,
      props: {
        dataTableName: 'service-provider-notes',
        type: 'create',
      }
    });
  };

  const { isPending, isError, data, isFetching, } = useQuery({
    queryKey: ['service-provider-notes', paginationModel],
    queryFn: () => dummyDataServiceProviderNotes, // TODO: Replace with API call
    placeholderData: keepPreviousData,
    // enabled: organisations.length > 0,
    refetchOnWindowFocus: false,
    staleTime: cachingInvalidation.serviceProviderNotes,
  });

  return (
    <Stack height='100%' width='100%' sx={{ backgroundColor: 'white' }}>
      <DataTableControls
        searchText={searchText}
        setSearchText={setSearchText}
      />
      <DataTable
        data={{ items: data || [], totalCount: data?.length || 0, pageNumber: paginationModel.page, pageSize: paginationModel.pageSize }}
        columns={Columns}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        isPending={isPending || isFetching}
        isError={isError}
        title='Notes'
        dataTableName='service-provider-notes'
        checkboxSelection={false}
        paginationMode='server'
        onNoData={handleModalOpen}
      />
    </Stack>
  );
};

export default ServiceProviderNotesDataTable;