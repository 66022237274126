import React from 'react';
import ViewServiceProvider from 'components/features/ViewServiceProvider';
import { LinearProgress } from '@mui/material';
import { useServiceProviderDataSync } from 'hooks/dataSync/useServiceProviderDataSync';

const ViewServiceProvidersPage = () => {
  const { isLoading, currentDataPoint } = useServiceProviderDataSync();

  if (isLoading || !currentDataPoint) {
    return <LinearProgress />;
  }

  return (
    <ViewServiceProvider serviceProvider={currentDataPoint.object} />
  );
};

export default ViewServiceProvidersPage;