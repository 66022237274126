import React, { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Stack, Typography } from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import styled from 'styled-components';
import variables from 'styles/variables';
import { EditOutlined } from '@mui/icons-material';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { useAtom } from 'jotai';
import { modalStateAtom } from 'atoms';
import LineHeader from 'components/common/LineHeader';
import Line from 'components/common/Line';
import { RelationshipType } from 'types/dbSchema/participantSettings';
import { ServiceProvider, ServiceProviderContact } from 'types/dbSchema/serviceProviders';
import Label from 'components/common/Label';
import ExternalContactForm from 'components/features/Forms/ExternalContactForm';
import LinkIcon from '@mui/icons-material/Link';
import { closeModalAtom } from 'atoms/modalAtom';
import useUserOrganisations from 'hooks/useUserOrganisations';
import useInvalidateData from 'hooks/useInvalidateData';
import { listParticipants } from 'api/organisations/participants';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { PaginationModel } from 'components/common/DataTable/types';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Participant } from 'types/dbSchema/participants';
import { UserPosition } from 'types/dbSchema/userPositions';
import ServiceProviderContactForm from '../Forms/ServiceProviderContactForm';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';

export interface ViewServicerProviderContactProps {
  contact: ServiceProviderContact;
}

const ViewServicerProviderContact: FC<ViewServicerProviderContactProps> = ({ contact }) => {
  const [paginationModel] = useState<PaginationModel>({ page: 0, pageSize: 20 });
  const [organisations] = useUserOrganisations();
  useInvalidateData('participants'); // If the data table has been flagged for invalidation, invalidate the query

  // TODO: Change this to retrieve the linked participants from the contact object.
  const { isPending, isError, data, isFetching, isLoading } = useQuery({
    queryKey: ['participants', paginationModel],
    queryFn: () => listParticipants(organisations[0].organisation.globalId, null, paginationModel.page + 1, paginationModel.pageSize),
    placeholderData: keepPreviousData,
    enabled: organisations.length > 0,
    refetchOnWindowFocus: false,
    staleTime: cachingInvalidation.participantProfiles,
  });

  return (
    <></>
    // <Stack width='100%' justifyContent='flex-start' alignItems='center' height='100%' sx={{
    //   backgroundColor: 'white',
    //   minHeight: `calc(100vh - ${variables.heights.topBar})`,
    //   overflowY: 'auto',
    // }}>
    //   <PageHeader contact={contact} />
    //   <Stack gap='24px' width='100%' height='fit-content' padding='0 24px 24px' >
    //     <Stack gap='5px'>
    //       <ContactDetailsStack>
    //         <LineHeader>
    //           <Typography variant='subtitle2'>
    //             Contact Details
    //           </Typography>
    //         </LineHeader>
    //         <Line>
    //           <Stack width='100%'>
    //             <Typography variant='body2' color={variables.colors.text.secondary}>
    //               Email
    //             </Typography>
    //             <Typography variant='body2'>
    //               {contact.email}
    //             </Typography>
    //           </Stack>
    //           <Stack width='100%'>
    //             <Typography variant='body2' color={variables.colors.text.secondary}>
    //               Mobile Number
    //             </Typography>
    //             <Typography variant='body2'>
    //               {contact.mobile}
    //             </Typography>
    //           </Stack>
    //           <Stack width='100%'>
    //             <Typography variant='body2' color={variables.colors.text.secondary}>
    //               Phone Number
    //             </Typography>
    //             <Typography variant='body2'>
    //               {contact.phone}
    //             </Typography>
    //           </Stack>
    //         </Line>
    //         <Line noBottomBorder>
    //           <Stack width='100%'>
    //             <Typography variant='body2' color={variables.colors.text.secondary}>
    //               Position
    //             </Typography>
    //             <Typography variant='body2'>
    //               {(contact.position as UserPosition)?.name}
    //             </Typography>
    //           </Stack>
    //           <Stack width='100%'>
    //             <Typography variant='body2' color={variables.colors.text.secondary}>
    //               Service Provider
    //             </Typography>
    //             <Typography variant='body2'>
    //               {(contact.serviceProvider as ServiceProvider)?.companyName}
    //             </Typography>
    //           </Stack>
    //           <Stack width='100%'>
    //             <Typography variant='body2' color={variables.colors.text.secondary}>
    //               Contact Tags
    //             </Typography>
    //             <Stack direction='row' gap='8px'>
    //               {contact.isPrimaryContact && <Label text='Primary' />}
    //             </Stack>
    //           </Stack>
    //         </Line>
    //       </ContactDetailsStack>
    //       <Typography variant='body2' color={variables.colors.text.secondary}>
    //         {`Created by ${contact.createdBy.fullName.firstName} ${contact.createdBy.fullName.lastName}
    //          on ${new Date(contact.createdDateTime).toLocaleDateString()}`}
    //       </Typography>
    //     </Stack>
    //     <Stack gap='24px'>
    //       <Stack flexDirection='row' gap='16px' width='100%' justifyContent='space-between' alignContent='center'>
    //         <Typography variant='subtitle1'>
    //           Linked Participants
    //         </Typography>
    //       </Stack>
    //       <Box display='grid' gridTemplateColumns='repeat(auto-fill, minmax(368px, 1fr))' gap='24px'>
    //         {/* TODO: Map over linked Participants */}
    //         {data?.items.map((participant: any) => (
    //           <LinkedParticipant key={participant.id} participant={participant} />
    //         ))}
    //       </Box>
    //     </Stack>
    //   </Stack>
    // </Stack>
  );
};

const LinkedParticipant = ({ participant }: { participant: Participant }) => {
  const [, setModalState] = useAtom(modalStateAtom);

  // Function to open/close the modal.
  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      // component: UnlinkModal,
      props: {
        dataTableName: 'participants',
        participant: participant,
      }
    }));
  }, [setModalState]);

  return (
    <Stack
      flexDirection='row'
      gap='16px'
      alignItems='center'
      justifyContent='space-between'
      minWidth='368px'
      padding='16px 24px'
      sx={{
        backgroundColor: '#F9FAFB',
        border: '1px solid #E5E7EB',
        borderRadius: '4px',
      }}
    >
      <Stack flexDirection='row' gap='16px' alignItems='center'>
        <Box height='100%' width='fit-content' display='flex' alignItems='center'>
          <img src={participant.profilePicture} alt="profile" style={{ width: '42px', height: '42px', borderRadius: '32px', objectFit: 'cover' }} />
        </Box>
        <Stack>
          <Typography variant='subtitle2'>
            {/* {participant.fullName.firstName} {participant.fullName.lastName} */}
            First Name Last Name
          </Typography>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            {participant.email}
          </Typography>
        </Stack>
      </Stack>
      <LinkOffIcon sx={{ color: variables.colors.icon.standard, cursor: 'pointer' }} onClick={handleModalOpen} />
    </Stack>
  );
};

const PageHeader: FC<ViewServicerProviderContactProps> = ({ contact }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [, setModalState] = useAtom(modalStateAtom);

  // Function to open/close the modal.
  const handleEditModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: ServiceProviderContactForm,
      props: {
        dataTableName: 'service-provider-contacts',
        type: 'edit',
        serviceProviderContact: contact,
      }
    }));
  }, [setModalState, contact]);

  const handleDelete = () => {
    console.log('Delete contact', contact);
  };

  return (
    <PageHeaderStack>
      <Button variant='text'
        sx={{
          color: variables.colors.text.secondary,
          textTransform: 'none',
          fontWeight: 400,
          padding: 0
        }}
        onClick={() => navigate(`/resources/service-providers/view/${id}`)}
      >
        <ArrowBackOutlinedIcon sx={{
          padding: '4px',
          boxSizing: 'border-box',
          height: '24px',
          width: '24px',
        }}
        />
        Contacts
      </Button>
      <PageHeaderDetails>
        <Typography variant='h5' sx={{ textWrap: 'wrap' }}>
          {contact.fullName.firstName} {contact.fullName.lastName}
        </Typography>
        <Stack flexDirection='row' gap='16px' width='fit-content' alignItems='center'>
          <DeleteOutlinedIcon color='error' onClick={handleDelete} />
          <Button startIcon={<EditOutlined />} variant='contained' color='primary' onClick={handleEditModalOpen}>
            Edit
          </Button>
        </Stack>
      </PageHeaderDetails>
    </PageHeaderStack >
  );
};

// export const UnlinkModal = ({ participantContact }: { participantContact: ParticipantContact }) => {
//   const [, closeModal] = useAtom(closeModalAtom);

//   const handleUnlink = () => {
//     console.log('Unlink contact from Participant', participantContact);
//     closeModal();
//   };

//   return (
//     <Stack alignItems="center" width='500px'>
//       <Stack flexDirection="row" alignItems="center" gap='24px' sx={{ padding: '32px' }}>
//         <Box sx={{ borderRadius: '100%', background: '#FECDD2', padding: '8px', height: '40px', width: '40px', display: 'flex', alignSelf: 'flex-start', boxSizing: 'border-box' }}>
//           <LinkOffIcon color='error' />
//         </Box>
//         <Stack gap='16px'>
//           <Typography variant='h6'>
//             Unlink this contact
//           </Typography>
//           <Typography variant='body2' color={variables.colors.text.secondary}>
//             Are you sure you want to remove this contact from the participant’s contacts list?
//           </Typography>
//           <Typography component='div' variant='body2' color={variables.colors.text.secondary} sx={{ fontStyle: 'italic' }}>
//             <Box component='span' sx={{ fontWeight: 700 }} color={variables.colors.text.secondary}>Note: </Box>this action does not delete the contact record from the system, it only removes the link with the participant.
//           </Typography>
//         </Stack>
//       </Stack>
//       <ButtonStack>
//         <Button variant='outlined' onClick={closeModal}>Cancel</Button>
//         <Button variant='contained' color='error' onClick={handleUnlink}>Unlink</Button>
//       </ButtonStack>
//     </Stack>
//   );
// };

const ButtonStack = styled(Stack)`
  flex-direction: row;
  width: 100%;
  padding: 16px 32px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 0px 0px 12px 12px;
  background: #F9FAFB;
  box-sizing: border-box;
`;

const PageHeaderStack = styled(Stack)`
  display: flex;
  width: 100%;
  padding: 32px 24px;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  gap: 8px;
`;

const PageHeaderDetails = styled(Stack)`
 flex-direction: row;
  gap: 8px;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  position: relative;
`;

const ContactDetailsStack = styled(Stack)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
`;

export default ViewServicerProviderContact;