import { FullName, Manager, User } from '../../types/dbSchema/userProfiles';
import { UserPosition } from '../../types/dbSchema/userPositions';
import { GeneralNote } from 'types/dbSchema/generalNotes';
import { ProductAndService, Rate } from 'types/dbSchema/productAndServices';
import { ServiceRegion } from 'types/dbSchema/serviceRegions';
import { SchedulingSupport } from 'types/dbSchema/schedulingSupports';
import { ProgressNote } from 'types/dbSchema/progressNotes';
import { ServiceProvider } from 'types/dbSchema/serviceProviders';
import { ServiceProviderNote } from 'types/dbSchema/serviceProviderNotes';
import { ParticipantGoal } from 'types/dbSchema/participantGoals';
import { ServiceType, ShiftLocation } from 'types/dbSchema/schedulingSettings';
import { EmployeeNote, ServiceTeamLeave, SkillQualification } from 'types/dbSchema/serviceTeam';
import { AvailabilityTemplate, LeaveType } from 'types/dbSchema/availabilitySettings';
import { Service } from 'types/dbSchema/services';
import { Participant } from 'types/dbSchema/participants';
import { Tag } from 'types/dbSchema/serviceTeamSettings';
import { PrimaryDisability } from 'types/dbSchema/participantSettings';
import { ParticipantNotificationSettings, PushNotificationSettings, SchedulingNotificationSettings, TemplateNotificationSettings } from 'types/dbSchema/notificationSettings';
import { BudgetNote, PlanBudget, ServiceContract } from 'types/dbSchema/serviceContracts';

// TODO: DELETE THIS FILE AFTER APIS ARE READY
// This will be replace with a GET all api call for service types

export const serviceTypesMasterList = [
  {
    id: '1',
    name: 'Cleaning',
    color: '#09E338',
    claimType: 'STAND',
    isActive: true
  },
  {
    id: '2',
    name: 'Cooking',
    color: '#FB8A8A',
    claimType: 'STAND',
    isActive: true
  },
  {
    id: '3',
    name: 'Rehab Support',
    color: '#FF6347',
    claimType: 'STAND',
    isActive: true
  },
  {
    id: '4',
    name: 'Medication Administration',
    color: '#FF69B4',
    claimType: 'STAND',
    isActive: true
  },
  {
    id: '5',
    name: 'Wound Care',
    color: '#FACB22',
    claimType: 'STAND',
    isActive: true
  },
  {
    id: '6',
    name: 'Personal Care',
    color: '#7875E6',
    claimType: 'STAND',
    isActive: true
  },
  {
    id: '7',
    name: 'Companionship',
    color: '#AA42E9',
    claimType: 'STAND',
    isActive: true
  },
  {
    id: '8',
    name: 'Counseling',
    color: '#FFA500',
    claimType: 'STAND',
    isActive: true
  },
  {
    id: '9',
    name: 'Social Services',
    color: '#09E338',
    claimType: 'STAND',
    isActive: true
  },
  {
    id: '10',
    name: 'Assistive Technology',
    color: '#F4982C',
    claimType: 'STAND',
    isActive: true
  },
  {
    id: '11',
    name: 'Home Modifications',
    color: '#AA42E9',
    claimType: 'STAND',
    isActive: true
  },
  {
    id: '12',
    name: 'Speech Evaluation',
    color: '#D90BC5',
    claimType: 'STAND',
    isActive: true
  },
  {
    id: '13',
    name: 'Language Therapy',
    color: '#FB8A8A',
    claimType: 'STAND',
    isActive: true
  }
] as ServiceType[];

export const dummyUserPositionData = [
  {
    id: '1',
    name: 'Physiotherapist',
    description: '',
    serviceTypesDelivered: [
      {
        name: 'Rehab Support',
        color: '#FF6347'
      },
      {
        name: 'Assistive Technology',
        color: '#F4982C'
      }
    ],
    usersCount: 5,
    isActive: true
  },
  {
    id: '2',
    name: 'Nurse',
    description: '',
    serviceTypesDelivered: [
      {
        name: 'Medication Administration',
        color: '#FF69B4'
      },
      {
        name: 'Wound Care',
        color: '#FACB22'
      }
    ],
    usersCount: 5,
    isActive: true
  },
  {
    id: '3',
    name: 'Caregiver',
    description: '',
    serviceTypesDelivered: [
      {
        name: 'Personal Care',
        color: '#FF8C00'
      },
      {
        name: 'Companionship',
        color: '#AA42E9'
      }
    ],
    usersCount: 23,
    isActive: true
  },
  {
    id: '4',
    name: 'Social Worker',
    description: '',
    serviceTypesDelivered: [
      {
        name: 'Counseling',
        color: '#FFA500'
      },
      {
        name: 'Social Services',
        color: '#09E338'
      },
      {
        name: 'Personal Care',
        color: '#FF8C00'
      },
      {
        name: 'Companionship',
        color: '#AA42E9'
      },
      {
        name: 'Assistive Technology',
        color: '#F4982C'
      },
      {
        name: 'Home Modifications',
        color: '#AA42E9'
      }
    ],
    usersCount: 14,
    isActive: true
  },
  {
    id: '5',
    name: 'Occupational Therapist',
    description: '',
    serviceTypesDelivered: [
      {
        name: 'Assistive Technology',
        color: '#F4982C'
      },
      {
        name: 'Home Modifications',
        color: '#AA42E9'
      }
    ],
    usersCount: 5,
    isActive: false
  },
  {
    id: '6',
    name: 'Speech Therapist',
    description: '',
    serviceTypesDelivered: [
      {
        name: 'Speech Evaluation',
        color: '#D90BC5'
      },
      {
        name: 'Language Therapy',
        color: '#FB8A8A'
      }
    ],
    usersCount: 61,
    isActive: true
  }
] as UserPosition[];

export const dummyUserProfileDataNEW = [{
  id: "12345",
  profilePicture: "https://via.placeholder.com/150",
  salutation: "Mr",
  fullName: {
    firstName: "John",
    middleName: "Doe",
    lastName: "Smith"
  },
  pronoun: "HeHim",
  phone: "0412345678",
  primaryMobile: "0400000000",
  workEmail: "john.smith@example.com",
  personalEmail: "john.personal@example.com",
  dob: "2024-07-02T02:52:51.560Z",
  gender: "Male",
  address: {
    id: "address_id",
    streetAddress: "123 Main St",
    suburb: "Suburb",
    state: "State",
    postCode: "1345",
    country: "Country",
    latitude: 37.7749,
    longitude: -122.4194,
    isPrimary: true
  },
  employmentStartDate: "2024-07-02T02:52:51.560Z",
  employeeNo: "EMP12345",
  serviceRegion: {
    id: '1',
    name: 'Region 1',
    isActive: true,
    pricingRegion: 'QLD/NSW/VIC',
  },
  manager: {
    id: "manager_id",
    fullName: "Manager Name",
  },
  employeeType: "Full-Time",
  nextOfKinFullName: "Jane Doe",
  nextOfKinMobile: "0400000000",
  nextOfKinPhone: "0400000000",
  nextOfKinRelationship: {
    id: "relationship_id",
    name: "Spouse",
    systemStatus: true,
    isActive: true
  },
  lastLoginTime: "2024-07-02T02:52:51.560Z",
  lastLoginTimeMobileApp: "2024-07-02T02:52:51.560Z",
  changeLog: [
    {
      date: "2024-07-02T02:52:51.560Z",
      details: "Updated profile picture"
    },
    {
      date: "2024-07-02T02:52:51.560Z",
      details: "Updated Name"
    }
  ],
  timezone: "+10",
  userRoles: [
    {
      id: "b690f1ee-7c54-4a01-90e6-d701748f0852",
      name: "Administrator",
      description: "Manage system settings"
    },
    {
      id: "026d5e56-c9ee-4e8f-b5d9-fe214384f49a",
      name: "Owner",
      description: "Owner Role"
    }
  ],
  mobileAppUserStatus: true,
  webAppUserStatus: true,
  position: {
    id: "position_id",
    name: "Owner",
    description: "Responsible for overseeing operations",
    serviceTypesDelivered: [
      {
        id: "service_type_id",
        name: "Service Type",
        claimType: "STAND",
        color: "#000000",
        isActive: true
      }
    ],
    usersCount: 10,
    isActive: true
  },
  status: "PendingInvite",
  // isActive: true
},
] as User[];

export const dummyDataServiceProviderNotes = [
  {
    id: '1',
    note: 'Note content with max 200 characters nono nono nono n Note content with max 200 characters nono non Note content with max 200 characters nono nono nono n Note content with max 200 characters nono nono',
    subject: 'Title with max 100 characters nonononon title with max 100 characters nonononono nonononono nonononono',
    noteType: 'Service Provider Note',
    attachments: [
      {
        id: "ece3619e-2e0d-4195-af4f-22c72c14b857",
        fileName: "TEST.txt",
        blobUrl: "https://stskymoaedev.blob.core.windows.net/e63874bf-5998-454b-9279-0ef63c9dff6c/participants/19608670-23bc-4296-a386-ae0f50451e3c/318f2372-8971-46c0-8a77-2af616d89d9b?sv=2024-08-04&se=2024-09-26T23%3A58%3A43Z&sr=b&sp=r&sig=aXMXC%2FX7ifreny1XGE8QRwLcxrdZQ5geXBonDTrO%2BRA%3D&filename=TEST.txt",
        fileSize: 1000,
        fileType: "text/plain",
        uploadDate: "2024-07-02T02:52:51.560Z",
      }
    ],
    createdBy: '1',
    createdDate: new Date('09/11/1988 12:00:00').toISOString(),
    lastModified: new Date('09/11/1988 12:00:00').toISOString(),
  }
] as ServiceProviderNote[];

export const dummyDataProgressNotesData = [
  {
    id: '1',
    note: 'Note content with max 200 characters nono nono nono n Note content with max 200 characters nono non Note content with max 200 characters nono nono nono n Note content with max 200 characters nono nono',
    noteType: 'General',
    score: 5,
    attachments: [
      {
        id: "ece3619e-2e0d-4195-af4f-22c72c14b857",
        fileName: "TEST.txt",
        blobUrl: "https://stskymoaedev.blob.core.windows.net/e63874bf-5998-454b-9279-0ef63c9dff6c/participants/19608670-23bc-4296-a386-ae0f50451e3c/318f2372-8971-46c0-8a77-2af616d89d9b?sv=2024-08-04&se=2024-09-26T23%3A58%3A43Z&sr=b&sp=r&sig=aXMXC%2FX7ifreny1XGE8QRwLcxrdZQ5geXBonDTrO%2BRA%3D&filename=TEST.txt",
        fileSize: 1000,
        fileType: "text/plain",
        uploadDate: "2024-07-02T02:52:51.560Z",
      }
    ],
    createdDate: new Date('09/11/1988 12:00:00').toISOString(),
    createdBy: '1',
  }
] as ProgressNote[];

export const dummyRates = [
  {
    id: '1',
    name: 'JAN01 NVQ 22',
    effectiveFrom: new Date('01/01/2022 12:00:00').toISOString(),
    unitOfMeasure: 'Hourly',
    rate: 10.20,
    priceGuideRegion: 'QLD/NSW/VIC',
  },
  {
    id: '2',
    name: 'JAN01 NVQ 22',
    effectiveFrom: new Date('09/11/1988 12:00:00').toISOString(),
    unitOfMeasure: 'Hourly',
    rate: 43.22,
    priceGuideRegion: 'NT/SA/WA/TAS',
  },
  {
    id: '3',
    name: 'JAN01 NVQ 22',
    effectiveFrom: new Date('09/11/1988 12:00:00').toISOString(),
    unitOfMeasure: 'Hourly',
    rate: 32.10,
    priceGuideRegion: 'Remote',
  },
  {
    id: '4',
    name: 'JAN01 NVQ 22',
    effectiveFrom: new Date('09/11/1988 12:00:00').toISOString(),
    unitOfMeasure: 'Hourly',
    rate: 12.50,
    priceGuideRegion: 'Very Remote',
  },
] as Rate[];

export const dummyProductAndServices = [
  {
    id: '1',
    pricingModel: '',
    productCode: '01_002_0107_1_1',
    name: 'Assistance With Self-Care Activities - Standard - Weekday Night',
    registrationGroupNumber: 0,
    registrationGroupName: 'test',
    supportCategoryNumberPRODA: 1,
    supportCategoryNumberPACE: 1,
    supportCategoryNamePRODA: 'Assistance with daily life (includes Supported Independent Living)',
    supportCategoryNamePACE: 'Assistance with Daily Life',
    unitOfMeasure: 'test',
    quote: 'No',
    nonFaceToFaceSupportProvision: 'test',
    providerTravel: 'test',
    shortNoticeCancellations: 'test',
    ndiaRequestedReports: 'test',
    irregularSILSupports: 'test',
    isActive: true,
    rates: dummyRates
  }
] as ProductAndService[];

export const dummyServiceRegions = [
  {
    id: '1',
    name: 'Region 1',
    isActive: true,
    pricingRegion: 'QLD/NSW/VIC',
  },
  {
    id: '2',
    name: 'Region 2',
    isActive: true,
    pricingRegion: 'NT/SA/WA/TAS',
  },
  {
    id: '3',
    name: 'Region 3',
    isActive: true,
    pricingRegion: 'Remote',
  },
  {
    id: '4',
    name: 'Region 4',
    isActive: true,
    pricingRegion: 'Very Remote',
  }
] as ServiceRegion[];

export const dummyDataSchedulingSupports = [
  {
    id: '1',
    service: 'Assistance With Self-Care Activities - Standard - Weekday Night',
    productCode: '01_002_0107_1_1',
    serviceTimeslot: 'Any Day',
    startTime: new Date('09/11/1988 12:00:00').toISOString(),
    endTime: new Date('09/11/1988 12:00:00').toISOString(),
    hours: 4,
    isActive: true,
  },
  {
    id: '2',
    service: 'Assistance With Self-Care Activities - Standard - Weekday Night',
    productCode: '01_002_0107_1_1',
    serviceTimeslot: 'Public Holiday',
    startTime: new Date('09/11/1988 12:00:00').toISOString(),
    endTime: new Date('09/11/1988 12:00:00').toISOString(),
    hours: 4,
    isActive: true,
  },
] as SchedulingSupport[];

export const dummyDataServiceProviders = [
  {
    id: '1',
    companyName: 'Company 1',
    abn: '12345678901',
    serviceTypes: [{
      name: 'Speech Evaluation',
      color: '#D90BC5',
      claimType: 'STAND',
      id: '12',
      isActive: true,
      isEditable: false
    }],
    phoneNumber: '1234567890',
    mobileNumber: '1234567890',
    email: 'test@test.com',
    website: 'www.test.com',
    serviceRegion: 'Region 1',
    address: {
      id: '1',
      streetAddress: '789 Circle Ave',
      suburb: 'Roundtown',
      state: 'Circlestate',
      postCode: '101112',
      country: 'Globeland',
      isPrimary: true,
    },
    isActive: true
  }
] as ServiceProvider[];

export const dummyDataParticipantGoals: ParticipantGoal[] = [
  {
    id: '1',
    goal: 'Participant wants to be able to walk unassisted',
    description: 'I really appreciate the insights and perspective shared in this article. It\'s definitely given me something to think about and has helped me see things from a different angle.Thank you for writing and sharing!',
    status: 'In Progress',
    reviewDate: '2024-07-02T02:52:51.560Z',
    goalType: 'Short-term',
    score: 2,
    created: {
      userID: '1',
      userFullName: {
        firstName: 'John',
        lastName: 'Doe',
      },
      date: '2024-07-02T02:52:51.560Z',
    },
    lastUpdated: {
      userID: '1',
      userFullName: {
        firstName: 'John',
        lastName: 'Doe',
      },
      date: '2024-07-02T02:52:51.560Z',
    },
    goalHistory: [
      {
        id: '1',
        comment: 'A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence in this spot, which was created for the bliss of souls like mine. I am so happy, my dear friend, so absorbed in the exquisite sense of mere tranquil existence, that I neglect my talents. I should be incapable of drawing a single stroke at the present moment; and yet I feel that I never was a greater artist than now.',
        createdBy: '1',
        createdDate: '2024-07-02T02:52:51.560Z',
        goalSnapshot: {
          id: '1',
          goal: 'Participant wants to be able to walk unassisted',
          description: 'Description for goal 1 - snapshot of at the time of review',
          status: 'Not Started',
          reviewDate: '2024-07-02T02:52:51.560Z',
          goalType: 'Short-term',
          score: 2,
          created: {
            userID: '1',
            userFullName: {
              firstName: 'John',
              lastName: 'Doe',
            },
            date: '2024-07-02T02:52:51.560Z',
          },
          lastUpdated: {
            userID: '1',
            userFullName: {
              firstName: 'John',
              lastName: 'Doe',
            },
            date: '2024-07-02T02:52:51.560Z',
          },
          goalHistory: [],
        },
        type: 'Goal Review',
      },
      {
        id: '2',
        comment: 'I really appreciate the insights and perspective shared in this article. It\'s definitely given me something to think about and has helped me see things from a different angle.Thank you for writing and sharing!',
        type: 'General Comment',
        createdBy: '1',
        createdDate: '2024-07-02T02:52:51.560Z',
        goalSnapshot: null,
      },
    ],
  },
];

export const placeholderSkillsData: SkillQualification[] = [
  {
    id: '1',
    tagCategory: 'General',
    tagName: {
      tagCategory: "General",
      id: "f9e83d5c-195c-4617-ad4f-08dcb6650f49",
      name: "Test Tag",
      isActive: true,
      isEditable: true
    },
    expiryDate: '2024-07-02T02:52:51.560Z',
    neverExpires: false,
    supportingDocuments: [],
    createdBy: '1',
    createdDate: '2024-07-02T02:52:51.560Z',
  },
  {
    id: '2',
    tagCategory: 'General',
    tagName: {
      tagCategory: "General",
      id: "d222e333-f444-5556-6667-777888999000",
      name: "Language",
      isActive: true,
      isEditable: false
    },
    expiryDate: '2025-07-02T02:52:51.560Z',
    neverExpires: false,
    supportingDocuments: [],
    createdBy: '1',
    createdDate: '2024-07-02T02:52:51.560Z',
  },
];

export const placeholderEmployeeNotes: EmployeeNote[] = [
  {
    id: '1',
    noteType: {
      description: "Notes related to progress",
      id: "a1b2c3d4-e5f6-7890-1234-56789abcdef0",
      name: "Progress Note",
      isActive: true,
      isEditable: false
    },
    subject: 'Subject of the note',
    attachments: [],
    createdBy: '1',
    createdDate: '2024-07-02T02:52:51.560Z',
    note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
  },
  {
    id: '2',
    noteType: {
      description: "Warnings and alerts",
      id: "b2c3d4e5-f678-9012-3456-789abcdef012",
      name: "Warning",
      isActive: true,
      isEditable: false
    },
    subject: 'Subject of the note',
    attachments: [],
    createdBy: '1',
    createdDate: '2024-07-02T02:52:51.560Z',
    note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
  },
];

export const placeholderLeaveData: ServiceTeamLeave[] = [
  {
    id: '1',
    leaveType: {
      id: '1',
      name: 'Annual Leave',
      color: '#a21545',
      description: 'Annual Leave Description',
      isActive: true,
      isEditable: false
    },
    startTime: '2024-07-02T02:52:51.560Z',
    endTime: '2024-07-02T02:52:51.560Z',
    isAllDay: true,
    notes: 'I have a head cold',
    attachments: [],
    reviewedBy: {
      userID: '1',
      userFullName: {
        firstName: 'John',
        lastName: 'Doe',
      },
      date: '2024-07-02T02:52:51.560Z',
    },
    status: 'Approved',
  },
  {
    id: '2',
    leaveType: {
      id: '2',
      name: 'RDO',
      color: '#ee5eae',
      description: 'Roster Day Off Description',
      isActive: true,
      isEditable: false
    },
    startTime: '2024-07-02T02:52:51.560Z',
    endTime: '2024-07-02T02:52:51.560Z',
    isAllDay: true,
    notes: 'I am going on a holiday',
    declinedReason: 'Not enough staff',
    reviewedBy: {
      userID: '1',
      userFullName: {
        firstName: 'John',
        lastName: 'Doe',
      },
      date: '2024-07-02T02:52:51.560Z',
    },
    status: 'Declined',
    attachments: [
      {
        id: "ece3619e-2e0d-4195-af4f-22c72c14b857",
        fileName: "TEST.txt",
        blobUrl: "https://stskymoaedev.blob.core.windows.net/e63874bf-5998-454b-9279-0ef63c9dff6c/participants/19608670-23bc-4296-a386-ae0f50451e3c/318f2372-8971-46c0-8a77-2af616d89d9b?sv=2024-08-04&se=2024-09-26T23%3A58%3A43Z&sr=b&sp=r&sig=aXMXC%2FX7ifreny1XGE8QRwLcxrdZQ5geXBonDTrO%2BRA%3D&filename=TEST.txt",
        fileSize: 1000,
        fileType: "txt",
        contentType: "text/plain",
        uploadDate: "2024-07-02T02:52:51.560Z",
      },
    ],
  }
];

export const placeholderHistoryLeaveData: ServiceTeamLeave[] = [
  {
    id: '3',
    leaveType: {
      id: '1',
      name: 'Annual Leave',
      color: '#a21545',
      description: 'Annual Leave Description',
      isActive: true,
      isEditable: false
    },
    startTime: '2024-07-02T02:52:51.560Z',
    endTime: '2024-07-02T02:52:51.560Z',
    isAllDay: true,
    notes: 'I am sick',
    attachments: [],
    reviewedBy: {
      userID: '1',
      userFullName: {
        firstName: 'John',
        lastName: 'Doe',
      },
      date: '2023-07-02T02:52:51.560Z',
    },
    status: 'Approved',
  },
];

export const dummyLeaveTypes: LeaveType[] = [
  {
    id: '1',
    name: 'Annual Leave',
    color: '#ffcccc',
    description: 'Annual Leave Description',
    isActive: true,
    isEditable: false
  },
  {
    id: '2',
    name: 'RDO',
    color: '#ff9999',
    description: 'Roster Day Off Description',
    isActive: true,
    isEditable: false
  },
];

const getNextDayOfWeek = (dayOfWeek: number) => {
  const today = new Date();
  const resultDate = new Date(today);
  resultDate.setDate(today.getDate() + ((7 + dayOfWeek - today.getDay()) % 7 || 7));
  return resultDate.toISOString();
};

export const dummyAvailabilitiesTemplates: AvailabilityTemplate[] = [
  {
    id: '1',
    name: 'Standard Availability',
    description: 'Standard Availability Description',
    isActive: true,
    availabilities: [
      {
        id: '1',
        title: 'Available',
        startTime: '08:00:00',
        endTime: '16:00:00',
        day: 'Monday',
        backgroundColor: '#4CAF50', // Optional styling
      },
      {
        id: '2',
        title: 'Available',
        startTime: '08:00:00',
        endTime: '16:00:00',
        day: 'Tuesday',
        backgroundColor: '#4CAF50', // Optional styling
      },
      {
        id: '3',
        title: 'Available',
        startTime: '08:00:00',
        endTime: '16:00:00',
        day: 'Wednesday',
        backgroundColor: '#4CAF50', // Optional styling
      },
      {
        id: '4',
        title: 'Available',
        startTime: '08:00:00',
        endTime: '16:00:00',
        day: 'Thursday',
        backgroundColor: '#4CAF50', // Optional styling
      },
      {
        id: '5',
        title: 'Available',
        startTime: '08:00:00',
        endTime: '16:00:00',
        day: 'Friday',
        backgroundColor: '#4CAF50', // Optional styling
      },
    ]
  },
  {
    id: '2',
    name: 'Night Time Sleepover Work Week',
    description: 'Night Time Sleepover Work Week Description',
    isActive: true,
    availabilities: [
      {
        id: '1',
        title: 'Available',
        startTime: '00:00:00',
        endTime: '06:00:00',
        day: 'Monday',
        backgroundColor: '#4CAF50', // Optional styling
      },
      {
        id: '2',
        title: 'Available',
        startTime: '22:00:00',
        endTime: '23:59:59',
        day: 'Monday',
        backgroundColor: '#4CAF50', // Optional styling
      },
      {
        id: '3',
        title: 'Available',
        startTime: '00:00:00',
        endTime: '06:00:00',
        day: 'Tuesday',
        backgroundColor: '#4CAF50', // Optional styling
      },
      {
        id: '4',
        title: 'Available',
        startTime: '22:00:00',
        endTime: '23:59:59',
        day: 'Tuesday',
        backgroundColor: '#4CAF50', // Optional styling
      },
      {
        id: '5',
        title: 'Available',
        startTime: '00:00:00',
        endTime: '06:00:00',
        day: 'Wednesday',
        backgroundColor: '#4CAF50', // Optional styling
      },
      {
        id: '6',
        title: 'Available',
        startTime: '22:00:00',
        endTime: '23:59:59',
        day: 'Wednesday',
        backgroundColor: '#4CAF50', // Optional styling
      },
      {
        id: '7',
        title: 'Available',
        startTime: '00:00:00',
        endTime: '06:00:00',
        day: 'Thursday',
        backgroundColor: '#4CAF50', // Optional styling
      },
      {
        id: '8',
        title: 'Available',
        startTime: '22:00:00',
        endTime: '23:59:59',
        day: 'Thursday',
        backgroundColor: '#4CAF50', // Optional styling
      },
      {
        id: '9',
        title: 'Available',
        startTime: '00:00:00',
        endTime: '06:00:00',
        day: 'Friday',
        backgroundColor: '#4CAF50', // Optional styling
      },
      {
        id: '10',
        title: 'Available',
        startTime: '22:00:00',
        endTime: '23:59:59',
        day: 'Friday',
        backgroundColor: '#4CAF50', // Optional styling
      },
      {
        id: '11',
        title: 'Available',
        startTime: '00:00:00',
        endTime: '06:00:00',
        day: 'Saturday',
        backgroundColor: '#4CAF50', // Optional styling
      },
      {
        id: '12',
        title: 'Available',
        startTime: '22:00:00',
        endTime: '23:59:59',
        day: 'Saturday',
        backgroundColor: '#4CAF50', // Optional styling
      },
    ]
  }
];

export const placeholderUnallocatedServiceData = [
  {
    id: "7e2ae64c-392c-4402-a2ae-ee885da00fb2",
    serviceTypes: [{
      id: "a8a110d5-1a2b-4c99-8c8f-bb5fb4d1e0b2",
      name: "Physical Therapy",
      claimType: "STAND",
      color: "#FFB6C1",
      isActive: true
    }],
    scheduledStart: "2024-09-12T23:13:31Z",
    scheduledEnd: "2024-09-14T23:13:31Z",
    uniqueFromSeries: false,
    shifts: null,
    serviceNote: "General cleaning",
    shiftLocation: "Building A",
    status: "Unallocated",
    recurringSeries: null,
    participant: {
      id: "d9f82705-cbd5-46d6-b0af-f2f438a07842",
      profilePicture: "https://via.placeholder.com/150",
      salutation: "Mr",
      fullName: {
        firstName: "Participant",
        middleName: "test",
        lastName: "One"
      },
      preferredName: "Pat",
      gender: "Female",
      pronoun: "TheyThem",
      dateOfBirth: "2024-08-31T00:00:00",
      aboriginalOrTorresStraitIslander: "TorresStraitIslander",
      mobile: "0400000000",
      phone: "0400000000",
      email: "test@test.com",
      additionalPersonalEmail: "test@test.com",
      referredBy: "test",
      assignedTo: {
        id: "fb7100f4-b415-4d46-b94d-0f244111c004",
        fullName: "steven hale"
      },
      homeAddress: {
        streetAddress: "610/1 High St",
        suburb: "Sippy Downs",
        state: "QLD",
        postCode: "4556",
        country: "Australia",
        latitude: -26.7155327,
        longitude: 153.0562432,
        isPrimary: true
      },
      otherAddress: {
        streetAddress: "610/1 High St",
        suburb: "Sippy Downs",
        state: "QLD",
        postCode: "4556",
        country: "Australia",
        latitude: -26.7155327,
        longitude: 153.0562432,
        isPrimary: false
      },
      primaryLanguage: "english",
      otherLanguage: "english",
      interpreterRequired: true,
      primaryDisability: {
        id: "44444444-5555-6666-7777-88889999aaaa",
        name: "ADHD",
        isActive: true,
        organisationId: null,
        isEditable: false
      } as PrimaryDisability,
      otherDisability: "test",
      culturalRequirements: "test",
      allergies: "test",
      livingArrangements: "test",
      interests: "test",
      servicePreferences: [
        {
          id: "3f7b0adc-1bee-4f4b-a2d1-ba94e3ee16b6",
          tagCategory: "Care",
          name: "Preferred Carer"
        },
        {
          id: "50f4cf4e-3a27-4168-a353-8adb463ae4c3",
          tagCategory: "General",
          name: "Language"
        }
      ] as Tag[],
      ndisNumber: "123456789",
      fundingProgram: "NDIS",
      ndisSystem: "PRODA",
      goals: [],
      serviceRegion: "Region 1",
      status: {
        color: "#69C64F4D",
        fontColor: "#356328",
        id: "a111b222-c333-d444-e555-666777788899",
        description: "Active",
        name: "Active",
        isActive: true,
        organisationId: null,
        isEditable: false
      },
      isActive: true,
      changeLog: [],
    } as Participant
  },
  {
    id: "5a9d465a-8071-4f6b-adde-87eb1ec44a17",
    seriesId: "0c51df46-60ab-4e8e-818a-490401809550",
    serviceTypes: [
      {
        id: "a8a110d5-1a2b-4c99-8c8f-bb5fb4d1e0b2",
        name: "Physical Therapy",
        claimType: "STAND",
        color: "#FFB6C1",
        isActive: true
      },
      {
        id: "b9b210e6-2b3c-5d88-9d9f-cc6fc5e2f1c3",
        name: "In Home Care",
        claimType: "STAND",
        color: "#E6E6FA",
        isActive: true
      },
      {
        id: "caca21f7-3c4d-6e77-a8a8-dd7fd7f3e2d4",
        name: "Hoisting",
        claimType: "STAND",
        color: "#2F4F4F",
        isActive: false
      }
    ],
    scheduledStart: "2024-09-14T23:13:31Z",
    scheduledEnd: "2024-09-16T23:13:31Z",
    uniqueFromSeries: false,
    shifts: ["Shift1", "Shift2"],
    serviceNote: "Night security",
    shiftLocation: "Building B",
    status: "Partially Allocated",
    recurringSeries: {
      id: "0c51df46-60ab-4e8e-818a-490401809550",
      scheduledStart: "2024-09-14T23:13:31Z",
      scheduledEnd: "2024-09-16T23:13:31Z",
      shiftDuration: 2,
      daysOfWeek: [1, 3, 5],
      interval: 1,
      intervalType: "week",
      endDate: "2024-10-03T23:13:31Z",
      exclusionDates: ["2024-09-21T23:13:31Z"]
    },
    participant: {
      id: "d9f82705-cbd5-46d6-b0af-f2f438a07842",
      profilePicture: "https://via.placeholder.com/150",
      salutation: "Mr",
      fullName: {
        firstName: "Participant",
        middleName: "test",
        lastName: "One"
      },
      preferredName: "Pat",
      gender: "Female",
      pronoun: "TheyThem",
      dateOfBirth: "2024-08-31T00:00:00",
      aboriginalOrTorresStraitIslander: "TorresStraitIslander",
      mobile: "0400000000",
      phone: "0400000000",
      email: "test@test.com",
      additionalPersonalEmail: "test@test.com",
      referredBy: "test",
      assignedTo: {
        id: "fb7100f4-b415-4d46-b94d-0f244111c004",
        fullName: "steven hale"
      },
      homeAddress: {
        streetAddress: "610/1 High St",
        suburb: "Sippy Downs",
        state: "QLD",
        postCode: "4556",
        country: "Australia",
        latitude: -26.7155327,
        longitude: 153.0562432,
        isPrimary: true
      },
      otherAddress: {
        streetAddress: "610/1 High St",
        suburb: "Sippy Downs",
        state: "QLD",
        postCode: "4556",
        country: "Australia",
        latitude: -26.7155327,
        longitude: 153.0562432,
        isPrimary: false
      },
      primaryLanguage: "english",
      otherLanguage: "english",
      interpreterRequired: true,
      primaryDisability: {
        id: "44444444-5555-6666-7777-88889999aaaa",
        name: "ADHD",
        isActive: true,
        organisationId: null,
        isEditable: false
      } as PrimaryDisability,
      otherDisability: "test",
      culturalRequirements: "test",
      allergies: "test",
      livingArrangements: "test",
      interests: "test",
      servicePreferences: [
        {
          id: "3f7b0adc-1bee-4f4b-a2d1-ba94e3ee16b6",
          tagCategory: "Care",
          name: "Preferred Carer"
        },
        {
          id: "50f4cf4e-3a27-4168-a353-8adb463ae4c3",
          tagCategory: "General",
          name: "Language"
        }
      ] as Tag[],
      ndisNumber: "123456789",
      fundingProgram: "NDIS",
      ndisSystem: "PRODA",
      goals: [],
      serviceRegion: "Region 1",
      status: {
        color: "#69C64F4D",
        fontColor: "#356328",
        id: "a111b222-c333-d444-e555-666777788899",
        description: "Active",
        name: "Active",
        isActive: true,
        organisationId: null,
        isEditable: false
      },
      isActive: true,
      changeLog: [],
    } as Participant
  },
  {
    id: "19882f82-aa42-47c4-98a9-6caac443559c",
    seriesId: "0c51df46-60ab-4e8e-818a-490401809550",
    serviceTypes: [
      {
        id: "a8a110d5-1a2b-4c99-8c8f-bb5fb4d1e0b2",
        name: "Physical Therapy",
        claimType: "STAND",
        color: "#FFB6C1",
        isActive: true
      },
      {
        id: "b9b210e6-2b3c-5d88-9d9f-cc6fc5e2f1c3",
        name: "In Home Care",
        claimType: "STAND",
        color: "#E6E6FA",
        isActive: true
      },
      {
        id: "caca21f7-3c4d-6e77-a8a8-dd7fd7f3e2d4",
        name: "Hoisting",
        claimType: "STAND",
        color: "#2F4F4F",
        isActive: false
      }
    ] as ServiceType[],
    scheduledStart: "2024-09-18T23:13:31Z",
    scheduledEnd: "2024-09-20T23:13:31Z",
    uniqueFromSeries: false,
    shifts: ["Shift3", "Shift4"],
    serviceNote: "Night security",
    shiftLocation: "Building B",
    status: "Unallocated",
    participant: {
      id: "d9f82705-cbd5-46d6-b0af-f2f438a07842",
      profilePicture: "https://via.placeholder.com/150",
      salutation: "Mr",
      fullName: {
        firstName: "Participant",
        middleName: "test",
        lastName: "One"
      },
      preferredName: "Pat",
      gender: "Female",
      pronoun: "TheyThem",
      dateOfBirth: "2024-08-31T00:00:00",
      aboriginalOrTorresStraitIslander: "TorresStraitIslander",
      mobile: "0400000000",
      phone: "0400000000",
      email: "test@test.com",
      additionalPersonalEmail: "test@test.com",
      referredBy: "test",
      assignedTo: {
        id: "fb7100f4-b415-4d46-b94d-0f244111c004",
        fullName: "steven hale"
      } as Manager,
      homeAddress: {
        streetAddress: "610/1 High St",
        suburb: "Sippy Downs",
        state: "QLD",
        postCode: "4556",
        country: "Australia",
        latitude: -26.7155327,
        longitude: 153.0562432,
        isPrimary: true
      },
      otherAddress: {
        streetAddress: "610/1 High St",
        suburb: "Sippy Downs",
        state: "QLD",
        postCode: "4556",
        country: "Australia",
        latitude: -26.7155327,
        longitude: 153.0562432,
        isPrimary: false
      },
      primaryLanguage: "english",
      otherLanguage: "english",
      interpreterRequired: true,
      primaryDisability: {
        id: "44444444-5555-6666-7777-88889999aaaa",
        name: "ADHD",
        isActive: true,
        organisationId: null,
        isEditable: false
      } as PrimaryDisability,
      otherDisability: "test",
      culturalRequirements: "test",
      allergies: "test",
      livingArrangements: "test",
      interests: "test",
      servicePreferences: [
        {
          id: "3f7b0adc-1bee-4f4b-a2d1-ba94e3ee16b6",
          tagCategory: "Care",
          name: "Preferred Carer"
        },
        {
          id: "50f4cf4e-3a27-4168-a353-8adb463ae4c3",
          tagCategory: "General",
          name: "Language"
        }
      ] as Tag[],
      ndisNumber: "123456789",
      fundingProgram: "NDIS",
      ndisSystem: "PRODA",
      goals: [],
      serviceRegion: "Region 1",
      status: {
        color: "#69C64F4D",
        fontColor: "#356328",
        id: "a111b222-c333-d444-e555-666777788899",
        description: "Active",
        name: "Active",
        isActive: true,
        organisationId: null,
        isEditable: false
      },
      isActive: true,
      changeLog: [],
    } as Participant
  },
  {
    id: "3347b51f-8a8a-4e9c-83b0-4877a7118500",
    seriesId: "0c51df46-60ab-4e8e-818a-490401809550",
    serviceTypes: [
      {
        id: "a8a110d5-1a2b-4c99-8c8f-bb5fb4d1e0b2",
        name: "Physical Therapy",
        claimType: "STAND",
        color: "#FFB6C1",
        isActive: true
      },
      {
        id: "b9b210e6-2b3c-5d88-9d9f-cc6fc5e2f1c3",
        name: "In Home Care",
        claimType: "STAND",
        color: "#E6E6FA",
        isActive: true
      },
      {
        id: "caca21f7-3c4d-6e77-a8a8-dd7fd7f3e2d4",
        name: "Hoisting",
        claimType: "STAND",
        color: "#2F4F4F",
        isActive: false
      }
    ] as ServiceType[],
    scheduledStart: "2024-09-25T23:13:31Z",
    scheduledEnd: "2024-09-27T23:13:31Z",
    uniqueFromSeries: false,
    shifts: ["Shift5", "Shift6"],
    serviceNote: "Night security",
    shiftLocation: "Building B",
    status: "Unallocated",
    participant: {
      id: "d9f82705-cbd5-46d6-b0af-f2f438a07842",
      profilePicture: "https://via.placeholder.com/150",
      salutation: "Mr",
      fullName: {
        firstName: "Participant",
        middleName: "test",
        lastName: "One"
      },
      preferredName: "Pat",
      gender: "Female",
      pronoun: "TheyThem",
      dateOfBirth: "2024-08-31T00:00:00",
      aboriginalOrTorresStraitIslander: "TorresStraitIslander",
      mobile: "0400000000",
      phone: "0400000000",
      email: "test@test.com",
      additionalPersonalEmail: "test@test.com",
      referredBy: "test",
      assignedTo: {
        id: "fb7100f4-b415-4d46-b94d-0f244111c004",
        fullName: "steven hale"
      },
      homeAddress: {
        streetAddress: "610/1 High St",
        suburb: "Sippy Downs",
        state: "QLD",
        postCode: "4556",
        country: "Australia",
        latitude: -26.7155327,
        longitude: 153.0562432,
        isPrimary: true
      },
      otherAddress: {
        streetAddress: "610/1 High St",
        suburb: "Sippy Downs",
        state: "QLD",
        postCode: "4556",
        country: "Australia",
        latitude: -26.7155327,
        longitude: 153.0562432,
        isPrimary: false
      },
      primaryLanguage: "english",
      otherLanguage: "english",
      interpreterRequired: true,
      primaryDisability: {
        id: "44444444-5555-6666-7777-88889999aaaa",
        name: "ADHD",
        isActive: true,
        organisationId: null,
        isEditable: false
      } as PrimaryDisability,
      otherDisability: "test",
      culturalRequirements: "test",
      allergies: "test",
      livingArrangements: "test",
      interests: "test",
      servicePreferences: [
        {
          id: "3f7b0adc-1bee-4f4b-a2d1-ba94e3ee16b6",
          tagCategory: "Care",
          name: "Preferred Carer"
        },
        {
          id: "50f4cf4e-3a27-4168-a353-8adb463ae4c3",
          tagCategory: "General",
          name: "Language"
        }
      ] as Tag[],
      ndisNumber: "123456789",
      fundingProgram: "NDIS",
      ndisSystem: "PRODA",
      goals: [],
      serviceRegion: "Region 1",
      status: {
        color: "#69C64F4D",
        fontColor: "#356328",
        id: "a111b222-c333-d444-e555-666777788899",
        description: "Active",
        name: "Active",
        isActive: true,
        organisationId: null,
        isEditable: false
      },
      isActive: true,
      changeLog: [],
    } as Participant
  }
] as Service[];

export const SchedulingNotificationsData = [
  {
    id: '1',
    notificationType: 'Unallocated Service - Scheduled for Today',
    frequency: 'Daily',
    daysToCheckOn: [2, 4],
    timeToCheck: '08:00:00',
    fromEmail: 'tes@test.com',
    toEmail: 'test@test.com',
    content: 'Test Content',
    auditInformation: [],
    lastRun: '2024-07-02T02:52:51.560',
    deliveryMethods: {
      email: false,
      sms: true,
      systemAlert: false,
    },
    isActive: true,
  }
] as SchedulingNotificationSettings[];

export const ParticipantNotificationsData = [
  {
    id: '1',
    notificationType: 'Schedule of Support - Ending in 30 Days',
    frequency: 'Daily',
    daysToCheckOn: [2, 4],
    timeToCheck: '08:00:00',
    fromEmail: 'tes@test.com',
    toEmail: 'test@test.com',
    content: 'Test Content',
    auditInformation: [],
    lastRun: '2024-07-02T02:52:51.560',
    deliveryMethods: {
      email: true,
      sms: true,
      systemAlert: false,
    },
    isActive: true,
  }
] as ParticipantNotificationSettings[];


export const PushNotificationsData = [
  {
    id: '1',
    notificationType: 'Change of Shift Location',
    content: 'Test Content',
    auditInformation: [],
    deliveryMethods: {
      email: false,
      systemAlert: false,
      sms: true,
    },
    isActive: true,
  }
] as PushNotificationSettings[];

export const TemplateNotificationsData = [
  {
    id: '1',
    notificationType: 'Participant Schedule',
    content: 'Test Content',
    auditInformation: [],
    isActive: true,
  }
] as TemplateNotificationSettings[];

export const dummyServiceContracts: ServiceContract[] = [
  {
    id: '1',
    deliveredAmount: 100,
    startingFunds: 1000,
    remainingAmount: 900,
    sosReferenceId: 'SC-000000014',
    startDate: '2024-07-02T02:52:51.560Z',
    endDate: '2024-07-02T02:52:51.560Z',
    isActive: true,
  }
];

export const dummyBudgetNotes: BudgetNote[] = [
  {
    id: '1',
    note: 'Test Note',
    attachments: [],
    createdBy: {
      fullName: 'John Doe',
      id: '1',
    },
    createdDate: '2024-07-02T02:52:51.560Z',
    isActive: true,
    noteType: {
      description: 'General Note',
      id: '1',
      isActive: true,
      isEditable: false,
      name: 'General Note',
    }
  }
];

export const dummyPlanBudgets: PlanBudget[] = [
  {
    id: '1',
    budgetCategory: 'General',
    deliveredAmount: 100,
    remainingAmount: 900,
    supportCategory: 'General',
    isActive: true,
  }
];