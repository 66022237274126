import React, { FC, useEffect } from 'react';
import ViewUserProfile from 'components/features/ViewUserProfile';
import { LinearProgress } from '@mui/material';
import { useFetchDataPoint } from 'hooks/useFetchRecordById';
import { getUserById } from 'api/organisations/users';

const ViewUserProfilePage: FC = () => {
  const { isLoading, currentDataPoint, hasError } = useFetchDataPoint({ getByIDFunc: getUserById });
  if (isLoading) return <LinearProgress />;
  if (hasError || !currentDataPoint) return null;

  return <ViewUserProfile userProfile={currentDataPoint} />;

};

export default ViewUserProfilePage;