import { IPages } from 'types';
import { Navigate } from 'react-router-dom';
import HomePage from 'components/pages/HomePage';
import LoginPage from 'components/pages/LoginPage';
import ReturnPage from 'components/pages/ReturnPage';
import PowerviewPage from 'components/pages/PowerviewPage';
import MyUserProfilePage from 'components/pages/MyUserProfilePage';
import OrganisationRegistrationPage from 'components/pages/OrganisationRegistrationPage';
// Participants
import ViewParticipantPage from 'components/pages/ParticipantCentre/Participants/ViewParticipantPage';
import CreateParticipantPage from 'components/pages/ParticipantCentre/Participants/CreateParticipantPage';
import ListParticipantsPage from 'components/pages/ParticipantCentre/Participants/ListParticipantsPage';
import EditParticipantPage from 'components/pages/ParticipantCentre/Participants/EditParticipantPage';
// User management
import ListUserProfilesPage from 'components/pages/Settings/UserManagement/UserProfiles/ListUserProfilesPage';
import CreateUserProfilePage from 'components/pages/Settings/UserManagement/UserProfiles/CreateUserProfilePage';
import ViewUserProfilePage from 'components/pages/Settings/UserManagement/UserProfiles/ViewUserProfilePage';
import EditUserProfilePage from 'components/pages/Settings/UserManagement/UserProfiles/EditUserProfilePage';
import UserPositionsPage from 'components/pages/Settings/UserManagement/UserPositionsPage';
import UserRolesPage from 'components/pages/Settings/UserManagement/UserRolesPage';
// Placeholder page
import PlaceholderPage from 'components/pages/PlaceholderPage';
// Settings
// import TaskSettings from 'components/pages/Settings/TaskSettingsPage'; // This page is partially completed (except for the APIs), it is not in the scope of MVP1
// import IncidentSettingsPage from 'components/pages/Settings/IncidentSettingsPage'; // This page is partially completed (except for the APIs), it is not in the scope of MVP1
import ServiceTeamSettingsPage from 'components/pages/Settings/ServiceTeamSettingsPage';
import SchedulingSettingsPage from 'components/pages/Settings/SchedulingSettingsPage';
import ParticipantSettingsPage from 'components/pages/Settings/ParticipantSettingsPage';
import ServiceProviderSettings from 'components/features/ServiceProviderSettings';
import ProductsAndServicesPage from 'components/pages/Resources/ProductsAndServicesPage';
import ViewProductAndService from 'components/features/ViewProductAndService';
import ServiceRegionsPage from 'components/pages/Resources/ServiceRegionsPage';
import OrganisationSettingsPage from 'components/pages/Settings/OrganisationSettings/OrganisationSettingsPage';
import EditOrganisationDetails from 'components/pages/Settings/OrganisationSettings/EditOrganisationDetails';
import ScheduleOfSupportsPage from 'components/pages/Scheduling/ScheduleOfSupportsPage';
import ParticipationSchedulePage from 'components/pages/Scheduling/ParticipationSchedulePage';
import StaffRosterPage from 'components/pages/Scheduling/StaffRosterPage';
import ShiftReleasesPage from 'components/pages/Scheduling/ShiftReleasesPage';
import ShiftApprovalPage from 'components/pages/Scheduling/ShiftApprovalPage';
import ServiceTeamPage from 'components/pages/Scheduling/ServiceTeamPage';
import NotificationsPage from 'components/pages/Scheduling/NotificationsPage';
import ServiceDashboardPage from 'components/pages/Scheduling/ServiceDashboardPage';
import SchedulingSupportsPage from 'components/pages/Scheduling/SchedulingSupportsPage';
import ListServiceProvidersPage from 'components/pages/Resources/ServiceProviders/ListServiceProvidersPage';
import ViewServiceProvidersPage from 'components/pages/Resources/ServiceProviders/ViewServiceProvidersPage';
import CreateServiceProvidersPage from 'components/pages/Resources/ServiceProviders/CreateServiceProvidersPage';
import EditServiceProvidersPage from 'components/pages/Resources/ServiceProviders/EditServiceProvidersPage';
import CreateParticipantGoalPage from 'components/pages/ParticipantCentre/Participants/ViewParticipantPage/Goals/CreateParticipantGoalPage';
import EditParticipantGoalPage from 'components/pages/ParticipantCentre/Participants/ViewParticipantPage/Goals/EditParticipantGoalPage';
import ViewParticipantGoalPage from 'components/pages/ParticipantCentre/Participants/ViewParticipantPage/Goals/ViewParticipantGoalPage';
import ReviewParticipantGoalPage from 'components/pages/ParticipantCentre/Participants/ViewParticipantPage/Goals/ReviewParticipantGoalPage';
import ListContactsPage from 'components/pages/ParticipantCentre/ContactsConsole/ListContactsPage';
import ViewParticipantContactPage from 'components/pages/ParticipantCentre/Participants/ViewParticipantPage/Contacts/ViewParticipantContactPage';
import ViewContactPage from 'components/pages/ParticipantCentre/ContactsConsole/ViewContactPage';
import LinkContactToParticipantPage from 'components/pages/ParticipantCentre/Participants/ViewParticipantPage/Contacts/LinkContactToParticipantPage';
import LinkParticipantToContactPage from 'components/pages/ParticipantCentre/ContactsConsole/LinkParticipantToContactPage';
import ViewServiceProviderContactPage from 'components/pages/Resources/ServiceProviders/ViewServiceProvidersPage/Contacts/ViewParticipantContactPage';
import ViewServiceTeamRosterPage from 'components/pages/Scheduling/ServiceTeamPage/ViewServiceTeamRosterPage';
import ViewServiceTeamLeavePage from 'components/pages/Scheduling/ServiceTeamPage/ViewServiceTeamLeavePage';
import ViewServiceTeamDetailsPage from 'components/pages/Scheduling/ServiceTeamPage/ViewServiceTeamDetailsPage';
import ViewServiceTeamSkillsQualificationsPage from 'components/pages/Scheduling/ServiceTeamPage/ViewServiceTeamSkillsQualificationsPage';
import ViewServiceTeamNotesPage from 'components/pages/Scheduling/ServiceTeamPage/ViewServiceTeamNotesPage';
import ViewServiceTeamAvailabilityPage from 'components/pages/Scheduling/ServiceTeamPage/ViewServiceTeamAvailabilityPage';
import CreateAvailabilityTemplatePage from 'components/pages/Settings/AvailabilitySettingsPage/CreateAvailabilityTemplatePage';
import EditAvailabilityTemplatePage from 'components/pages/Settings/AvailabilitySettingsPage/EditAvailabilityTemplatePage';
import AvailabilitySettingsPage from 'components/pages/Settings/AvailabilitySettingsPage';
import { permissions } from 'types/dbSchema/permissions';
import UnallocatedServicesPage from 'components/pages/Scheduling/UnallocatedServicesPage';
import ListNotificationSettingsPage from 'components/pages/Settings/NotificationSettings/ListNotificationSettingsPage';
import EditNotificationSettingsPage from 'components/pages/Settings/NotificationSettings/EditNotificationSettingsPage';
import ViewServiceContractsPage from 'components/pages/ParticipantCentre/Participants/ViewParticipantPage/ServiceContracts/ViewServiceContractsPage';
// Scheduling

// Below are lists of pages that will be rendered in the application - see src/App.tsx
// Each page has a "to" property which is the path that the page will be accessible at.
// The "pageComponent" property is a REFERENCE to a component that will be rendered when the path is accessed.
// The "props" property is an object that will be passed to the component when it is rendered.
// The "children" property is an array of pages that will be accessible under the current page.

// The uniquePages array contains pages that handle their own layout, authentication, routing, logic etc.
export const uniquePages: IPages[] = [
  {
    to: '/login',
    pageComponent: LoginPage,
    props: { label: 'Login' },
    requiredPermissions: [],
  },
  {
    to: '/return',
    pageComponent: ReturnPage,
    props: { label: 'Return' },
    requiredPermissions: [],
  },
];

// The pagesWithoutLayout array contains pages that do not follow the layout of the application, they are rendered inside ProtectedRoute.
export const pagesWithoutLayout: IPages[] = [
  {
    to: '/organisation-registration',
    pageComponent: OrganisationRegistrationPage,
    props: { label: 'Organisation Registration' },
    requiredPermissions: [],
  },
  {
    to: 'settings/user-management/user-profiles/create',
    pageComponent: CreateUserProfilePage,
    props: { label: 'Create' },
    requiredPermissions: [],
  },
  {
    to: 'settings/user-management/user-profiles/edit/:id',
    pageComponent: EditUserProfilePage,
    props: { label: 'EditUserProfile' },
    requiredPermissions: [],
  },
  {
    to: '/participant-centre/participants/create',
    pageComponent: CreateParticipantPage,
    props: { label: 'Participants' },
    requiredPermissions: [permissions.CREATE_PARTICIPANTS],
  },
  {
    to: '/participant-centre/participants/edit/:id',
    pageComponent: EditParticipantPage,
    props: { label: 'Participants' },
    requiredPermissions: [],
  },
  {
    to: '/participant-centre/participants/view/:id/goals/create',
    pageComponent: CreateParticipantGoalPage,
    props: { label: 'Participants' },
    requiredPermissions: [],
  },
  {
    to: '/participant-centre/participants/view/:id/goals/edit/:goalId',
    pageComponent: EditParticipantGoalPage,
    props: { label: 'Participants' },
    requiredPermissions: [],
  },
  {
    to: '/participant-centre/participants/view/:id/goals/review/:goalId',
    pageComponent: ReviewParticipantGoalPage,
    props: { label: 'Participants' },
    requiredPermissions: [],
  },
  {
    to: '/participant-centre/participants/view/:id/link-contact',
    pageComponent: LinkContactToParticipantPage,
    props: { label: 'Participants' },
    requiredPermissions: [],
  },
  {
    to: '/participant-centre/contacts-console/view/:id/link-participant',
    pageComponent: LinkParticipantToContactPage,
    props: { label: 'Participants' },
    requiredPermissions: [],
  },
  {
    to: '/scheduling/service-team/:id/availability',
    pageComponent: ViewServiceTeamAvailabilityPage,
    props: { label: 'Service Team' },
    requiredPermissions: [],
  },
  {
    to: '/settings/organisation/organisation-settings/edit',
    pageComponent: EditOrganisationDetails,
    props: { label: 'Organisation Settings' },
    requiredPermissions: [],
  },
  {
    to: '/resources/service-providers/create',
    pageComponent: CreateServiceProvidersPage,
    props: { label: 'Service Providers' },
    requiredPermissions: [],
  },
  {
    to: '/resources/service-providers/edit/:id',
    pageComponent: EditServiceProvidersPage,
    props: { label: 'Service Providers' },
    requiredPermissions: [],
  },
  {
    to: '/settings/notification-settings/edit/:id',
    pageComponent: EditNotificationSettingsPage,
    props: {
      label: 'Notification Settings'
    },
    requiredPermissions: [],
  },
];

// The pages array contains pages that will be rendered in the application with the layout, they are rendered inside ProtectedRoute.
export const pages: IPages[] = [
  {
    to: '/',
    pageComponent: HomePage,
    props: { label: 'Home' },
    requiredPermissions: [],
  },
  {
    to: '/powerview',
    pageComponent: PowerviewPage,
    props: { label: 'Powerview' },
    requiredPermissions: [],
  },
  // The base path does not have a component. Therefore we use the Navigate component to redirect to the first child of the page.
  {
    to: '/participant-centre',
    pageComponent: Navigate,
    props: {
      to: '/participant-centre/participants',
      replace: true
    },
    requiredPermissions: [permissions.VIEW_PARTICIPANT_CENTRE],
  },
  {
    to: '/participant-centre/participants',
    pageComponent: ListParticipantsPage,
    props: { label: 'Participants' },
    requiredPermissions: [permissions.VIEW_PARTICIPANT_CENTRE, permissions.VIEW_PARTICIPANTS],
  },
  {
    to: '/participant-centre/participants/view/:id',
    pageComponent: ViewParticipantPage,
    props: { label: 'Participants' },
    requiredPermissions: [],
  },
  {
    to: '/participant-centre/participants/:id/service-contracts/:serviceContractId',
    pageComponent: ViewServiceContractsPage,
    props: { label: 'Participants' },
    requiredPermissions: [],
  },
  {
    to: '/participant-centre/participants/view/:id/goals/view/:goalId',
    pageComponent: ViewParticipantGoalPage,
    props: { label: 'Participants' },
    requiredPermissions: [],
  },
  {
    to: '/participant-centre/participants/view/:id/contacts/:contactId',
    pageComponent: ViewParticipantContactPage,
    props: { label: 'Participants' },
    requiredPermissions: [],
  },
  {
    to: '/participant-centre/tasks',
    pageComponent: PlaceholderPage,
    props: { label: 'Tasks' },
    requiredPermissions: [],
  },
  {
    to: '/participant-centre/contacts-console',
    pageComponent: ListContactsPage,
    props: { label: 'Contacts' },
    requiredPermissions: [],
  },
  {
    to: '/participant-centre/contacts-console/view/:id',
    pageComponent: ViewContactPage,
    props: { label: 'View Contact' },
    requiredPermissions: [],
  },
  {
    to: '/participant-centre/incidents',
    pageComponent: PlaceholderPage,
    props: { label: 'Incidents' },
    requiredPermissions: [],
  },
  // The base path does not have a component. Therefore we use the Navigate component to redirect to the first child of the page.
  {
    to: '/scheduling',
    pageComponent: Navigate,
    props: {
      to: '/scheduling/schedule-of-supports',
      replace: true
    },
    requiredPermissions: [],
  },
  {
    to: '/scheduling/schedule-of-supports',
    pageComponent: ScheduleOfSupportsPage,
    props: { label: 'Schedule of Supports' },
    requiredPermissions: [],
  },
  {
    to: '/scheduling/participation-schedule',
    pageComponent: ParticipationSchedulePage,
    props: { label: 'Participation Schedule' },
    requiredPermissions: [],
  },
  {
    to: '/scheduling/staff-roster',
    pageComponent: StaffRosterPage,
    props: { label: 'Staff Roster' },
    requiredPermissions: [],
  },
  {
    to: '/scheduling/shift-releases',
    pageComponent: ShiftReleasesPage,
    props: { label: 'Shift Releases' },
    requiredPermissions: [],
  },
  {
    to: '/scheduling/shift-approval',
    pageComponent: ShiftApprovalPage,
    props: { label: 'Shift Approval' },
    requiredPermissions: [],
  },
  {
    to: '/scheduling/service-team',
    pageComponent: ServiceTeamPage,
    props: { label: 'Service Team' },
    requiredPermissions: [],
  },
  {
    to: '/scheduling/service-team/:id/roster',
    pageComponent: ViewServiceTeamRosterPage,
    props: { label: 'Service Team' },
    requiredPermissions: [],
  },
  {
    to: '/scheduling/service-team/:id/leave',
    pageComponent: ViewServiceTeamLeavePage,
    props: { label: 'Service Team' },
    requiredPermissions: [],
  },
  {
    to: '/scheduling/service-team/:id/employee-details',
    pageComponent: ViewServiceTeamDetailsPage,
    props: { label: 'Service Team' },
    requiredPermissions: [],
  },
  {
    to: '/scheduling/service-team/:id/skills-qualifications',
    pageComponent: ViewServiceTeamSkillsQualificationsPage,
    props: { label: 'Service Team' },
    requiredPermissions: [],
  },
  {
    to: '/scheduling/service-team/:id/employee-notes',
    pageComponent: ViewServiceTeamNotesPage,
    props: { label: 'Service Team' },
    requiredPermissions: [],
  },
  {
    to: '/scheduling/unallocated-services',
    pageComponent: UnallocatedServicesPage,
    props: { label: 'Unallocated Services' },
    requiredPermissions: [],
  },
  {
    to: '/scheduling/scheduling-supports',
    pageComponent: SchedulingSupportsPage,
    props: { label: 'Scheduling Supports' },
    requiredPermissions: [],
  },
  {
    to: '/scheduling/Notifications',
    pageComponent: NotificationsPage,
    props: { label: 'Notifications' },
    requiredPermissions: [],
  },
  {
    to: '/scheduling/service-dashboard',
    pageComponent: ServiceDashboardPage,
    props: { label: 'Service Dashboard' },
    requiredPermissions: [],
  },
  // The base path does not have a component. Therefore we use the Navigate component to redirect to the first child of the page.
  {
    to: '/billing',
    pageComponent: Navigate,
    props: {
      to: '/billing/service-ndis-claims',
      replace: true
    },
    requiredPermissions: [],
  },
  {
    to: '/billing/service-ndis-claims',
    pageComponent: PlaceholderPage,
    props: { label: 'Service NDIS Claims' },
    requiredPermissions: [],
  },
  {
    to: '/billing/service-invoices',
    pageComponent: PlaceholderPage,
    props: { label: 'Service Invoices' },
    requiredPermissions: [],
  },
  {
    to: '/billing/payroll',
    pageComponent: PlaceholderPage,
    props: { label: 'Payroll' },
    requiredPermissions: [],
  },
  // The base path does not have a component. Therefore we use the Navigate component to redirect to the first child of the page.
  {
    to: '/resources',
    pageComponent: Navigate,
    props: {
      to: '/resources/service-providers',
      replace: true
    },
    requiredPermissions: [],
  },
  {
    to: '/resources/service-providers',
    pageComponent: ListServiceProvidersPage,
    props: { label: 'Service Providers' },
    requiredPermissions: [],
  },
  {
    to: '/resources/service-providers/view/:id',
    pageComponent: ViewServiceProvidersPage,
    props: { label: 'Service Providers' },
    requiredPermissions: [],
  },
  {
    to: '/resources/service-providers/view/:id/contact/:contactId',
    pageComponent: ViewServiceProviderContactPage,
    props: { label: 'Service Provider Contact' },
    requiredPermissions: [],
  },
  {
    to: '/resources/service-regions',
    pageComponent: ServiceRegionsPage,
    props: { label: 'Service Regions' },
    requiredPermissions: [],
  },
  {
    to: '/resources/products-services',
    pageComponent: ProductsAndServicesPage,
    props: { label: 'Products & Services' },
    requiredPermissions: [],
  },
  {
    to: '/resources/products-services/view/:id',
    pageComponent: ViewProductAndService,
    props: { label: 'Products & Services' },
    requiredPermissions: [],
  },
  {
    to: '/downloads',
    pageComponent: PlaceholderPage,
    props: { label: 'Downloads' },
    requiredPermissions: [],
  },
  {
    to: '/user-profile',
    pageComponent: MyUserProfilePage,
    props: { label: 'My Profile' },
    requiredPermissions: [],
  },
  // The base path does not have a component. Therefore we use the Navigate component to redirect to the first child of the page.
  {
    to: '/help-centre',
    pageComponent: Navigate,
    props: {
      to: '/help-centre/help-articles',
      replace: true
    },
    requiredPermissions: [],
  },
  {
    to: '/help-centre/help-articles',
    pageComponent: PlaceholderPage,
    props: { label: 'Help Articles' },
    requiredPermissions: [],
  },
  {
    to: '/help-centre/support-requests',
    pageComponent: PlaceholderPage,
    props: { label: 'Support Requests' },
    requiredPermissions: [],
  },
  {
    to: '/notification-alerts',
    pageComponent: PlaceholderPage,
    props: { label: 'Notifications Alerts' },
    requiredPermissions: [],
  },
  // The base path does not have a component. Therefore we use the Navigate component to redirect to the first child of the page.
  {
    to: '/organisation',
    pageComponent: Navigate,
    props: {
      to: '/settings/organisation/organisation-settings',
      replace: true
    },
    requiredPermissions: [],
  },
  {
    to: '/settings/organisation/organisation-settings',
    pageComponent: OrganisationSettingsPage,
    props: { label: 'Organisation Settings' },
    requiredPermissions: [],
  },
  {
    to: '/settings/organisation/credit-card-details',
    pageComponent: PlaceholderPage,
    props: { label: 'Credit Card Details' },
    requiredPermissions: [],
  },
  {
    to: '/settings/organisation/payment-history',
    pageComponent: PlaceholderPage,
    props: { label: 'Payment History' },
    requiredPermissions: [],
  },
  {
    to: '/settings/organisation/subscription-plan',
    pageComponent: PlaceholderPage,
    props: { label: 'Subscription Plan' },
    requiredPermissions: [],
  },
  // The base path does not have a component. Therefore we use the Navigate component to redirect to the first child of the page.
  {
    to: '/settings/user-management',
    pageComponent: Navigate,
    props: {
      to: '/settings/user-management/user-profiles',
      replace: true
    },
    requiredPermissions: [],
  },
  {
    to: '/settings/user-management/user-profiles',
    pageComponent: ListUserProfilesPage,
    props: { label: 'Users Profiles' },
    requiredPermissions: [],
  },
  {
    to: '/settings/user-management/user-profiles/view/:id',
    pageComponent: ViewUserProfilePage,
    props: { label: 'View' },
    requiredPermissions: [],
  },
  {
    to: '/settings/user-management/user-positions',
    pageComponent: UserPositionsPage,
    props: { label: 'User Positions' },
    requiredPermissions: [],
  },
  {
    to: '/settings/user-management/user-roles',
    pageComponent: UserRolesPage,
    props: { label: 'User Roles' },
    requiredPermissions: [],
  },
  {
    to: '/settings/participant',
    pageComponent: ParticipantSettingsPage,
    props: { label: 'participant-settings' },
    requiredPermissions: [],
  },
  {
    to: '/settings/scheduling-settings',
    pageComponent: SchedulingSettingsPage,
    props: { label: 'scheduling-settings' },
    requiredPermissions: [],
  },
  {
    to: '/settings/service-team-settings',
    pageComponent: ServiceTeamSettingsPage,
    props: { label: 'service-team-settings' },
    requiredPermissions: [],
  },
  {
    to: '/settings/availability-settings',
    pageComponent: AvailabilitySettingsPage,
    props: { label: 'availability-settings' },
    requiredPermissions: [],
  },
  {
    to: '/settings/availability-settings/create',
    pageComponent: CreateAvailabilityTemplatePage,
    props: { label: 'availability-settings' },
    requiredPermissions: [],
  },
  {
    to: '/settings/availability-settings/edit/:id',
    pageComponent: EditAvailabilityTemplatePage,
    props: { label: 'availability-settings' },
    requiredPermissions: [],
  },
  // This page is partially completed (except for the APIs), it is not in the scope of MVP1
  // {
  //   to: '/settings/task-settings',
  //   pageComponent: TaskSettings,
  //   props: { label: 'task-settings' },
  // requiredPermissions: [],
  // },
  // This page is partially completed (except for the APIs), it is not in the scope of MVP1
  // {
  //   to: '/settings/incident-settings',
  //   pageComponent: IncidentSettingsPage,
  //   props: { label: '/settings/incident-settings' },
  // requiredPermissions: [],
  // },
  //
  {
    to: '/settings/provider-settings',
    pageComponent: ServiceProviderSettings,
    props: { label: 'provider-settings' },
    requiredPermissions: [],
  },
  {
    to: '/settings/notification-settings',
    pageComponent: ListNotificationSettingsPage,
    props: {
      label: 'Notification Settings'
    },
    requiredPermissions: [],
  },
  {
    to: '/settings/integration',
    pageComponent: PlaceholderPage,
    props: { label: 'Integration' },
    requiredPermissions: [],
  },
];