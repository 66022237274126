import React from 'react';
import CreateEditAvailabilityTemplate from 'components/features/AvailabilitySettings/CreateEditAvailabilityTemplate';
import { LinearProgress } from '@mui/material';
import { useTemplateDataSync } from 'hooks/dataSync/useTemplateDataSync';

const EditAvailabilityTemplatePage = () => {
  const { isLoading, currentDataPoint } = useTemplateDataSync();

  if (isLoading || !currentDataPoint) return <LinearProgress />;

  return (
    <CreateEditAvailabilityTemplate availabilityTemplate={currentDataPoint.object} />
  );
};

export default EditAvailabilityTemplatePage;