import React, { FC } from 'react';
import { statusArray, ParticipantGoal, goalStatuses } from 'types/dbSchema/participantGoals';
import StatusDropdown from 'components/common/StatusDropdown';
import { useAtom } from 'jotai';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import useErrorMessage from 'hooks/useErrorMessage';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { updateParticipantGoalById } from 'api/organisations/participants/goals';
import { useParams } from 'react-router-dom';
import { currentDataPointAtom } from 'atoms';

export interface ParticipantGoalStatusDropdownProps {
  goal: ParticipantGoal;
}

const ParticipantGoalStatusDropdown: FC<ParticipantGoalStatusDropdownProps> = ({ goal }) => {
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const showError = useErrorMessage();
  const [organisations] = useUserOrganisations();
  const { id: participantID, goalId } = useParams();
  const [, setCurrentDataPoint] = useAtom(currentDataPointAtom);

  const handleEditStatus = async (changeStatusTo: string) => {
    try {
      if (!organisations[0].organisation.globalId || !participantID || !goalId) throw new Error('Organisation ID, Participant ID, and Goal ID are required');
      const data = { ...goal, status: changeStatusTo };
      const response = await updateParticipantGoalById(organisations[0].organisation.globalId, participantID, goalId, data);
      if (response) {
        setInvalidateData((prev) => ({ ...prev, 'participant-goals': true }));
        setCurrentDataPoint((prev) => ({ ...prev, 'participant-goals': response }));
      }
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <StatusDropdown
      statuses={statusArray}
      currentStatus={goal.status}
      setStatus={handleEditStatus}
    />
  );
};

export default ParticipantGoalStatusDropdown;