import React, { useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import styled from 'styled-components';
import DataTable from 'components/common/DataTable';
import SearchInput from 'components/common/InputFields/SearchInput';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useAtom } from 'jotai';
import variables from 'styles/variables';
import IsActiveDropdown from 'components/common/IsActiveDropdown';
import { AvailabilityTemplate } from 'types/dbSchema/availabilitySettings';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { PaginationModel } from 'components/common/DataTable/types';
import useInvalidateData from 'hooks/useInvalidateData';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';
import useErrorMessage from 'hooks/useErrorMessage';
import { dummyAvailabilitiesTemplates } from 'utils/helpers/getDBData';
import { useNavigate } from 'react-router-dom';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';

// This file handle all of the leave types for the scheduling settings
// If it is too long, we can abstract the individual components into their own files
const AvailabilityTemplates = () => {
  const [searchValue, setSearchValue] = useState('');
  const [paginationModel, setPaginationModel] = useState<PaginationModel>({ page: 0, pageSize: 20 });
  const [organisations] = useUserOrganisations();
  useInvalidateData('availability-template');
  const navigate = useNavigate();

  // UseQuery hook to fetch the data, handle caching, handle loading states, pagination, and error states
  const { isError, data, isPending, isFetching, isLoading } = useQuery({
    queryKey: ['availability-template', paginationModel],
    // queryFn: () => listAvailabilityTemplates(organisations[0].organisation.globalId, paginationModel.page + 1, paginationModel.pageSize),
    queryFn: () => dummyAvailabilitiesTemplates,
    placeholderData: keepPreviousData,
    enabled: organisations.length > 0,
    refetchOnWindowFocus: false,
    staleTime: cachingInvalidation.availabilityTemplates,
  });

  // Grid column definitions for DataTable
  // We have nested the column array into this component to be able to pass the filteredColorList to the Edit component
  // There may be a more elegant solution but this works - moving on
  const columns: GridColDef[] = [
    {
      field: 'edit',
      headerName: '',
      width: 50,
      renderCell: (params) => (
        <Edit availabilityTemplate={params.row} />
      ),
      sortable: false,
    },
    {
      field: 'name',
      headerName: 'Leave Type',
      width: 300,
      renderCell: (params) => (
        <Typography variant='subtitle2'>
          {params.row.name}
        </Typography>
      ),
    },
    {
      field: 'description',
      headerName: 'Description',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Typography variant='subtitle2' color={variables.colors.text.secondary} fontWeight='500'>
          {params.row.description}
        </Typography>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      renderCell: (params) => (
        <EditStatus availabilityTemplate={params.row} />
      ),
    },
  ];

  return (
    <StyledBox>
      <SearchBox>
        <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} placeholderText='Search' />
        <Button variant='contained' sx={{ padding: '8px 16px' }} onClick={() => navigate('/settings/availability-settings/create')}>
          <AddOutlinedIcon sx={{ marginRight: '8px' }} />
          Add
        </Button>
      </SearchBox>
      {/* The box height is set to 100vh - the height of the SearchBox, tabs, and Nav. This is needed because the MUI datatable needs a fixed height */}
      <Box height='calc(100vh - 295px)'>
        <DataTable
          // data={data as Data}
          data={{ items: data || [], totalCount: 1, pageNumber: paginationModel.page, pageSize: paginationModel.pageSize }}
          columns={columns}
          checkboxSelection={false}
          dataTableName='availability-template'
          isPending={isFetching || isPending || isLoading}
          isError={isError}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          title='Leave Types'
          paginationMode='server'
          onNoData={() => navigate('/settings/availability-settings/create')}
        />
      </Box>
    </StyledBox>
  );
};


// Renders Edit Icon for Grid column definitions - opens add/edit form in modal
const Edit = ({ availabilityTemplate }: { availabilityTemplate: AvailabilityTemplate }) => {
  const navigate = useNavigate();

  return (
    <Stack justifyContent='center' alignItems='center' sx={{ cursor: 'pointer' }} onClick={() => navigate(`/settings/availability-settings/edit/${availabilityTemplate.id}`)}>
      <EditOutlinedIcon sx={{ color: '#9CA3AF' }} />
    </Stack>
  );
};

// Handles the status dropdown in the DataTable
const EditStatus = ({ availabilityTemplate }: { availabilityTemplate: AvailabilityTemplate }) => {
  const [organisations] = useUserOrganisations();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  useInvalidateData('availability-template');
  const showError = useErrorMessage();

  const handleEdit = async (status: boolean) => {
    try {
      // await updateAvailabilityTemplate(organisations[0].organisation.globalId, { ...availabilityTemplate, isActive: status });
      setInvalidateData((prev) => ({ ...prev, 'availability-template': true }));
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <IsActiveDropdown
      isActive={availabilityTemplate.isActive}
      setIsActive={handleEdit}
    />
  );
};

const StyledBox = styled(Box)`
  height: 100%;
  width: 100%;
  background-color: white;
`;

const SearchBox = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 24px;
  gap: 16px;
`;

export default AvailabilityTemplates;