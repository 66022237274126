import React from 'react';
import { useCallback } from "react";
import { useAtom } from "jotai";
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { ExternalContact } from 'types/dbSchema/externalContacts';
import { modalStateAtom } from "atoms";
import { Box, Button, Stack, Typography } from "@mui/material";
import variables from 'styles/variables';
import styled from 'styled-components';
import { closeModalAtom } from 'atoms/modalAtom';

export const UnlinkIcon = ({ participantContact }: { participantContact: ExternalContact }) => {
  const [, setModalState] = useAtom(modalStateAtom);

  // Function to open/close the modal.
  const handleModalOpen = useCallback(() => {
    setModalState(prevState => ({
      ...prevState,
      status: 'open',
      position: 'center',
      component: UnlinkModal,
      props: {
        dataTableName: 'participant-contacts',
        participantContact: participantContact,
      }
    }));
  }, [setModalState]);


  return (
    <Stack justifyContent='center' alignItems='center' sx={{ cursor: 'pointer' }} onClick={handleModalOpen}>
      <LinkOffIcon sx={{ color: variables.colors.icon.standard }} />
    </Stack>
  );
};

export const UnlinkModal = ({ participantContact }: { participantContact: ExternalContact }) => {
  const [, closeModal] = useAtom(closeModalAtom);

  const handleUnlink = () => {
    console.log('Unlink contact from Participant', participantContact);
    closeModal();
  };

  return (
    <Stack alignItems="center" width='500px'>
      <Stack flexDirection="row" alignItems="center" gap='24px' sx={{ padding: '32px' }}>
        <Box sx={{ borderRadius: '100%', background: '#FECDD2', padding: '8px', height: '40px', width: '40px', display: 'flex', alignSelf: 'flex-start', boxSizing: 'border-box' }}>
          <LinkOffIcon color='error' />
        </Box>
        <Stack gap='16px'>
          <Typography variant='h6'>
            Unlink this contact
          </Typography>
          <Typography variant='body2' color={variables.colors.text.secondary}>
            Are you sure you want to remove this contact from the participant’s contacts list?
          </Typography>
          <Typography component='div' variant='body2' color={variables.colors.text.secondary} sx={{ fontStyle: 'italic' }}>
            <Box component='span' sx={{ fontWeight: 700 }} color={variables.colors.text.secondary}>Note: </Box>this action does not delete the contact record from the system, it only removes the link with the participant.
          </Typography>
        </Stack>
      </Stack>
      <ButtonStack>
        <Button variant='outlined' onClick={closeModal}>Cancel</Button>
        <Button variant='contained' color='error' onClick={handleUnlink}>Unlink</Button>
      </ButtonStack>
    </Stack>
  );
};

const ButtonStack = styled(Stack)`
  flex-direction: row;
  width: 100%;
  padding: 16px 32px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 0px 0px 12px 12px;
  background: #F9FAFB;
  box-sizing: border-box;
`;