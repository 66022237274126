import React, { FC } from 'react';
import { LinearProgress } from '@mui/material';
import { useParticipantGoalDataSync } from 'hooks/dataSync/useParticipantGoalDataSync';
import ReviewGoal from 'components/features/ViewParticipantGoal/GoalDetails/ReviewGoal';

const ReviewParticipantGoalPage: FC = () => {
  const { isLoading, currentDataPoint } = useParticipantGoalDataSync();

  if (isLoading || !currentDataPoint.object) return <LinearProgress />;

  return <ReviewGoal participantGoal={currentDataPoint.object} />;
};

export default ReviewParticipantGoalPage;