import React, { FC } from 'react';
import { LinearProgress } from '@mui/material';
import ParticipantGoalForm from 'components/features/Forms/ParticipantGoalForm';
import { useParticipantGoalDataSync } from 'hooks/dataSync/useParticipantGoalDataSync';

const EditParticipantGoalPage: FC = () => {
  const { isLoading, currentDataPoint } = useParticipantGoalDataSync();

  if (isLoading || !currentDataPoint.object) return <LinearProgress />;

  return <ParticipantGoalForm participantGoal={currentDataPoint.object} />;
};

export default EditParticipantGoalPage;