import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAtom } from 'jotai';
import { currentDataPointAtom } from 'atoms';
import { dummyProductAndServices } from 'utils/helpers/getDBData';
import { ProductAndService } from 'types/dbSchema/productAndServices';

// This hook is used to sync user profile data with the current data point atom
export function useProductAndServiceDataSync() {
  const { id } = useParams();
  const [currentDataPoint, setCurrentDataPoint] = useAtom(currentDataPointAtom);

  useEffect(() => {
    const getProductAndService = async (id: string) => {
      // Replace this URL with the actual endpoint to fetch user profile data
      // const data = await axios.get(`/api/user-profiles/${id}`);
      const data = dummyProductAndServices.find((product: ProductAndService) => product.id === id) || null;
      setCurrentDataPoint({
        object: data,
        type: 'productAndService',
      });
    };

    if (id) {
      if (currentDataPoint?.object?.id !== id) {
        getProductAndService(id);
      }
    }
  }, [id, currentDataPoint, setCurrentDataPoint]);

  // Check if the data is still loading
  const isLoading = !currentDataPoint.object || currentDataPoint.object?.id !== id;

  return { isLoading, currentDataPoint };
}