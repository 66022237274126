import { Box, Stack, Typography } from '@mui/material';
import UserProfileCell from 'components/features/UserProfileCell';
import React, { FC } from 'react';
import styled from 'styled-components';
import variables from 'styles/variables';
import { GoalHistory } from 'types/dbSchema/participantGoals';
import { formatISODate, formatISODateTime } from 'utils/helpers';
import { dummyUserProfileDataNEW } from 'utils/helpers/getDBData';

export interface GoalHistoryProps {
  goalHistory: GoalHistory;
}

const GoalHistoryCard: FC<GoalHistoryProps> = ({ goalHistory }) => {
  return (
    <CommentBox>
      <Stack flexDirection='row' gap='16px' width='100%' justifyContent='flex-start' alignItems='center'>
        <UserProfileCell displayOnly userProfile={dummyUserProfileDataNEW[0]} nameOnly />
        <Typography variant='body2' color={variables.colors.text.secondary}>
          {formatISODateTime(goalHistory.createdDate)}
        </Typography>
        {goalHistory.type === 'General Comment' ? <GeneralComment /> : <GoalReview />}
      </Stack>
      <Typography variant='body2' dangerouslySetInnerHTML={{ __html: goalHistory.comment }} />
      {goalHistory.type === 'Goal Review' &&
        <Stack sx={{ borderTop: '1px solid #E5E7EB' }} width='100%' paddingTop='16px' gap='16px'>
          <Typography variant='body2' fontSize='12px' fontWeight='600' color={variables.colors.text.secondary}>
            GOAL REVIEWED
          </Typography>
          <Stack gap='8px' flexDirection='row'>
            <Typography variant='body2' color={variables.colors.text.secondary} minWidth='140px'>
              Goal Type
            </Typography>
            <Typography variant='body2'>
              {goalHistory.goalSnapshot?.goalType}
            </Typography>
          </Stack>
          <Stack gap='8px' flexDirection='row'>
            <Typography variant='body2' color={variables.colors.text.secondary} minWidth='140px'>
              Goal
            </Typography>
            <Typography variant='subtitle2'>
              {goalHistory.goalSnapshot?.goal}
            </Typography>
          </Stack>
          <Stack gap='8px' flexDirection='row'>
            <Typography variant='body2' color={variables.colors.text.secondary} minWidth='140px'>
              Description
            </Typography>
            <Typography variant='body2'>
              {goalHistory.goalSnapshot?.description}
            </Typography>
          </Stack>
          <Stack gap='8px' flexDirection='row'>
            <Typography variant='body2' color={variables.colors.text.secondary} minWidth='140px'>
              Review Date
            </Typography>
            <Typography variant='body2'>
              {goalHistory.goalSnapshot?.reviewDate && formatISODate(goalHistory.goalSnapshot?.reviewDate)}
            </Typography>
          </Stack>
          <Stack gap='8px' flexDirection='row'>
            <Typography variant='body2' color={variables.colors.text.secondary} minWidth='140px'>
              Score
            </Typography>
            <Typography variant='body2'>
              {goalHistory.goalSnapshot?.score}
            </Typography>
          </Stack>
        </Stack>
      }
    </CommentBox >
  );
};


const GeneralComment = () => {
  return (
    <Stack sx={{ backgroundColor: '#5C068C0A', padding: '4px 8px', borderRadius: '4px', width: 'fit-content', position: 'relative' }} direction='row' alignItems='center'>
      <Typography variant='subtitle2' fontWeight='600' color='#AC4FC6'>
        General Comment
      </Typography>
    </Stack>
  );
};

const GoalReview = () => {
  return (
    <Stack sx={{ backgroundColor: '#03A9F414', padding: '4px 8px', borderRadius: '4px', width: 'fit-content', position: 'relative' }} direction='row' alignItems='center'>
      <Typography variant='subtitle2' fontWeight='600' color='#01579B'>
        Goal Review
      </Typography>
    </Stack>
  );
};

const CommentBox = styled(Stack)`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;border-radius: 8px;
  background: #F9FAFB;
  /* border: 1px solid #E0E0E0; */
`;

export default GoalHistoryCard;