import { Box, Stack, Tab, Tabs as TabsMUI } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { TabsProps } from './types';
import useCurrentUser from 'hooks/useCurrentUser';

const Tabs: FC<TabsProps> = ({ tabs }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [visibleTabs, setVisibleTabs] = useState(tabs);
  const [user] = useCurrentUser();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  // Filter out tabs that the user does not have permission to view
  useEffect(() => {
    setVisibleTabs(tabs.filter((tab) => {
      return tab.requiredPermissions.every((permission) => user?.permissions.includes(permission));
    }));
  }, [tabs, user]);

  if (!user || !visibleTabs) return null;
  return (
    <Stack width='100%' height='100%'>
      <Box sx={{ backgroundColor: 'white', padding: '0 24px' }}>
        <Stack flexDirection='row' sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabsMUI
            value={currentTab}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              '.MuiTabs-scroller': {
                overflow: 'auto !important',
                scrollbarWidth: 'none',
              },
            }}>
            {visibleTabs.map((tab, index) => (
              <Tab label={tab.label} key={index} onClick={() => setCurrentTab(index)} sx={{ fontWeight: '600' }} />
            ))}
          </TabsMUI>
        </Stack>
      </Box>
      {/* NOTE: Content will handle it's own padding */}
      <Stack padding='0' sx={{ width: '100%', height: '100%' }}>
        {visibleTabs[currentTab].content}
      </Stack>
    </Stack>
  );
};

export default Tabs;